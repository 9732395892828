export function maskSSN(value: string): string {
  if (value.slice(-1) === "-") {
    return value;
  }
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d)/, "$1-$2")
    .replace(/^(\d{3})-(\d{2})(\d)/, "$1-$2-$3")
    .replace(/^(\d{3})-(\d{2})-(\d{4})(\d*)/, "$1-$2-$3");
}

export function maskEIN(value: string): string {
  if (value.slice(-1) === "-") {
    return value;
  }
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1-$2")
    .replace(/^(\d{2})-(\d{7})(\d*)/, "$1-$2");
}

export function maskDate(value: string): string {
  if (value.length == 1 && value > "1") {
    return `0${value}`;
  }
  if (value.slice(-1) === "/") {
    return value.replace(/^1\/$/, "01/").replace(/(\d{2})\/(\d)\/$/, "$1/0$2/");
  }
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1/$2")
    .replace(/^(\d{2})\/(\d{2})(\d)/, "$1/$2/$3")
    .replace(/^(\d{2})\/(\d{2})\/(\d{4})(\d*)/, "$1/$2/$3");
}

export function maskPhone(value: string): string {
  if (value.slice(-1) === "-") {
    return value;
  }
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d)/, "($1) $2")
    .replace(/^(\(\d{3}\))\s(\d{3})(\d)/, "$1 $2-$3")
    .replace(/^(\(\d{3}\))\s(\d{3})-(\d{4})(\d*)/, "$1 $2-$3");
}

export function maskCurrency(value: string): string {
  const numbers = value.replace(/[^0-9]/g, "").replace(/^0+/, "");
  const dollars = numbers.slice(0, -2);
  const cents = numbers.slice(-2);
  return `$${dollars || 0}.${"0".repeat(2 - cents.length)}${cents}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
}

export function maskPercentage(value: string): string {
  const numbers = value.replace(/[^0-9]/g, "").replace(/^0+/, "");
  const integers = numbers.slice(0, -2);
  const decimals = numbers.slice(-2);
  return `${integers || 0}.${"0".repeat(
    2 - decimals.length
  )}${decimals}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
