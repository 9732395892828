import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { getApplications } from "@/api";
import { getRouteByApplicationStage } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Header } from "@prime/pop-components/src/layout";
import { maskPhone } from "@prime/ui/lib/masks";
import { formatPhoneForAuth } from "@prime/ui/lib/utils";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@prime/ui/src/input-otp";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@prime/ui/src/tabs";
import { AxiosError } from "axios";

import XeroLoginLogoSVG from "./-components/xero-login-logo.svg";

const ERROR_OPTIONS = [
  "login_failed",
  "missing_offer",
  "authentication_required",
  "no_account_exists",
] as const;

const homepageSearchSchema = z.object({
  error: z.enum(ERROR_OPTIONS).optional(),
});

export const Route = createFileRoute("/")({
  validateSearch: homepageSearchSchema,
  beforeLoad: async ({ context, search, cause }) => {
    if (cause !== "enter") return;
    const { auth, apiClient } = context;

    // ?error=authentication_required
    if (search.error === "authentication_required") {
      context.toast({
        title: "Authenticated required",
        description: "Please log in to access this route.",
        variant: "destructive",
      });
    }

    // ?error=no_account_exists
    if (search.error === "no_account_exists") {
      context.toast({
        title: "Account does not exist",
        description: "Please log in using a different method.",
        variant: "destructive",
      });
    }

    // ?error=login_failed
    if (search.error === "login_failed") {
      context.toast({
        title: "Login failed",
        description: "We were unable to log you in. Please try again.",
        variant: "destructive",
      });
    }

    // ?error=missing_offer
    if (search.error === "missing_offer") {
      context.toast({
        title: "Offer does not exist",
        description:
          "We were unable to find the offer you were looking for. Please try again.",
        variant: "destructive",
      });
    }

    const { isAuthenticated, user } = auth;

    if (isAuthenticated && user) {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        if (!applications?.length) {
          const [authType, authProvider] = user?.sub?.split("|") ?? [];
          if (authType === "oauth2") {
            throw redirect({
              to: "/$platformKey/get-started",
              params: { platformKey: authProvider },
            });
          }
          return;
        }

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }
  },
  component: Page,
});

const phoneFormSchema = z.object({
  phoneNumber: z
    .string()
    .refine((value) => value.replace(/\D/g, "").length === 10, {
      message: "Please enter a valid phone number",
    }),
});

const emailFormSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});

type Verification = {
  method: "sms" | "email";
  value: string;
};

function Page() {
  const { auth } = Route.useRouteContext();

  const [verification, setVerification] = useState<Verification | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [havingTrouble, setHavingTrouble] = useState<boolean>(false);

  const phoneForm = useForm<z.infer<typeof phoneFormSchema>>({
    resolver: zodResolver(phoneFormSchema),
    defaultValues: {
      phoneNumber: "",
    },
  });

  const emailForm = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const signInWithXero = () =>
    auth.signInWith({
      connection: "xero",
    });

  async function onSubmitPhone(values: z.infer<typeof phoneFormSchema>) {
    const { phoneNumber } = values;
    // Send verification code to phone number via SMS
    try {
      setProcessing(true);
      setHavingTrouble(false);
      await auth.requestCode({ phoneNumber: formatPhoneForAuth(phoneNumber) });
      setVerification({ method: "sms", value: phoneNumber });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }

  async function onSubmitEmail(values: z.infer<typeof emailFormSchema>) {
    const { email } = values;
    // Send verification code to email
    try {
      setProcessing(true);
      setHavingTrouble(false);
      await auth.requestCode({ email });
      setVerification({ method: "email", value: email });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }

  const showVerification = verification;
  const phoneNumberGood = phoneForm.formState.isValid;
  const emailGood = emailForm.formState.isValid;
  const buttonDisabled = processing || (!phoneNumberGood && !emailGood);

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-start justify-center">
        <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
          {showVerification && (
            <>
              {!havingTrouble ? (
                <Verification
                  verification={verification}
                  onHavingTrouble={() => setHavingTrouble(true)}
                />
              ) : (
                <HavingTroubleScreen
                  sendVerificationCodeToEmail={emailForm.handleSubmit(
                    onSubmitEmail
                  )}
                  sendVerificationCodeToPhone={phoneForm.handleSubmit(
                    onSubmitPhone
                  )}
                  phoneNumber={
                    verification.method === "sms" ? verification.value : ""
                  }
                  emailAddress={
                    verification.method === "email" ? verification.value : ""
                  }
                  startOver={() => {
                    setVerification(null);
                    setHavingTrouble(false);
                  }}
                />
              )}
            </>
          )}
          {!showVerification && (
            <>
              <div>
                <div className="text-icon w-full text-left text-sm font-normal uppercase leading-[150%]">
                  Let’s Get Back to Business
                </div>
                <div className="text-txt-secondary w-full text-left text-xl font-light leading-[125%]">
                  Sign in with your phone number or email.
                </div>
              </div>
              <Tabs defaultValue="phone" className="bg-red w-full">
                <TabsList className="flex w-full">
                  <TabsTrigger
                    className="flex-1 text-sm font-light"
                    value="phone"
                  >
                    Phone
                  </TabsTrigger>
                  <TabsTrigger
                    className="flex-1 text-sm font-light"
                    value="email"
                  >
                    Email
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="phone">
                  <Form {...phoneForm}>
                    <form
                      onSubmit={phoneForm.handleSubmit(onSubmitPhone)}
                      className="flex w-full flex-col gap-6"
                    >
                      <FormField
                        control={phoneForm.control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                placeholder="Mobile Phone Number"
                                {...field}
                                valueModifier={maskPhone}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button
                        type="submit"
                        variant={buttonDisabled ? "outline" : "default"}
                        className="h-12 w-full rounded-xl px-5 py-3 text-sm font-normal"
                        disabled={buttonDisabled}
                      >
                        Sign In with Phone
                      </Button>
                    </form>
                  </Form>
                </TabsContent>
                <TabsContent value="email">
                  <Form {...emailForm}>
                    <form
                      onSubmit={emailForm.handleSubmit(onSubmitEmail)}
                      className="flex w-full flex-col gap-6"
                    >
                      <FormField
                        control={emailForm.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                type="email"
                                placeholder="Email Address"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <Button
                        type="submit"
                        variant={buttonDisabled ? "outline" : "default"}
                        className="h-12 w-full rounded-xl px-5 py-3 text-sm font-normal"
                        disabled={buttonDisabled}
                      >
                        Sign In with Email
                      </Button>
                    </form>
                  </Form>
                </TabsContent>
              </Tabs>
              <div className="flex w-full items-center justify-center gap-[6px]">
                <div className="bg-border-dark relative h-px w-full" />
                <h3 className="text-border-dark text-[10px]">OR</h3>
                <div className="bg-border-dark relative h-px w-full" />
              </div>
              <h3 className="text-txt-secondary text-xl">
                Sign in with your accounting software.
              </h3>
              <Button
                onClick={signInWithXero}
                className="h-12 w-full gap-3 rounded-xl bg-[#1AB4D7] px-5 py-3 text-sm font-medium hover:bg-[#1590AC]"
              >
                <XeroLoginLogoSVG className="h-8 w-8" />
                Sign in with Xero
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function Verification({
  verification,
  onHavingTrouble = () => {},
}: {
  verification: Verification;
  onHavingTrouble?: () => void;
}) {
  const navigate = useNavigate({ from: "/" });
  const { auth, toast, apiClient } = Route.useRouteContext();
  const { exchangeCode } = auth;
  const [isLoading, setIsLoading] = useState(false);

  const FormSchema = z.object({
    code: z.string().min(6, {
      message: "Your one-time code must be 6 characters.",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      code: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    setIsLoading(true);
    exchangeCode({
      code: data.code,
      ...(verification.method === "sms"
        ? { phoneNumber: formatPhoneForAuth(verification.value) }
        : { email: verification.value }),
    })
      .then(() => {
        getApplications({
          apiClient,
        })
          .then(({ applications = [] }) => {
            if (!applications?.length) {
              throw new Error("No applications found");
            } else {
              navigate(getRouteByApplicationStage(applications?.[0]));
            }
          })
          .catch(() => {
            navigate({
              to: "/",
              search: { error: "no_account_exists" },
            });
          });
      })
      .catch(() => {
        toast({
          title: "Verification code failed",
          description: "The verification code you entered is incorrect.",
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const communicationType =
    verification.method === "sms" ? "a text message" : "an email";

  const communicationMechanism =
    verification.method === "sms" ? "phone number" : "email address";

  const buttonDisabled = !form.formState.isValid || isLoading;

  return (
    <>
      <div>
        <h1 className="text-2xl text-[#1D242E]">Enter Your Code</h1>
        <div className="w-full text-left text-[14px] leading-[150%] text-[#69737e]">
          <span>{`We sent ${communicationType} containing a verification code to the ${communicationMechanism} `}</span>
          <span className="font-normal text-[#4b4b55]">
            {verification.value}
          </span>
        </div>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-6"
        >
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputOTP
                    maxLength={6}
                    onComplete={form.handleSubmit(onSubmit)}
                    autoFocus
                    {...field}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            variant="link"
            className="m-0 justify-start p-0 text-left text-sm font-light leading-[150%] text-[#595FEF] [text-decoration:underline]"
            onClick={onHavingTrouble}
          >
            Having Trouble?
          </Button>
          <Button
            type="submit"
            variant={buttonDisabled ? "outline" : "default"}
            className="mt-6 h-12 w-full rounded-xl px-5 py-3 text-sm font-normal"
            disabled={buttonDisabled}
          >
            Continue
            {isLoading ? (
              <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
            ) : null}
          </Button>
        </form>
      </Form>
    </>
  );
}

function HavingTroubleScreen({
  sendVerificationCodeToEmail,
  sendVerificationCodeToPhone,
  startOver,
  phoneNumber,
  emailAddress: email,
}: {
  sendVerificationCodeToEmail: () => void;
  sendVerificationCodeToPhone: () => void;
  startOver: () => void;
  phoneNumber: string;
  emailAddress: string;
}) {
  function Info({
    title,
    children,
  }: {
    title: string;
    children: React.ReactNode;
  }) {
    return (
      <div className="flex flex-col gap-2 rounded-lg border p-3">
        <p className="text-center text-[14px] font-normal">{title}</p>
        {children}
      </div>
    );
  }

  return (
    <>
      <div className="flex-shrink-0 flex-grow-0">
        <h1 className="text-2xl text-[#1D242E]">Let&rsquo;s figure this out</h1>
        <div className="w-full text-left text-[14px] leading-[150%] text-[#69737e]">
          If you haven&rsquo;t received a code to verify your account,
          there&rsquo;s a couple things we can do
        </div>
      </div>
      {phoneNumber && (
        <Info title={phoneNumber}>
          <Button
            onClick={sendVerificationCodeToPhone}
            size="sm"
            className="w-full"
          >
            Send a New Code via Text
          </Button>
        </Info>
      )}
      {email && (
        <Info title={email}>
          <Button
            onClick={sendVerificationCodeToEmail}
            size="sm"
            className="w-full"
          >
            Send a New Code via Email
          </Button>
        </Info>
      )}
      <Info title="My information is incorrect...">
        <Button size="sm" className="w-full" onClick={startOver}>
          Start Over
        </Button>
      </Info>
    </>
  );
}
