import { type GoogleAddress } from "./types";

export function formatGoogleAddress<T extends GoogleAddress>({
  _google_address_line_1,
  _google_address_line_2,
  _google_address_city,
  _google_address_state,
  _google_address_postal_code,
}: T) {
  if (!_google_address_line_2) {
    return `${_google_address_line_1}, ${_google_address_city} ${_google_address_state} ${_google_address_postal_code}`;
  } else {
    return `${_google_address_line_1} ${_google_address_line_2}, ${_google_address_city} ${_google_address_state} ${_google_address_postal_code}`;
  }
}

export const convertPlaceAddressComponentsToAddress = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): GoogleAddress => {
  const address: GoogleAddress = {
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
  };

  for (const component of addressComponents) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address._google_address_line_1 = component.long_name;
        break;
      }

      case "route": {
        address._google_address_line_1 += ` ${component.short_name}`;
        break;
      }

      case "subpremise": {
        address._google_address_line_2 = component.long_name;
        break;
      }

      case "locality": {
        address._google_address_city = component.long_name;
        break;
      }

      case "sublocality_level_1": {
        address._google_address_city = component.long_name;
        break;
      }

      case "administrative_area_level_1": {
        address._google_address_state = component.short_name;
        break;
      }

      case "postal_code": {
        address._google_address_postal_code = component.long_name;
        break;
      }

      case "country": {
        address._google_address_country_code = component.short_name;
        break;
      }

      default: {
        break;
      }
    }
  }

  return address;
};
