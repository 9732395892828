import {
  BadgePercentIcon,
  BriefcaseIcon,
  CreditCardIcon,
  LucideIcon,
  ReceiptIcon,
  ZapIcon,
} from "lucide-react";
import { useState } from "react";

import { Button } from "@prime/ui/src/button";

import { cn } from "../../lib/css";

export function PromoCard({
  color = "#895af0",
  IconComponent,
  title,
  description,
  hideMobile = false,
  sup,
}: {
  color?: string;
  IconComponent: LucideIcon;
  title: string;
  description: string;
  hideMobile?: boolean;
  sup?: string;
}) {
  return (
    <div
      className={`${hideMobile ? "hidden md:flex" : "flex"} text-txt-secondary flex- box-border w-full max-w-[480px] flex-1 shrink-0 basis-[40%] flex-col items-center justify-start gap-6 overflow-hidden rounded-xl bg-white p-6 text-center text-xl font-medium lg:basis-[30%]`}
    >
      <div
        style={{ backgroundColor: color }}
        className={`flex flex-row items-center justify-center rounded-[14px] p-3`}
      >
        <IconComponent size={24} className="text-white" />
      </div>
      <div className="relative self-stretch">
        <div className="inline-block leading-[125%] [text-shadow:0px_4px_24px_rgba(0,_0,_0,_0.1)]">
          {title}
          {sup ? (
            <sup className="text-txt-tertiary text-xs font-normal"> {sup}</sup>
          ) : null}
        </div>
        <div className="text-txt-tertiary inline-block text-base font-light leading-[150%]">
          {description}
        </div>
      </div>
    </div>
  );
}

export function PromoTerms({
  className,
  originationProduct,
}: {
  className?: string;
  originationProduct?: boolean;
}) {
  const [showMore, setShowMore] = useState(false);
  return (
    <div
      className={cn(
        "bg-surface-dark flex w-full flex-col items-center justify-center px-3 py-12 md:rounded-3xl md:px-6",
        className
      )}
    >
      <div className="text-txt-primary flex w-full max-w-[960px] flex-col items-center justify-center gap-6 text-center md:gap-12">
        <div className="flex flex-col items-center justify-center">
          <div className="flex max-w-[480px] flex-col items-start justify-start gap-[12px] self-stretch">
            <h3 className="leading-[95% relative self-stretch text-4xl font-medium sm:text-5xl">
              Transparent Terms.
              <br />
              No Hidden Fees.
            </h3>
            <div className="text-txt-secondary relative self-stretch text-base font-light leading-[150%] sm:text-xl">
              Easy and honest short term financing for small businesses ready to
              go big. Let’s break it down.
            </div>
          </div>
        </div>
        <div className="text-txt-secondary flex flex-col flex-wrap items-center justify-center gap-6 self-stretch text-xl md:flex-row md:items-start">
          <PromoCard
            title={originationProduct ? "Fixed Fee" : "Fixed Interest Rate"}
            sup={originationProduct ? "3" : undefined}
            description={`A consistent ${originationProduct ? "amount" : "rate"} that provides predictable repayments.`}
            IconComponent={BadgePercentIcon}
            color="#895af0"
          />
          <PromoCard
            title="No Hidden Fees"
            sup={originationProduct ? "3" : undefined}
            description="Upfront pricing, with no unexpected charges or additional fees."
            IconComponent={ReceiptIcon}
            color="#7D5BF0"
          />
          <PromoCard
            title="Apply in Minutes"
            description="An application that won't keep you away from running your business."
            IconComponent={ZapIcon}
            color="#6D5DF0"
          />
          <PromoCard
            title="No Impact on Credit"
            description="A soft credit check ensures no impact to your credit score."
            IconComponent={CreditCardIcon}
            color="#4E6FEB"
            hideMobile={!showMore}
          />
          <PromoCard
            title="No Personal Guarantee"
            description="Get funding without risking your most valuable personal assets."
            IconComponent={BriefcaseIcon}
            color="#3591E1"
            hideMobile={!showMore}
          />
          <PromoCard
            title="Fast Delivery of Funds"
            description="Approved funds deposited in your bank as fast as one business day."
            IconComponent={ZapIcon}
            color="#20AEDA"
            hideMobile={!showMore}
          />
        </div>
        {!showMore ? (
          <Button
            onClick={() => setShowMore(!showMore)}
            variant={"outline"}
            className="mt-6 flex w-full max-w-[480px] border-[2px] font-medium text-[hsl(var(--brand))] md:hidden"
          >
            See More
          </Button>
        ) : null}
      </div>
    </div>
  );
}
