import { createFileRoute, redirect } from "@tanstack/react-router";
import { ArrowRightIcon } from "lucide-react";

import { getApplications } from "@/api";
import { useAPI } from "@/contexts/api";
import Agreements from "@/routes/-components/agreements";
import { Application } from "@/types";
import { Header } from "@prime/pop-components/src/layout";
import { Button } from "@prime/ui/src/button";

export const Route = createFileRoute("/$platformKey/get-started/")({
  beforeLoad: async ({ context }) => {
    const { auth } = context;
    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      let applications: Application[] = [];

      try {
        const response = await getApplications({
          apiClient: context.apiClient,
        });
        applications = response.applications;
      } catch (err) {
        // Valid case, Do nothing
      }

      if (applications.length) {
        throw redirect({
          to: "/",
        });
      }
    }
  },
  component: Page,
});

function Page() {
  const { platformKey } = Route.useParams<{ platformKey: string }>();
  const { auth } = useAPI();

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-center justify-center p-6 text-center sm:p-12">
        <div className="flex w-full max-w-[512px] flex-col items-center gap-6 rounded-xl p-6">
          <div className="text-start">
            <div className="text-txt-primary relative inline-block w-full text-left text-2xl font-normal leading-[125%]">
              Before We Get Started
            </div>
            <div className="text-txt-tertiary z-[3] self-stretch text-base leading-[150%]">
              Please review and agree to our policies and disclosures that
              dictate how we’ll be working with you.
            </div>
          </div>

          <div className="bg-surface-inset w-full rounded-xl p-1 sm:p-3">
            <Agreements />
          </div>
          <Button
            className="mt-6 h-12 w-full rounded-xl text-base"
            onClick={() =>
              auth.signInWith({
                connection: platformKey,
                allScopes: true,
                state: { autoClaim: true },
              })
            }
          >
            <div className="relative font-medium leading-[24px]">Continue</div>
            <ArrowRightIcon size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
}
