import React, { createContext, useContext, useState } from "react";

import { Application } from "@/types";
import { maskEIN, maskPhone } from "@prime/ui/lib/masks";

import {
  type PersonalInformation,
  type BusinessInformation,
  AdditionalMetadataSchema,
} from "../lib/schema";

type Step = "personal" | "business" | "review";

type IdentityContextValue = {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  personalInformation: PersonalInformation;
  setPersonalInformation: React.Dispatch<
    React.SetStateAction<PersonalInformation>
  >;
  businessInformation: BusinessInformation;
  setBusinessInformation: React.Dispatch<
    React.SetStateAction<BusinessInformation>
  >;
  googlePlacesApiKey: string;
  isSubmitting: boolean;
  onSubmit: ({
    personalInformation,
    businessInformation,
  }: {
    personalInformation: PersonalInformation;
    businessInformation: BusinessInformation;
  }) => Promise<void>;
};

const IdentityContext = createContext<IdentityContextValue | null>(null);

const getInitialPI = (
  application?: Application,
  userEmail?: string
): PersonalInformation => {
  if (application?.additional_metadata) {
    const additional_metadata = AdditionalMetadataSchema.parse(
      application?.additional_metadata
    );

    const person_metadata = additional_metadata?.person_metadata;
    const address = person_metadata?.address;
    const addressSearch = address
      ? `${address?.street_address_1 || ""}, ${
          address?.locality || ""
        }, ${address?.region || ""} ${address?.postal_code}`
      : "";

    return {
      first_name: person_metadata?.name_first || "",
      last_name: person_metadata?.name_last || "",
      address_search: addressSearch,
      _google_address_line_1: address?.street_address_1 || "",
      _google_address_line_2: address?.street_address_2 || "",
      _google_address_city: address?.locality || "",
      _google_address_state: address?.state || "",
      _google_address_postal_code: address?.postal_code || "",
      _google_address_country_code: address?.country || "",
      role: "" as "ceo", // Empty string as default value for type checking
      type: person_metadata?.type || ("" as "owner"), // Empty string as default value for type checking as "owner",
      date_of_birth: "",
      ssn: "",
      phone: person_metadata?.phone_number
        ? maskPhone(person_metadata?.phone_number)
        : "",
      email: person_metadata?.email_address || userEmail || "",
    };
  }
  return {
    first_name: "",
    last_name: "",
    address_search: "",
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
    role: "" as "ceo", // Empty string as default value for type checking
    type: "" as "owner", // Empty string as default value for type checking as "owner",
    date_of_birth: "",
    ssn: "",
    phone: "",
    email: userEmail || "",
  };
};

const getInitialBI = (
  application?: Application,
  userEmail?: string
): BusinessInformation => {
  if (application?.additional_metadata) {
    const additional_metadata = AdditionalMetadataSchema.parse(
      application?.additional_metadata
    );

    const business_metadata = additional_metadata?.business_metadata;
    const address = business_metadata?.address;
    const addressSearch = address
      ? `${address?.street_address_1 || ""}, ${
          address?.locality || ""
        }, ${address?.region || ""} ${address?.postal_code}`
      : "";

    return {
      business_name: business_metadata?.name || "",
      business_type: business_metadata?.business_type || ("" as "llc"),
      address_search: addressSearch,
      _google_address_line_1: address?.street_address_1 || "",
      _google_address_line_2: address?.street_address_2 || "",
      _google_address_city: address?.locality || "",
      _google_address_state: address?.region || "",
      _google_address_postal_code: address?.postal_code || "",
      _google_address_country_code: address?.country || "",
      phone: business_metadata?.phone
        ? maskPhone(business_metadata?.phone)
        : "",
      ein: business_metadata?.ein ? maskEIN(business_metadata?.ein) : "",
      email: business_metadata?.email || "",
      website: business_metadata?.website || "",
      ownership_percentage: "",
    };
  }

  return {
    business_name: "",
    business_type: "" as "llc",
    address_search: "",
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
    phone: "",
    ein: "",
    email: userEmail || "",
    website: "",
    ownership_percentage: "",
  };
};

function IdentityContextProvider(props: {
  googlePlacesApiKey: string;
  isSubmitting: boolean;
  onSubmit: ({
    personalInformation,
    businessInformation,
  }: {
    personalInformation: PersonalInformation;
    businessInformation: BusinessInformation;
  }) => Promise<void>;
  children?: React.ReactNode;
  application?: Application;
  userEmail?: string;
}) {
  const [step, setStep] = useState<Step>("personal");

  const [personalInformation, setPersonalInformation] =
    useState<PersonalInformation>(
      getInitialPI(props?.application, props?.userEmail)
    );

  const [businessInformation, setBusinessInformation] =
    useState<BusinessInformation>(
      getInitialBI(props?.application, props?.userEmail)
    );

  const contextValue = {
    step,
    setStep,
    personalInformation,
    setPersonalInformation,
    businessInformation,
    setBusinessInformation,
    googlePlacesApiKey: props.googlePlacesApiKey,
    isSubmitting: props.isSubmitting,
    onSubmit: props.onSubmit,
  };
  return <IdentityContext.Provider {...props} value={contextValue} />;
}

function useIdentityContext() {
  const context = useContext(IdentityContext);

  if (!context) {
    throw new Error(
      "You are trying to use `useIdentityContext` but the calling site is not a child of the <IdentityContextProvider> component"
    );
  }
  return context;
}

export { useIdentityContext, IdentityContextProvider };
