export default function EmailIllo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 72 122"
      {...props}
    >
      <path
        fill="#A5A9FF"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m1.134 65.461 33.864-46.94 34.498 8.423-9.768 22.835-24.01 26.56L3.392 66.8 1.134 65.46Z"
      />
      <path
        fill="#4946BD"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M2.445 66.238 36 19.518l33.496 7.426-7.511 24.098-24.01 26.56L3.391 72.79l-.946-6.551Z"
      />
      <path
        fill="#fff"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m10.028 30.831.21 70.582 1.246.466 50.27-30.384c.045-23.123 1.381-69.7 1.381-69.7l-1-.607-52.107 29.643Z"
      />
      <path
        fill="#595FEF"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m11.273 31.298.21 70.582 51.515-29.918.137-70.168-51.862 29.504Z"
      />
      <path
        fill="#A5A9FF"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m1.134 65.461.221 54.013 2.257 1.338 64.79-40.384 2.464-52.672-1.37-.812L36 73.696 3.391 66.8 1.134 65.46Z"
      />
      <path
        fill="#fff"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m3.391 66.799.221 54.013 67.046-39.046.208-54.01L37.254 74.81 3.391 66.799Z"
      />
      <path
        fill="#fff"
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m3.612 120.812 33.642-46.001 33.404 6.955-67.046 39.046ZM45.334 50.333c4.202-7.61 4.03-15.75-.383-18.182-4.414-2.432-11.398 1.765-15.6 9.375s-4.03 15.75.383 18.182c4.414 2.432 11.398-1.765 15.6-9.375Z"
      />
      <path
        fill="#fff"
        d="M38.97 45.246c-.077.636-2.281 5.557-3.958 4.327-.722-.53-.238-2.692.42-4.13 1.033-2.257 3.128-4.167 3.81-3.775.942.54-1.21 5.167-.128 5.808 1.91 1.13 5.83-7.72 1.808-9.389-2.043-.848-5.523 2.161-7.04 4.646-.946 1.55-3.733 8.21-.646 10.407 1.351.962 4.214-.384 6.316-2.64"
      />
      <path
        stroke="#4946BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M38.97 45.246c-.077.636-2.281 5.557-3.958 4.327-.722-.53-.238-2.692.42-4.13 1.033-2.257 3.128-4.167 3.81-3.775.942.54-1.21 5.167-.128 5.808 1.91 1.13 5.83-7.72 1.808-9.389-2.043-.848-5.523 2.161-7.04 4.646-.946 1.55-3.733 8.21-.646 10.407 1.351.962 4.214-.384 6.316-2.64"
      />
    </svg>
  );
}
