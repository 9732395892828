import { useEffect } from "react";

const useSmoothAnchors = () => {
  useEffect(() => {
    // Smooth scroll
    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        // @ts-expect-error ts(2683)
        const targetId = (this as HTMLAnchorElement).getAttribute("href");
        if (targetId) {
          const targetElement = document.querySelector(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      } as EventListener);
    });
    // Cleanup function to remove event listeners when component unmounts
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", function (e) {
          e.preventDefault();
          // @ts-expect-error ts(2683)
          const targetId = (this as HTMLAnchorElement).getAttribute("href");
          if (targetId) {
            const targetElement = document.querySelector(targetId);
            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: "smooth",
              });
            }
          }
        } as EventListener);
      });
    };
  }, []);
  return null;
};

export default useSmoothAnchors;
