import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/css";

const bannerVariants = cva(
  "bg-surface-banner text-txt-inverted box-border w-full justify-between rounded-xl px-3 py-3 text-xl sm:px-6",
  {
    variants: {
      variant: {
        default: "",
        destructive: "bg-accent-failure",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BannerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof bannerVariants> {
  title: string;
  description: string;
  icon: React.ReactElement;
  button?: React.ReactElement;
}

const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  ({ className, variant, title, description, icon, button }, ref) => {
    return (
      <div ref={ref} className={cn(bannerVariants({ variant, className }))}>
        <div className="mx-auto flex w-full max-w-[1280px] flex-1 flex-col items-start justify-between gap-6 text-center sm:flex-row sm:items-center">
          <div className="flex flex-row items-center gap-3">
            {icon}
            <div className="flex flex-1 flex-col items-start justify-start">
              <div className="text-left text-xl font-medium leading-[125%]">
                {title}
              </div>
              <div className="whitespace-pre-wrap text-left text-base leading-[150%]">
                {description}
              </div>
            </div>
          </div>
          {button}
        </div>
      </div>
    );
  }
);
Banner.displayName = "Banner";

export { Banner, bannerVariants };
