import { Link, createFileRoute } from "@tanstack/react-router";
import {
  BadgeCheck,
  BanknoteIcon,
  BarChart4Icon,
  BookmarkIcon,
  CalendarIcon,
  CircleArrowRight,
  CircleDollarSignIcon,
  Cog,
  Download,
  FileCheck,
  LandmarkIcon,
  LucideIcon,
  PercentIcon,
  PieChartIcon,
  ReceiptIcon,
  RefreshCwIcon,
  SquareArrowRight,
  TriangleAlert,
  UserRoundCheck,
  Zap,
} from "lucide-react";
import { ReactElement, useMemo, useState } from "react";

import {
  getBankAccount,
  getBorrowerBusiness,
  getServicingLoan,
  getServicingTransactions,
} from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import FundingSvg from "@/routes/loans/-components/funding.svg";
import RepaidSvg from "@/routes/loans/-components/repaid.svg";
import ReviewSvg from "@/routes/loans/-components/review.svg";
import { type LoanStatus, type ServicingTransaction } from "@/types";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@prime/ui/src/alert-dialog";
import { Badge } from "@prime/ui/src/badge";
import { Banner } from "@prime/ui/src/banner";
import { Button, ButtonProps } from "@prime/ui/src/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@prime/ui/src/card";
import { LocalNavigation } from "@prime/ui/src/local-navigation";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@prime/ui/src/table";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatDatetime = (date: Date | string | number) =>
  new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    // timeZone: "America/New_York", // EST time zone
    timeZoneName: "short",
  })
    .format(new Date(date))
    .replace(", ", " at ");

export const Route = createFileRoute("/loans/$loanId/_loansLayout/")({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;
    const { loanId } = params;

    const loan = await getServicingLoan({
      apiClient,
      params: { loanId },
    });

    const borrowerId = loan.borrower_id;

    const borrowerBusiness = await getBorrowerBusiness({
      apiClient,
      params: { borrower_id: borrowerId },
    });

    const transactions = await getServicingTransactions({
      apiClient,
      params: { loanId },
    });

    const bankAccount = await getBankAccount({
      apiClient,
      params: { borrowerId },
    });

    return {
      bankAccount,
      borrowerBusiness,
      loan,
      transactions,
    };
  },
  component: Page,
  pendingComponent: LoaderScreen,
});

function Page() {
  const { borrowerBusiness, loan } = Route.useLoaderData();

  const statusLabel = STATUS_TO_LABEL_MAP[loan.status];

  const businessName = borrowerBusiness.legal_name ?? borrowerBusiness.name;

  return (
    <>
      <div className="flex flex-1 px-3 py-12 sm:px-6 lg:px-16">
        <div className="ml-auto mr-auto flex w-full max-w-7xl flex-col gap-6">
          <LocalNavigation
            title={businessName}
            // routeName="Dashboard"
            // onClick={...}
          />
          <LoanHeader />
          <BannerHandler />

          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <StatusCard />
            <SummaryCard />
          </div>

          {statusLabel !== "Processing" && <TransactionsTable />}
        </div>
      </div>

      <Footer />
    </>
  );
}

function Footer() {
  return (
    <div className="text-txt-dimmed flex w-full flex-col items-center justify-between gap-2 px-16 py-6 text-center text-xs sm:flex-row">
      <div>Prime Financial Technologies Inc</div>
      <div className="text-btn flex flex-row gap-6 text-left">
        <a href="/docs/terms-of-service">Disclosures</a>
        <a href="mailto:support@primeft.com">Support</a>
      </div>
    </div>
  );
}

function BannerHandler() {
  const { loan } = Route.useLoaderData();
  const { loanId } = Route.useParams();
  const { status, overdue_amount } = loan;
  const hasOverdueBalance = parseFloat(overdue_amount) > 0;

  if (
    status === "pending_execution" ||
    status === "ready_for_execution" ||
    status === "ready_for_servicing" ||
    status === "ready_for_disbursement"
  ) {
    return (
      <Banner
        title="Welcome to your loan dashboard!"
        description="This is where you&rsquo;ll be able to monitor the status of your loan, view your payments, access documents and more. Bookmark this page!"
        icon={<BookmarkIcon className="h-9 w-9 shrink-0" />}
      />
    );
  }

  if (hasOverdueBalance) {
    const overdueBalance = USDollar.format(parseFloat(overdue_amount));
    return (
      <Banner
        variant={"destructive"}
        title={`You have an overdue balance of ${overdueBalance}`}
        description="Pay this balance within 3 days to avoid any additional interest or fees."
        icon={<TriangleAlert className="h-9 w-9 shrink-0" />}
        button={
          <Button className="bg-surface-card hover:bg-surface-card text-action-negative hover:text-action-negative w-full hover:opacity-95 sm:w-fit">
            <Link to="/loans/$loanId/payment" params={{ loanId }}>
              Pay {overdueBalance} Now
            </Link>
          </Button>
        }
      />
    );
  }

  return null;
}

const STATUS_ICON_MAP: Record<LoanStatus, LucideIcon | null> = {
  // Processing
  pending_execution: Cog,
  ready_for_execution: Cog,
  ready_for_servicing: Cog,
  ready_for_disbursement: Cog,
  // Funding
  disbursed: CircleArrowRight,
  // Active
  payment: Zap,
  // Overdue/Delinquent
  delinquent: TriangleAlert,
  // Repaid
  repaid: BadgeCheck,
  // Nullable
  closed: null,
  canceled: null,
};

const STATUS_TO_LABEL_MAP: Record<
  LoanStatus,
  | "Processing"
  | "Funding"
  | "Active"
  | "Overdue"
  | "Repaid"
  | "Closed"
  | "Canceled"
> = {
  pending_execution: "Processing",
  ready_for_execution: "Processing",
  ready_for_servicing: "Processing",
  ready_for_disbursement: "Funding",
  payment: "Active",
  disbursed: "Active",
  repaid: "Repaid",
  delinquent: "Overdue",
  closed: "Closed",
  canceled: "Canceled",
};

function LoanStatusPill() {
  const { loan } = Route.useLoaderData();
  const { status } = loan;
  const StatusIcon = STATUS_ICON_MAP[status];
  const statusLabel = STATUS_TO_LABEL_MAP[status];

  return (
    <div
      data-status={statusLabel}
      className={
        "bg-surface-light-branded text-icon flex flex-row items-end" +
        ' [&[data-status="Repaid"]]:bg-accent-repaid' +
        ' [&[data-status="Overdue"]]:text-surface-card [&[data-status="Overdue"]]:bg-accent-failure' +
        ' [&[data-status="Delinquent"]]:text-surface-card [&[data-status="Delinquent"]]:bg-accent-failure' +
        " gap-1 rounded-full px-3 py-2 text-sm"
      }
    >
      {StatusIcon && (
        <>
          <StatusIcon className="h-5 w-5 shrink-0" />
          <div className="leading-[150%]">{statusLabel}</div>
        </>
      )}
    </div>
  );
}

function IllustrationContent({
  illustration,
  children,
}: {
  illustration: ReactElement;
  children: ReactElement | ReactElement[];
}) {
  return (
    <div className="border-border-dark flex flex-1 flex-col items-center justify-start self-stretch overflow-hidden rounded-xl border-[1px] border-solid text-sm sm:flex-row">
      <div className="flex flex-1 items-center justify-center self-stretch bg-[#ebeaff]">
        {illustration}
      </div>
      <div className="flex flex-1 flex-row items-center justify-center self-stretch p-4 sm:px-6">
        {children}
      </div>
    </div>
  );
}

function ProcessingStatusCardContent() {
  return (
    <IllustrationContent illustration={<ReviewSvg className="max-w-60" />}>
      <div className="flex-1">
        <p className="[margin-block-end:12px] [margin-block-start:0]">
          Our system is processing your loan. We’ll send you a confirmation
          email when everything is finalized and your loan is activated
        </p>
        <p className="m-0">
          Once your loan is active you can return to this page to monitor your
          balance and the status of your payments..
        </p>
      </div>
    </IllustrationContent>
  );
}

function RepaidStatusCardContent() {
  return (
    <IllustrationContent illustration={<RepaidSvg className="max-w-60" />}>
      <div className="flex-1">
        <p>Well done! Your loan has been repaid.</p>
        <p>We appreciate doing business with you!</p>
      </div>
    </IllustrationContent>
  );
}

function BankInfo() {
  const { bankAccount } = Route.useLoaderData();
  const { name: bank, ach_account_number: account_number = "XXXX" } =
    bankAccount?.[0] || {};
  return (
    <div className="bg-surface-inset flex w-full flex-col items-start justify-center rounded-xl p-3 text-left text-[16px]">
      <div className="flex flex-1 flex-row items-center gap-3">
        <div className="bg-txt-inverted flex h-12 w-12 flex-row items-center justify-center rounded-lg">
          <LandmarkIcon />
        </div>
        <div className="flex flex-col">
          <div>{bank}</div>
          <div className="text-txt-tertiary text-xs">
            Account ending in {account_number.slice(-4)}
          </div>
        </div>
      </div>
    </div>
  );
}

function FundingStatusCardContent() {
  const { loan, transactions } = Route.useLoaderData();
  const loanAmount = USDollar.format(
    parseFloat(loan.servicing_terms.capital_amount)
  );

  const disbursement = useMemo(
    () => transactions.find(({ type }) => type === "disbursement"),
    [transactions]
  );

  return (
    <IllustrationContent illustration={<FundingSvg className="max-w-60" />}>
      <div className="flex flex-1 flex-col self-stretch">
        <p className="flex-1 self-stretch [margin-block-end:12px] [margin-block-start:0]">
          An ACH transfer to your bank account of {loanAmount} was initiated on{" "}
          {formatDatetime(disbursement?.effective_at || new Date())}. You can
          expect this transfer to clear within 48 hours. You will receive an
          email once the transfer has completed.
        </p>
        <BankInfo />
      </div>
    </IllustrationContent>
  );
}

type LoanProgressBarInput = {
  pending: number;
  overdue: number;
  paid: number;
  total: number;
};
const LoanProgressBar = ({
  pending,
  overdue,
  paid,
  total,
}: LoanProgressBarInput) => {
  const paidPercentage = (paid / total) * 100;
  const pendingPercentage = (pending / total) * 100;
  const overduePercentage = (overdue / total) * 100;
  return (
    <div className="bg-surface-inset flex w-full flex-row items-start justify-start gap-0.5 rounded p-1">
      {paid ? (
        <div
          style={{ width: `${paidPercentage}%` }}
          className={`bg-accent-success h-2 overflow-hidden first:rounded-l-sm last:rounded-r-sm`}
        ></div>
      ) : null}
      {pending ? (
        <div
          style={{ width: `${pendingPercentage}%` }}
          className={`bg-accent-pending-inverted bg-45-degree animate-move-stripes h-2 overflow-hidden first:rounded-l-sm last:rounded-r-sm`}
        ></div>
      ) : null}
      {overdue ? (
        <div
          style={{ width: `${overduePercentage}%` }}
          className={`bg-accent-failure h-2 overflow-hidden first:rounded-l-sm last:rounded-r-sm`}
        ></div>
      ) : null}
    </div>
  );
};

function formatDate(date: Date): string {
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return month + "/" + day + "/" + year;
}

function ActiveStatusCardContent() {
  const { loan, transactions } = Route.useLoaderData();

  const pending: number = transactions.reduce(
    (sum, { type, status, total_amount }) => {
      if (type === "remittance" && status === "processing") {
        return sum + parseFloat(total_amount);
      }
      return sum;
    },
    0
  );

  const paid = parseFloat(loan.total_paid);

  const paidBalance = USDollar.format(paid).slice(1);
  const remainingBalance = USDollar.format(
    parseFloat(loan.remaining_amount)
  ).slice(1);

  const pendingAmount = USDollar.format(pending);
  const overdueAmount = USDollar.format(parseFloat(loan.overdue_amount));

  return (
    <div className="flex flex-1 flex-col">
      <div className="text-txt-tertiary flex w-full flex-row pb-2 font-medium">
        <div className="flex flex-1 flex-col items-start justify-center gap-[3px] rounded-2xl px-0 py-2.5">
          <div className="flex flex-row items-center justify-start gap-2 text-sm">
            <p className="bg-surface-inset flex h-6 w-6 items-center justify-center rounded">
              $
            </p>
            <p>Total Paid</p>
          </div>
          <StyledDecimals
            value={paidBalance}
            className="text-txt-primary *:text-txt-tertiary text-4xl font-normal *:text-2xl *:font-medium"
          />
        </div>
        <div className="flex flex-1 flex-col items-end justify-center gap-[3px] rounded-2xl px-0 py-2.5 text-center">
          <p className="text-sm">Remaining Balance</p>
          <StyledDecimals
            value={remainingBalance}
            className="text-txt-primary *:text-txt-tertiary text-4xl font-normal *:text-2xl *:font-medium"
          />
        </div>
      </div>
      <LoanProgressBar
        paid={paid}
        pending={pending}
        overdue={parseFloat(loan.overdue_amount)}
        total={parseFloat(loan.servicing_terms.total_loan_amount)}
      />
      <div className="flex w-full flex-row justify-end gap-6 pt-3 text-sm">
        {pending ? (
          <p className="text-accent-success mr-auto">{pendingAmount} Pending</p>
        ) : null}
        {parseFloat(loan.overdue_amount) ? (
          <p className="text-[#ff758a]">{overdueAmount} Overdue</p>
        ) : null}
      </div>
    </div>
  );
}

const StatusCardFooter = () => {
  const { loanId } = Route.useParams();
  const { bankAccount, loan } = Route.useLoaderData();

  const { name: bank, ach_account_number: account_number = "XXXX" } =
    bankAccount?.[0] || {};
  const isLoanSeasoned = loan.payment_allowed;

  const MakePaymentButton = (props: ButtonProps) => (
    <Button className="w-full gap-2 font-normal sm:w-fit" {...props} />
  );

  return (
    <CardFooter className="bg-surface-inset text-txt-primary flex flex-col items-start justify-between gap-4 p-4 text-base sm:flex-row sm:items-center sm:gap-6 sm:p-6">
      <div className="flex flex-1 flex-row gap-3">
        <div className="bg-surface-card border-border-dark flex h-12 w-12 flex-row items-center justify-center overflow-hidden rounded-lg border border-solid">
          <LandmarkIcon />
        </div>
        <div>
          <div className="text txt-secondary font-normal">{bank}</div>
          <div className="text-txt-tertiary text-sm leading-[150%]">
            Account ending in {account_number.slice(-4)}
          </div>
        </div>
      </div>
      {isLoanSeasoned ? (
        <MakePaymentButton asChild>
          <Link to="/loans/$loanId/payment" params={{ loanId }}>
            <BanknoteIcon className="h-5" />
            Make a Payment
          </Link>
        </MakePaymentButton>
      ) : (
        <AlertDialog>
          <AlertDialogTrigger>
            <MakePaymentButton>
              <BanknoteIcon className="h-5" />
              Make a Payment
            </MakePaymentButton>
          </AlertDialogTrigger>

          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Make a Payment</AlertDialogTitle>
              <AlertDialogDescription>
                We do not accept manual payments until a loan has been active
                for 2 business days.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel className="w-full">OK</AlertDialogCancel>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </CardFooter>
  );
};

function StatusCard() {
  const { loan } = Route.useLoaderData();
  const { status } = loan;

  const statusLabel = STATUS_TO_LABEL_MAP[status];

  return (
    <div className="bg-background-page flex w-full justify-center rounded-3xl">
      <Card className={"text-txt-primary bg-surface-card max-w-2xl flex-1"}>
        <CardHeader className="flex flex-row items-end justify-between pb-3">
          <CardTitle className="text-2xl font-medium tracking-tight">
            Status
          </CardTitle>
          <LoanStatusPill />
        </CardHeader>
        <CardContent>
          {statusLabel === "Processing" && <ProcessingStatusCardContent />}
          {statusLabel === "Funding" && <FundingStatusCardContent />}
          {statusLabel === "Active" && <ActiveStatusCardContent />}
          {statusLabel === "Overdue" && <ActiveStatusCardContent />}
          {statusLabel === "Repaid" && <RepaidStatusCardContent />}
        </CardContent>
        {statusLabel === "Active" && <StatusCardFooter />}
      </Card>
    </div>
  );
}

function SummaryCard() {
  const [viewAll, setViewAll] = useState(false);
  const { loan } = Route.useLoaderData();
  const { servicing_terms } = loan;

  const {
    capital_amount,
    remittance_payment,
    total_loan_amount,
    effective_apr,
    total_number_of_payments,
    loan_term_days,
    remittance_frequency,
    fee_amount,
  } = servicing_terms;

  const loanAmount = USDollar.format(parseFloat(capital_amount));

  const paymentAmount = USDollar.format(parseFloat(remittance_payment));

  const totalFees = USDollar.format(parseFloat(fee_amount));

  const totalRepayAmount = USDollar.format(parseFloat(total_loan_amount));

  // Hide "action" buttons until work scoped
  const showActionButtons = false;

  return (
    <div className="bg-background-page flex w-full justify-center rounded-3xl">
      <Card className="max-w-2xl flex-1">
        <CardHeader className="flex flex-row items-end justify-between pb-3">
          <CardTitle className="text-txt-primary text-2xl font-medium tracking-tight">
            Summary of Terms
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 gap-x-9 gap-y-3 sm:grid-cols-2">
            <TermItem
              Icon={BanknoteIcon}
              title="Loan Amount"
              value={loanAmount}
            />
            <TermItem
              Icon={RefreshCwIcon}
              title="Auto Payments"
              value={remittance_frequency}
              className={viewAll ? "capitalize" : "hidden capitalize sm:flex"}
            />
            <TermItem
              Icon={PercentIcon}
              title="Interest Rate"
              value={`${effective_apr}%`}
            />
            <TermItem
              Icon={BarChart4Icon}
              title="Total Payments"
              value={`${total_number_of_payments}`}
              className={viewAll ? "" : "hidden sm:flex"}
            />
            <TermItem
              Icon={ReceiptIcon}
              title="Financing Fee"
              value={totalFees}
              className={viewAll ? "" : "hidden sm:flex"}
            />
            <TermItem
              Icon={CalendarIcon}
              title="Time to Repay"
              value={`${Math.round(loan_term_days / 30.4167)} months`}
            />
            <TermItem
              Icon={CircleDollarSignIcon}
              title="Total to Repay"
              value={totalRepayAmount}
            />
            <TermItem
              Icon={PieChartIcon}
              title="Payment Amount"
              value={paymentAmount}
            />
          </div>
          <Button
            className={`${viewAll ? "hidden" : "flex sm:hidden"} mt-4 w-full`}
            variant={"outline"}
            onClick={() => setViewAll(true)}
          >
            See More
          </Button>

          {showActionButtons && (
            <div
              className={`mt-6 ${viewAll ? "flex" : "hidden sm:flex"} flex-col items-start justify-start gap-3 self-stretch border-t-[1px] border-solid px-0 pb-0 pt-6 text-center sm:flex-row`}
            >
              <Button
                variant="link"
                className="text-action-primary bg-surface-inset w-full gap-2"
              >
                <FileCheck className="h-5 w-5" />
                View Complete Terms
              </Button>
              <Button
                variant="link"
                className="text-action-primary bg-surface-inset w-full gap-2"
              >
                <Download className="h-5 w-5" />
                Download Documents
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

function LoanHeader() {
  const { loan } = Route.useLoaderData();

  const { overdue_amount, status } = loan;

  const statusLabel = STATUS_TO_LABEL_MAP[status];

  const STATUS_MESSAGE_MAP: Record<
    NonNullable<typeof statusLabel | "Behind">,
    string
  > = {
    Processing: "is being finalized 🪄",
    Funding: "is being funded 💰",
    Active: "is on track 👌",
    Behind: "is a little behind 👀",
    Overdue: "is overdue ‼️",
    Repaid: "is fully repaid  🎉",
    Closed: "",
    Canceled: "",
  };

  const isOverdue = parseFloat(overdue_amount) > 0;
  const message = STATUS_MESSAGE_MAP[isOverdue ? "Behind" : statusLabel];

  return (
    <div className="text-txt-primary flex w-full flex-row items-end justify-between text-left text-3xl font-medium">
      <p>Your loan {message}</p>
    </div>
  );
}

type Label = "Failed" | "Completed" | "Pending" | "Scheduled" | "Reversed";
type Variant = "failure" | "success" | "pending" | "scheduled" | "reversed";
type BadgeMap = Record<
  ServicingTransaction["status"],
  [Label, Variant, string]
>;

const BADGE_MAP: BadgeMap = {
  failed: ["Failed", "failure", "bg-accent-failure"],
  processed: ["Completed", "success", "bg-accent-success"],
  processing: ["Pending", "pending", "border border-accent-success"],
  scheduled: ["Scheduled", "scheduled", "bg-accent-scheduled"],
  canceled: ["Reversed", "reversed", "bg-accent-reversed"],
};

type TransactionLabel = "Auto Payment" | "Manual Payment" | "Funding";

const getTransactionLabel = (tx: ServicingTransaction): TransactionLabel => {
  if (tx.type === "disbursement") return "Funding";
  return tx.is_auto_payment ? "Auto Payment" : "Manual Payment";
};

const TX_TYPE_BADGE_MAP: Record<TransactionLabel, LucideIcon> = {
  Funding: SquareArrowRight,
  "Auto Payment": RefreshCwIcon,
  "Manual Payment": UserRoundCheck,
};

function TransactionsTable() {
  const { transactions } = Route.useLoaderData();
  if (!transactions.length) return null;

  return (
    <div className="bg-surface-card flex flex-col gap-4 rounded-3xl p-4 sm:p-6">
      <p className="text-txt-primary text-2xl font-medium tracking-tight">
        Transactions
      </p>
      <Table>
        <TableHeader>
          <TableRow className="bg-surface-inset text-txt-primary border-0">
            <TableHead className="w-2/5 rounded-l-xl px-6 text-left">
              Payments
            </TableHead>
            <TableHead className="w-1/5 px-6 text-center">Status</TableHead>
            <TableHead className="w-1/5 px-6 text-center">Principal</TableHead>
            <TableHead className="w-1/5 px-6 text-center">Interest</TableHead>
            <TableHead className="w-1/5 rounded-r-xl px-6 text-right">
              Amount
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="p-6">
          {transactions.map((tx) => {
            const {
              total_amount,
              effective_at,
              type,
              status,
              principal_amount,
              interest_amount,
            } = tx;
            const principalAmount = USDollar.format(
              parseFloat(principal_amount)
            );
            const interestAmount = USDollar.format(parseFloat(interest_amount));
            const totalAmount = USDollar.format(
              type === "disbursement"
                ? parseFloat(total_amount)
                : -parseFloat(total_amount)
            );

            const paymentLabel = getTransactionLabel(tx);
            const PaymentBadge = TX_TYPE_BADGE_MAP[paymentLabel];
            const date = formatDate(new Date(effective_at));
            const [label, variant, dotStyles] = BADGE_MAP[status];

            return (
              <TableRow
                key={`${effective_at}-${total_amount}}`}
                className={"text-txt-tertiary h-14 text-xs lg:text-sm"}
              >
                <TableCell className="">
                  <div className="flex items-center gap-3 lg:gap-4">
                    <p>{date}</p>
                    <div className="flex items-center gap-1.5 lg:gap-2.5">
                      <PaymentBadge className="text-icon h-6 w-6" />
                      <p className="text-txt-secondary flex-1">
                        {paymentLabel}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-center">
                  <Badge variant={variant} className="hidden sm:inline-flex">
                    {label}
                  </Badge>
                  <div
                    className={`inline-flex h-2 w-2 rounded-full sm:hidden ${dotStyles}`}
                  ></div>
                </TableCell>
                <TableCell className="text-center">{principalAmount}</TableCell>
                <TableCell className="text-center">{interestAmount}</TableCell>
                <TableCell className="text-right">{totalAmount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function StyledDecimals({
  value,
  className = "text-xs",
}: {
  value: string;
  className?: string;
}) {
  const [integers, decimals] = value.split(".");
  // Checks if all decimals are digits
  if (decimals && /^\d+$/.test(decimals)) {
    return (
      <p className={className}>
        {integers}
        <span>.{decimals}</span>
      </p>
    );
  }
  return <p className={className}>{value}</p>;
}

function TermItem({
  Icon,
  title,
  value,
  className = "",
}: {
  Icon: LucideIcon;
  title: string;
  value: string;
  className?: string;
}) {
  return (
    <div
      className={`flex items-center justify-between gap-1 py-2 text-sm ${className}`}
    >
      <div className="flex items-center gap-2">
        <Icon className="text-icon h-6 w-6" />
        <p className="text-txt-secondary font-medium">{title}</p>
      </div>
      <StyledDecimals value={value} />
    </div>
  );
}
