import { ArrowRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { maskEIN, maskPhone } from "@prime/ui/lib/masks";
import { AddressInput } from "@prime/ui/src/address-input";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@prime/ui/src/select";

import {
  type BusinessType,
  BUSINESS_TYPES,
} from "../../../../../apps/apply-pop/src/types";
import { useIdentityContext } from "../contexts/identity";
import { convertPlaceAddressComponentsToAddress } from "../lib/address";
import { businessSchema, type BusinessInformation } from "../lib/schema";

export default function Business() {
  const {
    businessInformation,
    setBusinessInformation,
    googlePlacesApiKey,
    setStep,
  } = useIdentityContext();

  const form = useForm<BusinessInformation>({
    resolver: zodResolver(businessSchema),
    defaultValues: businessInformation,
  });

  function onSubmit(values: BusinessInformation) {
    setBusinessInformation(values);
    setStep("review");
  }

  const onAddressSuggestionSelect = (
    address: google.maps.GeocoderAddressComponent[],
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    const convertedAddress = convertPlaceAddressComponentsToAddress(address);

    // Set the implicit google address values
    form.setValue(
      "_google_address_line_1",
      convertedAddress._google_address_line_1
    );
    form.setValue(
      "_google_address_line_2",
      convertedAddress._google_address_line_2
    );
    form.setValue(
      "_google_address_city",
      convertedAddress._google_address_city
    );
    form.setValue(
      "_google_address_state",
      convertedAddress._google_address_state
    );
    form.setValue(
      "_google_address_postal_code",
      convertedAddress._google_address_postal_code
    );
    form.setValue(
      "_google_address_country_code",
      convertedAddress._google_address_country_code
    );

    // Set the address search input value
    form.setValue("address_search", suggestion.description, {
      shouldValidate: true,
    });
  };

  const BUSINESS_TYPE_LABEL_MAP: Record<BusinessType, string> = {
    sole_proprietorship: "Sole Proprietorship",
    llc: "LLC",
    partnership: "Partnership",
    corporation: "Corporation",
    trust: "Trust",
    cooperative: "Cooperative",
    other: "Other",
  };

  return (
    <Page>
      <PageHeading>
        <PageTitle>Tell Us About Your Business</PageTitle>
        <PageDescription>
          We need this information to verify the identity of the business that
          will be receiving the loan.
        </PageDescription>
      </PageHeading>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-4 flex flex-col gap-4"
        >
          {/* Business Name */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="business_name"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Legal Business Name"
                        autoComplete="organization"
                        inputMode="text"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Full legal or DBA business name as it appears on your tax
                      return
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Business Structure */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="business_type"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger placeholder="Business Structure">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {BUSINESS_TYPES.map((btype) => (
                        <SelectItem key={btype} value={btype}>
                          {BUSINESS_TYPE_LABEL_MAP[btype]}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Address */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="address_search"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <AddressInput
                        googlePlacesApiKey={googlePlacesApiKey}
                        placeholder="Business Address"
                        onSuggestionSelect={onAddressSuggestionSelect}
                        inputMode="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Phone number */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Business Phone Number"
                        valueModifier={maskPhone}
                        inputMode="tel"
                        autoComplete="tel"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* EIN */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="ein"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Business EIN"
                        valueModifier={maskEIN}
                        inputMode="numeric"
                        autoComplete="tax-id"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* email */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Business email"
                        inputMode="email"
                        autoComplete="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* website */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="website"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Website"
                        inputMode="url"
                        autoComplete="url"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* ownership */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="ownership_percentage"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Ownership Percentage"
                        inputMode="numeric"
                        {...field}
                      />
                    </FormControl>

                    <FormDescription>
                      What percentage of the business do you own?
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          <Button className="mt-2 h-11 md:ml-auto">
            Continue
            <ArrowRight className="ml-2 h-4 w-4" aria-hidden="true" />
          </Button>
        </form>
      </Form>
    </Page>
  );
}
