import { createFileRoute, redirect } from "@tanstack/react-router";

import { verifyApplication } from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/verification/review/"
)({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;

    const { stage } = await verifyApplication({
      apiClient,
      params: { id: params.applicationId as string },
    });

    if (stage === "verification_approved") {
      throw redirect({
        to: "/applications/$applicationId/connect-bank",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }

    if (stage === "verification_denied") {
      throw redirect({
        to: "/applications/$applicationId/verification/denied",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }
  },
  component: Page,
  pendingComponent: LoadingScreen,
});

export function Page() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Your application is currently under review.
        </h1>
        <p className="text-text-600 text-base lg:text-lg lg:leading-tight">
          Please check back regularly.
        </p>
      </div>
    </div>
  );
}
