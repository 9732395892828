import * as React from "react";
import { Eye, EyeOff } from "lucide-react";

import { cn } from "../../lib/css";
import { Label } from "../label";

type InputAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export type InputProps = InputAttributes &
  Required<Pick<InputAttributes, "placeholder">> & {
    valueModifier?: (value: string) => string;
    containerClass?: string;
  };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      containerClass,
      className,
      type = "text",
      onChange: _onChange,
      valueModifier,
      ...props
    },
    ref
  ) => {
    const [hide, setHide] = React.useState(true);
    const inputType =
      type === "password" && hide ? type : type !== "password" ? type : "text";

    // A valueModifier can be passed in to modify the event target's value in place
    // (e.g. formatting a date or ssn while the user types in the input field)
    // Here, we check to see if a valueModifier was passed in,
    // and if so, we modify the value before passing it along to the onChange handler.
    // Note: If you are using react-hook-form, an onChange handler will be passed along automatically
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (valueModifier) {
        evt.target.value = valueModifier(evt.target.value);
        _onChange?.(evt);
      } else {
        _onChange?.(evt);
      }
    };

    const eyeIconClassName = "cursor-pointer absolute top-[32%] right-[2%]";

    return (
      <div className={cn("relative", containerClass)}>
        <input
          type={inputType}
          className={cn(
            `bg-background-input ring-offset-background aria-[invalid=true]:border-action-border-negative peer flex h-14 w-full rounded-md border px-3 pt-3.5 text-sm placeholder-transparent shadow-[0,2px,4px,-2px,rgb(0,0,0)/.1] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`,
            className
          )}
          ref={ref}
          onChange={onChange}
          {...props}
        />
        {type === "password" &&
          (hide ? (
            <Eye
              width={25}
              className={eyeIconClassName}
              onClick={() => {
                setHide(false);
              }}
            />
          ) : (
            <EyeOff
              width={25}
              className={eyeIconClassName}
              onClick={() => {
                setHide(true);
              }}
            />
          ))}

        <Label
          htmlFor={props.id}
          className={`text-label peer-focus:text-text-label-focused peer-aria-[invalid=true]:text-text-label-negative pointer-events-none absolute -translate-y-[50px] translate-x-[13px] text-xs font-normal transition-all peer-placeholder-shown:-translate-y-[40px] peer-placeholder-shown:text-[0.8rem] peer-placeholder-shown:leading-6 peer-focus:-translate-y-[50px] peer-focus:text-xs`}
        >
          {props.placeholder}
        </Label>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
