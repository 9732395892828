import React from "react";

import { Application } from "@/types";

import Business from "./(steps)/business";
import Personal from "./(steps)/personal";
import Review from "./(steps)/review";
import {
  IdentityContextProvider,
  useIdentityContext,
} from "./contexts/identity";
import {
  type BusinessInformation,
  type PersonalInformation,
} from "./lib/schema";

export default function Page({
  googlePlacesApiKey,
  isSubmitting,
  onSubmit,
  application,
  userEmail,
}: {
  googlePlacesApiKey: string;
  isSubmitting: boolean;
  onSubmit: ({
    personalInformation,
    businessInformation,
  }: {
    personalInformation: PersonalInformation;
    businessInformation: BusinessInformation;
  }) => Promise<void>;
  application?: Application;
  userEmail?: string;
}) {
  return (
    <IdentityContextProvider
      googlePlacesApiKey={googlePlacesApiKey}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      application={application}
      userEmail={userEmail}
    >
      <div className="flex max-w-[480px] flex-1 flex-col gap-6">
        <IdentityVerification />
      </div>
    </IdentityContextProvider>
  );
}

function IdentityVerification() {
  const { step } = useIdentityContext();

  switch (step) {
    case "personal": {
      return <Personal />;
    }
    case "business": {
      return <Business />;
    }
    case "review": {
      return <Review />;
    }
  }
}

export { type BusinessInformation, type PersonalInformation };
