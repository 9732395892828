import * as z from "zod";

import { BUSINESS_TYPES } from "../../../../../apps/apply-pop/src/types";
import { dateIsValid } from "./date";

export type PersonalInformation = z.infer<typeof personalSchema>;
export type BusinessInformation = z.infer<typeof businessSchema>;

// export type PersonMetadata = z.infer<typeof PersonMetadataSchema>;
// export type BusinessMetadata = z.infer<typeof BusinessMetadataSchema>;

const personalSchemaBase = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  role: z.enum(["ceo", "manager", "accountant", "vice_president"], {
    message: "Please select a role",
  }),
  type: z.enum(["owner", "employee"], {
    message: "Please select an employment type",
  }),
  address_search: z
    .string()
    .min(1, { message: "Please select an address from the dropdown" }),
  email: z.string().email({ message: "Please enter a valid email address" }),
  phone: z.string().refine((value) => value.replace(/\D/g, "").length === 10, {
    message: "Please enter a valid phone number",
  }),
  date_of_birth: z.string().refine(
    (value) => {
      const validDate = dateIsValid(value);
      return (
        !!validDate &&
        validDate < new Date() &&
        validDate >= new Date(1900, 0, 1)
      );
    },
    { message: "Date of birth is required" }
  ),
  ssn: z.string().refine((value) => value.replace(/\D/g, "").length === 9, {
    message: "Social security number is required",
  }),
});

const businessSchemaBase = z.object({
  business_name: z.string().min(1, { message: "Business name is required" }),
  business_type: z.enum(BUSINESS_TYPES, {
    message: "Please select a business type from the dropdown",
  }),
  address_search: z
    .string()
    .min(1, { message: "Please select an address from the dropdown" }),
  phone: z.string().refine((value) => value.replace(/\D/g, "").length === 10, {
    message: "Please enter a valid phone number",
  }),
  ein: z.string().refine((value) => value.replace(/\D/g, "").length === 9, {
    message: "EIN is required",
  }),
  email: z.string().min(1, { message: "Email is required" }),
  website: z.string().optional(),
});

const ownershipSchema = z.object({
  ownership_percentage: z.coerce.string().refine(
    (value) => {
      return Number(value) > 0 && Number(value) <= 100;
    },
    {
      message: "Ownership percentage must be between 1 and 100",
    }
  ),
});

const googleAddressSchema = z.object({
  _google_address_line_1: z.string(),
  _google_address_line_2: z.string(),
  _google_address_city: z.string(),
  _google_address_state: z.string(),
  _google_address_postal_code: z.string(),
  _google_address_country_code: z.string(),
});

export type GoogleAddress = z.infer<typeof googleAddressSchema>;

export const personalSchema = personalSchemaBase
  .merge(googleAddressSchema)
  .refine(
    (data) => {
      return (
        Boolean(data._google_address_line_1) &&
        Boolean(data._google_address_city) &&
        Boolean(data._google_address_state) &&
        Boolean(data._google_address_postal_code) &&
        Boolean(data._google_address_country_code)
      );
    },
    {
      message: "Please select an address from the dropdown",
      path: ["address_search"],
    }
  );

export const businessSchema = businessSchemaBase
  .merge(ownershipSchema)
  .merge(googleAddressSchema)
  .refine(
    (data) => {
      return (
        Boolean(data._google_address_line_1) &&
        Boolean(data._google_address_city) &&
        Boolean(data._google_address_state) &&
        Boolean(data._google_address_postal_code) &&
        Boolean(data._google_address_country_code)
      );
    },
    {
      message: "Please select an address from the dropdown",
      path: ["address_search"],
    }
  );

const AddressSchema = z.object({
  street_address_1: z.string().optional().nullable(),
  street_address_2: z.string().optional().nullable(),
  locality: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  postal_code: z.string().optional().nullable(),
});

const PersonMetadataSchema = z.object({
  name_first: z.string().optional().nullable(),
  name_last: z.string().optional().nullable(),
  birth_date: z.string().optional().nullable(),
  email_address: z.string().optional().nullable(),
  phone_number: z.string().optional().nullable(),
  ssn: z.string().optional().nullable(),
  address: AddressSchema.optional().nullable(), // address is an optional object
  type: z.enum(["owner", "employee"]).optional().nullable().or(z.literal("")),
});

const BusinessMetadataSchema = z.object({
  name: z.string().optional().nullable(),
  ein: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
  address: AddressSchema.optional().nullable(),
  website: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  business_type: z.enum(BUSINESS_TYPES).optional().nullable().or(z.literal("")),
});

export const AdditionalMetadataSchema = z.object({
  business_metadata: BusinessMetadataSchema.optional().nullable(),
  person_metadata: PersonMetadataSchema.optional().nullable(),
});
