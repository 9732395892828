import CreditPull from "@/routes/_docs/docs/-content/credit-pull";
import ESign from "@/routes/_docs/docs/-content/e-sign";
import { PrivacyPolicy, TermsOfService } from "@prime/pop-components/src/docs";
import { ScrollArea } from "@prime/ui/src/scroll-area";

function Agreements() {
  return (
    <>
      <ScrollArea className="relative h-64 w-full overflow-auto rounded-l-md border-2 border-[#DEE1E8] bg-white">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-col gap-3 p-2 sm:p-4">
          <br />
          <TermsOfService />
          <PrivacyPolicy />
          <ESign />
          <CreditPull />
          <br />
        </div>
      </ScrollArea>
      <p className="mt-3 px-2 text-sm font-light">
        By clicking &rdquo;Continue&ldquo;, you agree to the Terms of Service,
        Privacy Policy, E-Sign Agreement, and Credit Pull Consent displayed
        above.
      </p>
    </>
  );
}

export default Agreements;
