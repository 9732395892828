import type { PersonalInformation } from "@prime/screens/src/identity/page";

type ApplicationStage =
  // Pre-qualification Offer terms Related (If applicant is coming through prequal offer link)
  | "offer_generated"
  | "offer_claimed"
  // Ownership Related (Following Identification)
  | "identification_completed"
  // KYC and KYB related (Fraud Verification)
  | "verification_approved"
  | "verification_denied"
  | "verification_review"
  // Connect Bank Related (This is multistep process for user to connect bank)
  | "connect_bank_started"
  | "connect_bank_completed"
  // Payment Provider Setup Related. Relies on MoneyService/Dwolla. After Connect bank these steps are performed
  | "payment_provider_borrower_setup_completed"
  | "payment_provider_borrower_bank_account_setup_completed"
  // Underwriting Related
  | "underwriting_approved"
  | "underwriting_denied"
  | "underwriting_review"
  // Fraud & Underwriting combined stages.
  | "risk_assessment_approved"
  | "risk_assessment_denied"
  | "risk_assessment_review"
  // Review Terms Related
  | "terms_accepted"
  // Review and Sign Agreements Related (Multiple agreements need to be reviewed)
  | "agreements_review_completed"
  // -.-
  | "loan_write_back_setup_completed"
  // Execute Loan Related (This involves async process to complete the execute loan process)
  | "execute_loan_started"
  | "execute_loan_completed"
  // Not related to Prequal application offer Flow
  | "terms_generated"
  | "application"
  // Stages for Origination process
  | "origination_started"
  | "origination_completed";

type ApplicationStatus = "pending" | "in_progress" | "completed";

export type PrequalApplication = {
  id: string;
  requested_loan_amount: string;
  applicant_business_name: string;
  claimed_by_account_id?: string;
  stage: ApplicationStage;
};

export type Application = {
  id: string;
  applicant_business_name: string;
  program_id: string;
  loan_type_id: string;
  loan_id: string | null;
  requested_loan_amount: string;
  stage: ApplicationStage;
  status: ApplicationStatus;
  expires_at: string | null;
  collateral: string | null;
  borrower_id: string | null;
  kyc_record_id: string | null;
  kyb_record_id: string | null;
  terms_id: string | null;
  underwriting_record_id: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  claimed_by_account_id: string;
  verification_record_id: string | null;
  partner_short_id: string | null;
  additional_metadata: Record<string, unknown> | null;
};

export type BusinessPerson = {
  id: string;
  business_id: string;
  person_type: "owner" | "employee";
  person_role: "ceo" | "manager" | "accountant" | "vice_president";
  name_prefix?: string;
  name_suffix?: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  ssn?: string;
  date_of_birth?: string;
  address?: Address;
  phones?: Phone[];
  ownership_percentage?: string;
  is_signer?: boolean;
  is_applicant?: boolean;
  created_at: string;
  updated_at: string;
};

export type CreateBusinessPersonPayload = PersonalInformation & {
  business_id: string;
  is_applicant?: boolean;
  is_signer?: boolean;
  ownership_percentage?: string;
};

export type UpdateBusinessPersonPayload = Partial<
  Omit<BusinessPerson, "updated_at" | "created_at" | "id">
>;

export type CreateAccountPayload = {
  auth0_id: string;
  user_email: string;
  application_id: string;
};

export type CreateAccountResponse = {
  id: string;
  auth0_id: string;
  email: string;
  application: Application;
};

export type GetAccountResponse = {
  id: string;
  auth0_id: string;
  email: string;
  application: Application;
};

export type AccountsApplicationsResponse = {
  id: string;
  applications: Application[];
};

type Address = {
  street_address_1: string;
  street_address_2?: string;
  locality: string;
  region: string;
  country: string;
  postal_code: string;
};

type Phone = {
  country_code: string;
  number: string;
  type: "mobile" | "home" | "office";
};

export const BUSINESS_TYPES = [
  "sole_proprietorship",
  "llc",
  "partnership",
  "corporation",
  "trust",
  "cooperative",
  "other",
] as const;

export type BusinessType = (typeof BUSINESS_TYPES)[number];

export type Borrower = {
  id: string;
  name: string;
  address: Address;
  phones: Phone[];
  created_at: Date;
  updated_at: Date;
  legal_name: string;
  ein: string;
  enigma_id?: string;
  website: string;
  business_type: BusinessType;
  email: string;
};

export type ApplicationUpdate = {
  business_id?: string;
  expires_at?: string;
  collateral?: Record<string, unknown>;
  requested_loan_amount?: number;
  stage?: ApplicationStage;
  status?: ApplicationStatus;
};

export type VerificationResponse = Application & {
  verification_status: "approved" | "denied" | "review";
};

export type OpenBankingWidgetResponse = {
  application_id: string;
  ob_widget_token: string;
  vendor_name: "plaid";
};

export type OpenBankingReconnectWidgetResponse = {
  bank_account_id: string;
  ob_widget_token: string;
  vendor_name: string;
};

export type OpenBankingConnectBankResponse = {
  vendor_name: "plaid";
  application_id: string;
  ob_connection_id: string;
};

export type UnderwriteResponse = Application & {
  underwriting_status: "approved" | "denied" | "review";
  risk_assessement_status?: "approved" | "denied" | "review";
};

export type Terms = {
  id: string;
  application_id: string;
  loan_type_id: "bnpl" | "mca";
  fee_percentage: string;
  loan_term_days: number;
  effective_apr: string;
  fee_amount: string;
  total_loan_amount: string;
  remittance_frequency: number;
  remittance_payment: string;
  loan_amount: string;
  total_number_of_payments: number;
  payment_period_title: string;
  origination_fee_amount: string;
};

export type Agreement = {
  agreement_id: string;
  agreement_content_html: string;
  agreement_type?: AgreementType;
  created_at?: string;
};

export type AgreementReview = {
  application_id: string;
  agreement: Agreement;
};

export type AgreementType =
  | "loan_agreement"
  | "unsecured_financing"
  | "itemization"
  | "privacy_notice_for_prime_and_lead"
  | "prime_terms_and_conditions"
  | "decision_maker"
  | "esign_consent"
  | "credit_pull_consent"
  | "tcpa_consent"
  | "bank_account_info_consent"
  | "offer_summary";

export type AgreementSign = Application & {
  agreement: Omit<Agreement, "agreement_content_html">;
};

export type LoanStatus =
  | "pending_execution"
  | "ready_for_execution"
  | "ready_for_servicing"
  | "ready_for_disbursement"
  | "disbursed"
  | "closed"
  | "repaid"
  | "delinquent"
  | "payment"
  | "canceled";

export type Loan = {
  id: string;
  status: LoanStatus;
  capital_amount: string;
  fees: string;
  borrower_id: string;
  terms: Terms;
  created_at: string;
  updated_at: string;
};

type ServicingTerms = {
  capital_amount: string;
  fee_amount: string;
  effective_apr: string;
  total_loan_amount: string;
  remittance_frequency:
    | "weekly"
    | "bi-weekly"
    | "monthly"
    | "bi-monthly"
    | "every_thirty_days";
  total_number_of_payments: number;
  loan_term_days: number;
  remittance_payment: string;
};

export type ServicingLoan = {
  id: string;
  servicing_terms: ServicingTerms;
  total_paid: string;
  remaining_amount: string;
  overdue_amount: string;
  next_payment_at: string;
  payment_allowed: boolean;
  status: LoanStatus;
  loan_disbursement_started_at: string;
  borrower_id: string;
  created_at: string;
  updated_at: string;
};

export type ServicingTransaction = {
  effective_at: string;
  status: "processed" | "failed" | "processing" | "canceled" | "scheduled";
  type: "disbursement" | "remittance";
  principal_amount: string;
  interest_amount: string;
  total_amount: string;
  is_auto_payment: boolean;
};

export type ManualPaymentResponse = {
  loan_id: string;
  status: string;
};

export type Document = {
  id: string;
  loan_id: string;
  application_id: string;
  agreement_type?: AgreementType;
  document_type: "loan_agreement" | "signed_loan_agreement";
  created_at: string;
};

export type Transaction = {
  id: string;
  bankaccount_to: string;
  bankaccount_from: string;
  loan_id: string;
  type: "disbursement" | "payment";
  amount: number;
  at_provider_status: string;
  at_provider_id: string;
  at_provider_rails: "ach" | "rtp" | "ata" | "fednow";
  created_at: string;
  updated_at: string;
  status:
    | "ready_for_disbursement"
    | "pending_processing"
    | "processed"
    | "retry";
  service_provider?: string;
};

type BankAccountType = "checking" | "saving" | "money_market" | "cd";

export type BankAccount = {
  id: string;
  business_id: string;
  name?: string;
  bank_name?: string;
  type?: BankAccountType;
  created_at: string;
  updated_at: string;
  is_default: boolean;
  mask?: number;
  ach_account_number?: string;
  ach_routing_number?: string;
};

export enum LoanType {
  FixedInterest = "FIXED_INTEREST_COMPOUND_INSTALLMENT_LOAN",
  DailyInterestAccrual = "DAILY_INTEREST_ACCRUAL_INSTALLMENT_LOAN",
  OriginationFeeNoInterest = "ORIGINATION_FEE_NO_INTEREST_INSTALLMENT_LOAN",
}

export type InvitationDetails = {
  apr: string;
  business_name: string;
  expiration: string;
  loan_total_amount: string;
  loan_amount: string;
  term_length: number;
  weekly_payment: string;
  claimed: boolean;
  loan_type: LoanType;
  origination_fee_amount: string;
};

export type WritebackBankAccount = {
  id: string;
  account_number: string;
  account_name: string;
  is_active: boolean;
  is_lender_bank: boolean;
  borrower_business_id: string;
  should_create_in_partner_system?: boolean;
  partner_platform_id: string;
  partner_platform_transaction_status?: string;
  created_at: string;
  updated_at: string;
};

export type WritebackExpenseAccount = {
  id: string;
  nominal_code: string;
  account_name: string;
  is_active: boolean;
  borrower_business_id: string;
  should_create_in_partner_system?: boolean;
  partner_platform_id: string;
  partner_platform_transaction_status?: string;
  created_at: string;
  updated_at: string;
};

export type WritebackSupplier = {
  id: string;
  supplier_name: string;
  is_active: boolean;
  borrower_business_id: string;
  partner_platform_id: string;
  partner_platform_transaction_status?: string;
  created_at: string;
  updated_at: string;
};

export type WritebackOptInResponse = {
  write_back_opt_in: boolean;
  partner_platform_borrower_bank_account?: WritebackBankAccount;
  partner_platform_lender_bank_account?: WritebackBankAccount;
  partner_platform_borrower_expense_account?: WritebackExpenseAccount;
  partner_platform_lender_supplier?: WritebackSupplier;
};

export type WritebackAccountsResponse = {
  application_id: string;
  partner_platform_accounts: {
    bank_accounts: WritebackBankAccount[];
    expense_accounts: WritebackExpenseAccount[];
    suppliers?: WritebackSupplier[];
  };
};

export type BusinessMetadata = {
  name?: string;
  ein?: string;
  phone?: string;
  address?: Address;
  website?: string;
  email?: string;
  business_type?: string;
};

export type PersonMetadata = {
  name_first?: string;
  name_last?: string;
  birth_date?: string;
  email_address?: string;
  phone_number?: string;
  ssn?: string;
  address?: Address;
  type?: string;
};

export type AdditionalMetadata = {
  business_metadata?: BusinessMetadata;
  person_metadata?: PersonMetadata;
};

export {
  type BusinessInformation,
  type PersonalInformation,
} from "@prime/screens/src/identity/page";
