import * as Sentry from "@sentry/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Loader2 } from "lucide-react";
import { useState } from "react";

import { acceptTerms, getApplicationTerms } from "@/api";
import { Button } from "@prime/ui/src/button";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";
import { Skeleton } from "@prime/ui/src/skeleton";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/terms/"
)({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;

    const terms = await getApplicationTerms({
      apiClient,
      params: { id: params.applicationId },
    });

    return { terms };
  },
  pendingComponent: () => (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <Skeleton className="h-10 w-full" />

      <div className="border-border-500 grid-col grid gap-8 rounded-xl border px-6 py-3">
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="h-12 w-full" />
      </div>
    </div>
  ),
  component: _Page,
});

function _Page() {
  const navigate = useNavigate();
  const { terms: _termsData } = Route.useLoaderData();
  const { apiClient, application } = Route.useRouteContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For now, we will always grab the first term item in the array
  // We'll handle multiple terms later
  const terms = _termsData.terms[0];

  const handleClick = async () => {
    setIsSubmitting(true);

    try {
      await acceptTerms({
        apiClient,
        params: { id: terms.application_id },
        payload: { terms_id: terms.id },
      });

      // If above successful, navigate to the agreements step
      navigate({
        to: "/applications/$applicationId/agreements",
        params: { applicationId: application.id },
        replace: true,
      });
    } catch (err) {
      setIsSubmitting(false);
      Sentry.captureException(err);
    }
  };

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const loanAmount = USDollar.format(parseFloat(terms.loan_amount));

  const totalOriginationFee = USDollar.format(
    parseFloat(terms.origination_fee_amount)
  );

  const interestPaid = USDollar.format(
    parseFloat(terms.fee_amount) - parseFloat(terms.origination_fee_amount)
  );

  const weeklyPayments = terms.total_number_of_payments;

  const paymentAmount = USDollar.format(parseFloat(terms.remittance_payment));

  const totalRepayAmount = parseFloat(terms.total_loan_amount);

  const paymentPeriodTitle: string = terms.payment_period_title;

  return (
    <Page>
      <PageHeading>
        <PageTitle>Your Loan is Approved!</PageTitle>
        <PageDescription>
          Please review the final terms of your loan.
        </PageDescription>
      </PageHeading>

      <div className="border-border-500 rounded-xl border px-6 py-3">
        <TermItem
          title="Loan Amount"
          value={loanAmount}
          description="This is the amount we'll deposit in your bank account."
        />
        <TermItem
          title="Total Interest Paid"
          value={interestPaid}
          description="This is how much you're paying in interest."
        />
        <TermItem
          title="Total Origination Fee Paid"
          value={totalOriginationFee}
          description="This is how much you're paying in fees."
        />
        <TermItem
          title="Total You Repay"
          value={USDollar.format(totalRepayAmount)}
          description="This is the total amount you will have to repay."
        />
        <TermItem
          title="Time to Repay"
          value={`${String(terms.loan_term_days)} days`}
          description="This is the total amount of time it wil take for your loan to be repaid."
        />
        <TermItem
          title={paymentPeriodTitle}
          value={String(weeklyPayments)}
          description="This is how many withdrawls we'll make from your bank."
        />
        <TermItem
          title="Payment Amount"
          value={paymentAmount}
          description="This is the amount we will withdraw each week."
        />
      </div>

      <Button onClick={handleClick} className="mt-6" disabled={isSubmitting}>
        Proceed to Signing
        {isSubmitting && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </Page>
  );
}

function TermItem({
  title,
  value,
  description,
}: {
  title: string;
  value: string;
  description: string;
}) {
  return (
    <div className="flex items-start justify-between border-b py-3 last:border-b-0">
      <div className="w-2/4">
        <p className="text-text-900 text-base font-semibold">{title}</p>
        <p className="text-text-800 text-sm">{description}</p>
      </div>
      <div>
        <p className="text-primary text-xl">{value}</p>
      </div>
    </div>
  );
}
