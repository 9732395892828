export default function Content() {
  return (
    <>
      <h1 className="text-2xl">
        Electronic Communications and Consent Agreement
      </h1>
      <p className="mb-3 mt-3 text-xs font-extralight">Version: 20221107</p>

      <div className="space-y-4">
        <h2 className="font-medium">Agreement</h2>
        <p>
          Please read this Electronic Records Communications and Consent
          Agreement (“Agreement”) carefully and download and save or print a
          copy for your records. In this Agreement, the words “we,” “us,” “our,”
          and “Prime” mean Prime Financial Technologies Inc. and its affiliates,
          agents, and service providers.
        </p>

        <h2 className="font-medium">
          Electronic Disclosures and Other Communications
        </h2>
        <p>
          We may be required by applicable law to give you certain important
          notices or disclosures in writing. Without your consent, we are not
          permitted to give you these disclosures electronically. To the extent
          permitted by law, you consent to use electronic signatures and to
          electronically receive all records, notices, statements, receipts,
          correspondence, and other items or communications in connection with
          your relationship with us, including, but not limited to, privacy
          notices, terms and conditions, credit agreements, payment
          authorizations, payment notifications, billing statements, or any
          other disclosures regarding your legal rights and obligations
          (collectively, “Communications”) including those that we may otherwise
          be required by law to send or provide you in writing or paper form
          (e.g., by mail). We may provide these Communications in the form of an
          update to our website (http://primeft.com); as an email, text, or
          communication on social media; as a notification on mobile, tablet, or
          wearable devices; or through other electronic means. We may also send
          notices to you by mail to any postal address that you have provided
          us. All notices by any of these methods will be deemed received by you
          no later than the earlier of when received or posted, or 24 hours
          after sent, except for notice by postal mail, which will be deemed
          received by you no later than the earlier of when received or 3
          business days after it is mailed.
        </p>

        <h2 className="font-medium">Requesting Paper Copy of Communications</h2>
        <p>
          At your request, we will provide a copy of your Communications and
          Agreement in paper-based media. To request a paper copy of your
          Communications or Agreement, contact us by email at
          support@primeft.com. We will not charge you any fee for providing a
          paper copy.
        </p>

        <h2 className="font-medium">Technology Requirements</h2>
        <p>
          To use our services and receive an electronic copy of the
          Communications, you must have the following:
        </p>
        <ul className="ml-8 list-disc">
          <li>
            a personal computer or other access device which is capable of
            accessing the Internet (e.g., you must have a modem, cable Internet
            connection, or some other means of access to the Internet, and you
            must have an active account with an Internet service provider), and
            which can receive HTML files;
          </li>
          <li>
            a valid email address and, if you use a spam filter that blocks our
            re-routes emails from unknown senders, you must permit messages from
            primeft.io in your spam filter.
          </li>
          <li>
            a current version of a program that accurately reads and displays
            PDF files (e.g., Adobe Acrobat Reader);
          </li>
          <li>
            an Internet web browser which is capable of supporting 128-bit SSL
            encrypted communications; and
          </li>
          <li>
            Sufficient storage space to either download and save (to your hard
            disk drive or other storage device) or print Communications.
          </li>
        </ul>
        <p>
          We may change these requirements. If we do, we will notify you
          promptly of any material changes.
        </p>

        <h2 className="font-medium">Updating Your Information</h2>
        <p>
          You must keep us informed of any change to your email address or other
          contact information. If you need to update your email address or other
          contact information, you may do so by updating your information in the
          App or by contacting us at support@primeft.com and requesting the
          necessary updates. We will not assume liability for non-receipt of
          Communications in the event your contact information on file is
          invalid; your email or Internet service provider filters the
          notification as &ldquo;spam&rdquo; or &ldquo;junk mail&rdquo;; there
          is a malfunction in your computer, mobile device, browser, Internet
          service, mobile connectivity and/or software; or for other reasons
          beyond our control.
        </p>

        <h2 className="font-medium">Withdrawing Consent</h2>
        <p>
          You are free to withdraw your consent to this Agreement at any time.
          However, once you withdraw your consent, we will no longer be able to
          send you electronic copies of Communications, and we may close or
          limit your access to our services. To withdraw your consent, please
          submit a request to withdraw your consent by email at
          support@primeft.com. Any withdrawal of your consent will be effective
          after a reasonable period of time for processing your request. The
          legal effectiveness, validity, and enforceability of Communications
          that were previously provided or signed electronically will not be
          affected.
        </p>

        <h2 className="font-medium">
          Acceptance of Agreement and Consent to Receive Electronic
          Communications
        </h2>
        <p>
          In order to use or access our services, you must accept this Agreement
          when prompted at the time you sign up for our service. By doing so,
          you agree:
        </p>

        <ul className="ml-8 list-disc">
          <li>To be bound by the terms of this Agreement;</li>
          <li>
            The Internet access device(s) you will use to receive Communications
            meet(s) the system requirements described above;
          </li>
          <li>
            To receive Communications electronically at any email address or
            mobile telephone number you have provided or made available to us;
          </li>
          <li>
            The Communications that we provide electronically have the same
            meaning and effect as if provided in paper form; and
          </li>
          <li>
            Your electronic acceptance or signature on any agreement or document
            has the same effect as if you signed it in ink.
          </li>
        </ul>
      </div>
    </>
  );
}
