import { ArrowRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { maskDate, maskPhone, maskSSN } from "@prime/ui/lib/masks";
import { AddressInput } from "@prime/ui/src/address-input";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import {
  Page,
  PageHeading,
  PageTitle,
  PageDescription,
} from "@prime/ui/src/page";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@prime/ui/src/select";

import { useIdentityContext } from "../contexts/identity";
import { convertPlaceAddressComponentsToAddress } from "../lib/address";
import { personalSchema, type PersonalInformation } from "../lib/schema";

export default function Personal() {
  const {
    googlePlacesApiKey,
    personalInformation,
    setPersonalInformation,
    setStep,
  } = useIdentityContext();

  const form = useForm<PersonalInformation>({
    resolver: zodResolver(personalSchema),
    defaultValues: personalInformation,
  });

  function onSubmit(values: PersonalInformation) {
    setPersonalInformation(values);
    setStep("business");
  }

  const onAddressSuggestionSelect = (
    address: google.maps.GeocoderAddressComponent[],
    suggestion: google.maps.places.AutocompletePrediction
  ) => {
    const convertedAddress = convertPlaceAddressComponentsToAddress(address);

    // Set the implicit google address values
    form.setValue(
      "_google_address_line_1",
      convertedAddress._google_address_line_1
    );
    form.setValue(
      "_google_address_line_2",
      convertedAddress._google_address_line_2
    );
    form.setValue(
      "_google_address_city",
      convertedAddress._google_address_city
    );
    form.setValue(
      "_google_address_state",
      convertedAddress._google_address_state
    );
    form.setValue(
      "_google_address_postal_code",
      convertedAddress._google_address_postal_code
    );
    form.setValue(
      "_google_address_country_code",
      convertedAddress._google_address_country_code
    );

    // Set the address search input value
    form.setValue("address_search", suggestion.description, {
      shouldValidate: true,
    });
  };

  return (
    <Page>
      <PageHeading>
        <PageTitle>Tell Us About Yourself</PageTitle>
        <PageDescription>
          We need this information to verify your identity.
        </PageDescription>
      </PageHeading>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-4 flex flex-col gap-4"
        >
          <div className="grid grid-cols-6 gap-4">
            {/* First name */}
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormControl>
                    <Input
                      placeholder="First Name"
                      autoComplete="given-name"
                      inputMode="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Last name */}
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormControl>
                    <Input
                      placeholder="Last Name"
                      autoComplete="family-name"
                      inputMode="text"
                      className="col-span-3"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Address */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="address_search"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <AddressInput
                        googlePlacesApiKey={googlePlacesApiKey}
                        placeholder="Home Address"
                        inputMode="text"
                        onSuggestionSelect={onAddressSuggestionSelect}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Email */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Personal Email"
                        type="email"
                        autoComplete="email"
                        inputMode="email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Phone number */}
          <div className="grid grid-cols-6 gap-4">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-6">
                    <FormControl>
                      <Input
                        placeholder="Phone"
                        autoComplete="tel"
                        inputMode="tel"
                        valueModifier={maskPhone}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          <div className="grid grid-cols-6 gap-4">
            {/* Business Role */}
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger placeholder="Business Role">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="ceo">CEO</SelectItem>
                      <SelectItem value="manager">Manager</SelectItem>
                      <SelectItem value="accountant">Accountant</SelectItem>
                      <SelectItem value="vice_president">
                        Vice President
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Business Type */}
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger placeholder="Employment Type">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="owner">Owner</SelectItem>
                      <SelectItem value="employee">Employee</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-6 gap-4">
            {/* DOB */}
            <FormField
              control={form.control}
              name="date_of_birth"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Input
                        placeholder="Date of Birth"
                        valueModifier={maskDate}
                        inputMode="numeric"
                        autoComplete="bday"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {/* SSN */}
            <FormField
              control={form.control}
              name="ssn"
              render={({ field }) => {
                return (
                  <FormItem className="col-span-4">
                    <FormControl>
                      <Input
                        placeholder="Social Security Number"
                        type="password"
                        valueModifier={maskSSN}
                        autoComplete="off"
                        inputMode="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          <Button className="mt-2 h-11 md:ml-auto">
            Continue
            <ArrowRight className="ml-2 h-4 w-4" aria-hidden="true" />
          </Button>
        </form>
      </Form>
    </Page>
  );
}
