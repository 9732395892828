import React, { useEffect, useState } from "react";

import { Loader } from "@googlemaps/js-api-loader";

import { Input } from "../input";

type InputAttributes = React.InputHTMLAttributes<HTMLInputElement>;

export type InputProps = InputAttributes &
  Required<Pick<InputAttributes, "placeholder">> & {
    googlePlacesApiKey?: string;
    onSuggestionSelect: (
      place: google.maps.GeocoderAddressComponent[],
      suggestion: google.maps.places.AutocompletePrediction
    ) => void;
  };

const AddressInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange: reactHookFormOnChange,
      googlePlacesApiKey = "",
      onSuggestionSelect,
      ...props
    },
    ref
  ) => {
    const [placesAutocompleteService, setPlacesAutocompleteService] = useState<
      google.maps.places.AutocompleteService | undefined
    >(undefined);
    const [placesService, setPlacesService] = useState<
      google.maps.places.PlacesService | undefined
    >(undefined);
    const [sessionToken, setSessionToken] = useState<
      google.maps.places.AutocompleteSessionToken | undefined
    >(undefined);
    const [suggestions, setSuggestions] = useState<
      google.maps.places.AutocompletePrediction[] | null
    >(null);

    const initializeService = () => {
      try {
        if (!window.google) throw new Error("Google script not loaded");
        if (!window.google.maps)
          throw new Error("Google maps script not loaded");
        if (!window.google.maps.places)
          throw new Error("Google maps places script not loaded");
      } catch (e) {
        console.error(e);
        return;
      }

      setPlacesAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      setPlacesService(
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        )
      );
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    };

    useEffect(() => {
      const init = async () => {
        try {
          if (
            !window.google ||
            !window.google.maps ||
            !window.google.maps.places
          ) {
            await new Loader({
              apiKey: googlePlacesApiKey,
              libraries: ["places"],
            }).load();
          }
          initializeService();
        } catch (error) {
          console.error(error);
        }
      };

      if (googlePlacesApiKey) {
        init();
      } else {
        initializeService();
      }
    }, [googlePlacesApiKey]);

    const loadSuggestions = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (value == "") {
        setSuggestions(null);
        return;
      }
      if (placesAutocompleteService && sessionToken) {
        placesAutocompleteService.getPlacePredictions(
          {
            input: value,
            sessionToken,
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setSuggestions(predictions);
            }
          }
        );
      }
    };

    // We need react-hook-form to perform its default onChange behavior
    // but we also want to load suggestions when the user types
    // so we are merging these functions into one unified onChange handler
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      reactHookFormOnChange?.(evt);
      loadSuggestions(evt);
    };

    return (
      <div>
        <Input onChange={onChange} ref={ref} {...props} />
        {suggestions && suggestions.length > 0 && (
          <div className="relative">
            <ul className="absolute top-0 z-10 block w-full text-gray-700 transition-all">
              {suggestions.map((suggestion) => {
                return (
                  <li
                    key={suggestion.place_id}
                    className="block w-full cursor-pointer border-x-2 border-b-2 border-slate-300 bg-slate-100 p-4 hover:bg-slate-900 hover:text-slate-100"
                    onClick={() => {
                      placesService?.getDetails(
                        { placeId: suggestion.place_id },
                        (place, status) => {
                          if (
                            status ===
                              window.google.maps.places.PlacesServiceStatus
                                .OK &&
                            place?.address_components != undefined
                          ) {
                            onSuggestionSelect(
                              place.address_components,
                              suggestion
                            );
                          }
                        }
                      );
                      setSuggestions(null);
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </li>
                );
              })}
              <li className="logo -mt-2 flex justify-end border-x-2 border-b-2 border-slate-300 bg-slate-100">
                <img
                  src="https://developers.google.com/static/maps/documentation/images/google_on_white.png"
                  alt="Powered by Google"
                />
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
);

AddressInput.displayName = "AddressInput";

export { AddressInput };
