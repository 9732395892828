import * as React from "react";
import {
  CircleCheckIcon,
  ClipboardCheckIcon,
  LandmarkIcon,
  PenSquareIcon,
  ShieldCheckIcon,
  SquareUserIcon,
  UsersIcon,
} from "lucide-react";

import { cn } from "../../lib/css";

const Portal = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "grid h-screen grid-cols-1 gap-3 md:grid-cols-[275px_1fr] md:p-3",
        className
      )}
      {...props}
    />
  );
});

Portal.displayName = "Portal";

const PortalAside = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <aside
      ref={ref}
      className={cn(
        "text-text-sidebar bg-surface-banner hidden h-full w-full grid-cols-1 grid-rows-1 overflow-hidden rounded-xl p-4 md:grid",
        className
      )}
      {...props}
    />
  );
});

PortalAside.displayName = "PortalAside";

const PortalAsideHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("grid-col grid gap-4", className)}
      {...props}
    />
  );
});

PortalAsideHeader.displayName = "PortalAsideHeader";

const PortalAsideNavigation = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("grid-col my-3 grid gap-1", className)}
      {...props}
    />
  );
});

PortalAsideNavigation.displayName = "PortalAsideNavigation";

const navigationSteps = [
  "verify",
  "identity",
  "ownership",
  "connect-bank",
  "accept-terms",
  "sign",
] as const;

type PortalAsideNavigationItemStep = (typeof navigationSteps)[number];

const navigationStates = [
  "complete",
  "active",
  "incomplete",
  "locked",
] as const;

type PortalAsideNavigationItemState = (typeof navigationStates)[number];

const PortalAsideNavigationItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    step: PortalAsideNavigationItemStep;
    state: PortalAsideNavigationItemState;
  }
>(({ className, step, state, ...props }, ref) => {
  function assertUnreachable(x: never): never {
    throw new Error(
      `You failed to account for the case: "${x}" in <PortalAsideNavigationItem /> component `
    );
  }

  const Icon = (
    props: React.HTMLAttributes<HTMLOrSVGElement> & {
      step: PortalAsideNavigationItemStep;
    }
  ): JSX.Element => {
    switch (step) {
      case "verify":
        return <ShieldCheckIcon {...props} />;
      case "identity":
        return <SquareUserIcon {...props} />;
      case "ownership":
        return <UsersIcon {...props} />;
      case "connect-bank":
        return <LandmarkIcon {...props} />;
      case "accept-terms":
        return <ClipboardCheckIcon {...props} />;
      case "sign":
        return <PenSquareIcon {...props} />;
      default:
        assertUnreachable(step);
    }
  };

  const title = (step: PortalAsideNavigationItemStep) => {
    switch (step) {
      case "verify":
        return "Create Account";
      case "identity":
        return "Identification";
      case "ownership":
        return "Ownership";
      case "connect-bank":
        return "Connect Bank";
      case "accept-terms":
        return "Accept Terms";
      case "sign":
        return "Sign Agreements";
      default:
        assertUnreachable(step);
    }
  };

  return (
    <div
      ref={ref}
      className={cn(
        "text-text-sidebar flex items-center justify-between rounded-lg px-3 py-2.5 text-sm font-normal",
        state === "active" && "bg-surface-card text-[hsl(var(--brand))]",
        className
      )}
      {...props}
    >
      <div className="flex gap-3">
        <Icon
          className={cn(
            "h-6 w-6",
            state === "complete" && "text-accent-repaid"
          )}
          step={step}
        />
        <span className={cn(state === "complete" && "text-accent-repaid")}>
          {title(step)}
        </span>
      </div>
      {state === "complete" && (
        <CircleCheckIcon className="text-accent-repaid ml-auto h-5 w-5" />
      )}
    </div>
  );
});

PortalAsideNavigationItem.displayName = "PortalAsideNavigationItem";

const PortalMain = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <main ref={ref} className={cn("flex-1", className)} {...props} />;
});

PortalMain.displayName = "PortalMain";

const PortalHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "text-text-label sticky top-0 z-50 flex items-center justify-center bg-white px-4 py-2 text-base shadow-sm md:py-4",
        className
      )}
      {...props}
    />
  );
});

PortalHeader.displayName = "PortalHeader";

const PortalContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn("", className)} {...props} />;
});

PortalContent.displayName = "PortalContent";

export {
  Portal,
  PortalAside,
  PortalAsideHeader,
  PortalAsideNavigation,
  PortalAsideNavigationItem,
  PortalContent,
  PortalHeader,
  PortalMain,
  // types
  navigationSteps,
  type PortalAsideNavigationItemState,
  type PortalAsideNavigationItemStep,
};
