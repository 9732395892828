export default function PopLogoSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 120 42"
      {...props}
    >
      <path
        fill="currentColor"
        d="M72.968 17.038c0 9.41-7.628 17.037-17.038 17.037s-17.037-7.628-17.037-17.037C38.893 7.628 46.52 0 55.93 0c9.41 0 17.038 7.628 17.038 17.038ZM34.138 17.038c0 9.41-7.475 16.562-20.075 16.562-6.3 0-14 8.4-14 8.4V17.038C.063 7.628 7.69 0 17.1 0c9.41 0 17.038 7.628 17.038 17.038ZM111.138 17.038c0 9.41-7.475 16.562-20.075 16.562-6.3 0-14 8.4-14 8.4V17.038C77.063 7.628 84.69 0 94.1 0c9.41 0 17.038 7.628 17.038 17.038ZM119.938 3.5c0 2.025-1.566 3.5-3.493 3.5-1.942 0-3.507-1.475-3.507-3.5s1.565-3.5 3.507-3.5c1.927 0 3.493 1.475 3.493 3.5Zm-.596 0c0-1.695-1.284-2.966-2.897-2.966-1.628 0-2.912 1.27-2.912 2.966 0 1.68 1.284 2.966 2.912 2.966 1.613 0 2.897-1.287 2.897-2.966Zm-1.143 1.71h-1.159l-.673-1.145h-.454v1.146h-1.049V1.679h1.738c.877 0 1.425.393 1.425 1.146 0 .55-.36.816-.705.973l.877 1.413Zm-1.268-2.29c0-.268-.204-.393-.486-.393h-.532v.785h.532c.282 0 .486-.126.486-.393Z"
      />
    </svg>
  );
}
