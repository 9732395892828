import React from "react";

import { cn } from "@prime/ui/lib/css";

const BeneficialOwnerCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("rounded-lg border p-3", className)}
      {...props}
    />
  );
});
BeneficialOwnerCard.displayName = "BeneficialOwnerCard";

const BeneficialOwnerTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "space-between flex items-center rounded-lg border p-3",
        className
      )}
      {...props}
    />
  );
});
BeneficialOwnerTitle.displayName = "BeneficialOwnerTitle";

const BeneficialOwnerContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn("", className)} {...props} />;
});
BeneficialOwnerContent.displayName = "BeneficialOwnerContent";

const BeneficialOwnerItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("space-between mt-2 flex px-3 py-1", className)}
      {...props}
    />
  );
});
BeneficialOwnerItem.displayName = "BeneficialOwnerItem";

const BeneficialOwnerLabel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn("flex-1 text-sm", className)} {...props} />;
});
BeneficialOwnerLabel.displayName = "BeneficialOwnerLabel";

const BeneficialOwnerValue = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn("text-sm", className)} {...props} />;
});
BeneficialOwnerValue.displayName = "BeneficialOwnerValue";

export {
  BeneficialOwnerCard,
  BeneficialOwnerTitle,
  BeneficialOwnerContent,
  BeneficialOwnerItem,
  BeneficialOwnerLabel,
  BeneficialOwnerValue,
};
