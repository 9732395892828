import * as React from "react";
import { Slot } from "@radix-ui/react-slot";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/css";

const buttonVariants = cva(
  "ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-action-primary text-action-primary-text hover:bg-action-primary/90 font-normal",
        destructive:
          "bg-action-negative hover:bg-action-negative/90 text-white",
        outline:
          "border-action-primary text-action-primary border font-semibold",
        icon: "text-action-primary font-semibold",
        secondary:
          "bg-btn-secondary text-btn-inverted hover:bg-btn-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        gradient:
          "text-action-primary-text font-normal" +
          " [background:linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]" +
          " hover:[background:linear-gradient(90.05deg,_#734BC9_0.5%,_#4B50C9_47.98%,_#1593B0)]",
        xero: "border-none bg-[#213B55] font-semibold text-white hover:bg-[#213B55ee]",
        "prime-themed":
          "bg-prime-secondary border-prime-secondary text-prime-primary hover:bg-prime-primary hover:text-prime-secondary border-2 border-solid font-semibold",
        "prime-themed-secondary":
          "bg-prime-primary text-prime-background hover:bg-prime-secondary hover:text-prime-primary border-none font-semibold",
      },
      size: {
        default: "h-12 rounded-xl px-4 py-2 text-base",
        xero: "rounded-[4px] px-9 py-3",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        "icon-sm": "h-6 w-6",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
