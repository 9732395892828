import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/offers/$id/claimed/")({
  component: Page,
});

function Page() {
  return (
    <div className="grid min-h-screen items-center justify-center">
      <div className="border-border-500 border p-8">
        <h1 className="text-xl font-medium">
          Sorry, this offer has already been claimed.
        </h1>
      </div>
    </div>
  );
}
