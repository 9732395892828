import { createFileRoute } from "@tanstack/react-router";
import { LoaderIcon } from "lucide-react";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/verification/denied/"
)({
  component: Page,
  pendingComponent: Loader,
});

function Page() {
  return (
    <>
      <div>
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Your application has been denied
        </h1>
        <p className="text-text-600 text-base lg:text-lg lg:leading-tight">
          We are sorry for any inconvenience. Please contact us for more.
        </p>
      </div>
    </>
  );
}

function Loader() {
  return (
    <div className="grid h-screen w-full items-center justify-center">
      <LoaderIcon className="text-primary h-12 w-12 animate-spin" />
    </div>
  );
}
