export default function XeroLoginLogoSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="g14">
        <path
          id="path20"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="white"
        />
        <path
          id="path22"
          d="M7.6293 16.015L10.3494 13.2882C10.4396 13.1963 10.4899 13.0746 10.4899 12.9455C10.4899 12.6766 10.2713 12.4581 10.0023 12.4581C9.87131 12.4581 9.74848 12.5095 9.65587 12.6032C9.65559 12.6038 6.93818 15.3196 6.93818 15.3196L4.20836 12.5992C4.11612 12.5082 3.99389 12.4581 3.86429 12.4581C3.59565 12.4581 3.37695 12.6764 3.37695 12.9451C3.37695 13.0762 3.42928 13.1992 3.5232 13.2919L6.24294 16.0112L3.52461 18.7347C3.42906 18.8287 3.37695 18.952 3.37695 19.0837C3.37695 19.3526 3.59565 19.5707 3.86429 19.5707C3.99411 19.5707 4.11644 19.5203 4.20836 19.4284L6.93395 16.7065L9.6492 19.4185C9.7455 19.518 9.86974 19.571 10.0023 19.571C10.271 19.571 10.4897 19.3526 10.4897 19.0837C10.4897 18.9539 10.4394 18.832 10.3479 18.7401L7.6293 16.015Z"
          fill="#1AB4D7"
        />
        <path
          id="path24"
          d="M23.8008 16.014C23.8008 16.5024 24.1979 16.8997 24.6868 16.8997C25.1747 16.8997 25.5719 16.5024 25.5719 16.014C25.5719 15.5256 25.1747 15.1283 24.6868 15.1283C24.1979 15.1283 23.8008 15.5256 23.8008 16.014Z"
          fill="#1AB4D7"
        />
        <path
          id="path26"
          d="M22.1203 16.0146C22.1203 14.6004 23.2707 13.4497 24.6851 13.4497C26.0988 13.4497 27.2496 14.6004 27.2496 16.0146C27.2496 17.4285 26.0988 18.5787 24.6851 18.5787C23.2707 18.5787 22.1203 17.4285 22.1203 16.0146ZM21.1113 16.0146C21.1113 17.9849 22.7145 19.5879 24.6851 19.5879C26.6557 19.5879 28.2597 17.9849 28.2597 16.0146C28.2597 14.0441 26.6557 12.4406 24.6851 12.4406C22.7145 12.4406 21.1113 14.0441 21.1113 16.0146Z"
          fill="#1AB4D7"
        />
        <path
          id="path28"
          d="M20.8581 12.5016L20.7081 12.5012C20.258 12.5012 19.8238 12.6432 19.4612 12.9226C19.4135 12.704 19.218 12.5394 18.9852 12.5394C18.7173 12.5394 18.5026 12.7541 18.502 13.0226C18.502 13.0236 18.5036 19.0418 18.5036 19.0418C18.5043 19.3098 18.7229 19.5276 18.991 19.5276C19.259 19.5276 19.4775 19.3098 19.4783 19.0412C19.4783 19.04 19.4785 15.3401 19.4785 15.3401C19.4785 14.1066 19.5913 13.6084 20.6479 13.4764C20.7456 13.4642 20.8522 13.4661 20.8522 13.4661C21.1414 13.4562 21.3468 13.2575 21.3468 12.989C21.3468 12.7203 21.1275 12.5016 20.8581 12.5016Z"
          fill="#1AB4D7"
        />
        <path
          id="path30"
          d="M11.499 15.4261C11.499 15.4127 11.5 15.3989 11.5007 15.3851C11.7836 14.2666 12.7966 13.4389 14.0031 13.4389C15.2241 13.4389 16.2464 14.2869 16.5149 15.4261H11.499ZM17.5129 15.3341C17.3029 14.3397 16.7585 13.5229 15.9295 12.9984C14.7178 12.2291 13.1177 12.2717 11.9472 13.104C10.9925 13.7832 10.4414 14.8942 10.4414 16.0387C10.4414 16.3257 10.476 16.6154 10.548 16.9005C10.9085 18.3186 12.1276 19.3922 13.5811 19.5701C14.0125 19.6223 14.4323 19.5973 14.867 19.4847C15.2406 19.3939 15.6021 19.2423 15.9351 19.0289C16.2808 18.8067 16.5696 18.5136 16.8493 18.1629C16.855 18.1564 16.8607 18.1507 16.8663 18.1439C17.0604 17.9032 17.0244 17.5608 16.8111 17.3973C16.6312 17.2594 16.3291 17.2035 16.0915 17.5079C16.0404 17.5807 15.9834 17.6556 15.9206 17.7305C15.7317 17.9393 15.4972 18.1416 15.2162 18.2985C14.8587 18.4895 14.4513 18.5987 14.0182 18.6011C12.6006 18.5853 11.842 17.5958 11.5722 16.8895C11.5251 16.7576 11.4887 16.6208 11.4634 16.4799C11.4601 16.4536 11.4577 16.4281 11.4567 16.4047C11.7498 16.4047 16.5438 16.4039 16.5438 16.4039C17.2412 16.3893 17.6166 15.897 17.5129 15.3341Z"
          fill="#1AB4D7"
        />
      </g>
    </svg>
  );
}
