import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { ArrowRightIcon, PencilIcon, BadgeCheckIcon } from "lucide-react";
import { ReactNode, useMemo, useState } from "react";

import { useAPI } from "@/contexts/api";
import Agreements from "@/routes/-components/agreements";
import { Footer, Header } from "@prime/pop-components/src/layout";
import { Guide, PromoTerms } from "@prime/pop-components/src/promotional";
import { Button } from "@prime/ui/src/button";
import { Slider } from "@prime/ui/src/slider";

import XeroSvg from "../-components/xero.svg";

export const Route = createFileRoute("/xero/welcome/")({
  component: Page,
  beforeLoad: async ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/$platformKey/auth",
        params: {
          platformKey: "xero",
        },
      });
    }
  },
  loader: async () => ({}),
});

function OptionToggle({
  options = [3, 6, 9],
  onChange = () => {},
  value = options[0],
}: {
  options?: number[];
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <div className="bg-txt-inverted-dimmed box-border flex h-12 shrink-0 flex-row items-start justify-start self-stretch overflow-hidden rounded-md p-[3px] text-center text-sm">
      {options.map((term: number) => {
        const isSelected: boolean = term === value;
        const className: string = isSelected
          ? "cursor-pointer bg-surface-banner text-txt-inverted border-border-dark flex flex-1 flex-row items-center justify-center self-stretch rounded-lg border-[1px] border-solid px-3.5 py-0 shadow-[0px_2px_5px_rgba(0,_0,_0,_0.05)]"
          : "cursor-pointer flex flex-1 flex-row items-center justify-center self-stretch px-3.5 py-0";
        return (
          <div
            key={`term-${term}`}
            className={className}
            onClick={() => onChange(term)}
          >
            <div className="relative leading-[150%]">
              <span>{`${term} `}</span>
              <span className="text-xs">MONTHS</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function StepContainer({
  children,
  currentIndex,
}: {
  children: ReactNode[];
  currentIndex: number;
}) {
  return (
    <div className="m-0 flex max-w-full flex-1 flex-col overflow-hidden bg-[url(/assets/campaign-bg.png)] bg-cover bg-[top] bg-no-repeat shadow-lg md:m-3 md:rounded-xl">
      <div
        style={{
          width: `${100 * children.length}%`,
        }}
      >
        <div
          className="flex w-full transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${(currentIndex * 100) / children.length}%)`,
          }}
        >
          {children.map((child: ReactNode, index: number) => {
            return (
              <div
                key={index}
                className="flex overflow-hidden"
                style={{
                  width: `${Math.round(100 / children.length)}%`,
                }}
              >
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Hero({ next = () => {} }: { next: () => void }) {
  const [step, setStep] = useState(0);
  const { storage } = useAPI();

  const {
    is_prequalified = true,
    offer_expiration = "2024-09-01T05:00:00.000Z",
    min_funding = 5000,
    max_funding = 50000,
    preferred_funding = 35000,
    terms = [3, 6, 9],
    preferred_term = 3,
    // business_name = "SMALL BUSINESS LLC",
  } = {}; // get from somewhere

  const [funding, setFunding] = useState(preferred_funding);
  const [length, setLength] = useState(preferred_term);

  const handleNext = () => {
    storage?.setItem?.("requested_loan_amount", funding.toString());
    storage?.setItem?.("requested_term_length", length.toString());
    next();
  };

  return (
    <div
      style={{
        transform: step ? "translateX(calc(6rem - 100vw))" : "translateX(0)",
        transitionTimingFunction: "cubic-bezier(0.7,-0.4,0.4,1.4)",
      }}
      className="flex min-h-[480px] min-w-[640px] flex-row items-center justify-start rounded-xl text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)] transition-transform duration-500 md:w-full md:max-w-[840px]"
    >
      <div className="box-border flex min-w-[calc(100vw-6rem)] max-w-[calc(100vw-6rem)] flex-1 flex-col items-start justify-start gap-[45px] self-stretch rounded-l-xl bg-[hsl(var(--gray-100)/0.3)] bg-white bg-opacity-50 p-6 [backdrop-filter:blur(32px)] md:min-w-[320px]">
        <div className="text-29xl text-mediumslateblue flex flex-1 flex-col items-start justify-start gap-[24px] self-stretch text-left">
          <div className="relative flex flex-1 flex-col items-start justify-between self-stretch">
            {/* <p className="text-btn font-medium uppercase">{business_name}</p> */}
            <p className="text-txt-primary my-3 text-[42px] leading-[125%]">
              You’re pre-qualified for up to{" "}
              <span
                onClick={() => setFunding(max_funding)}
                className="cursor-pointer rounded-md px-3 [background:linear-gradient(rgba(255,_255,_255,_0.75),_rgba(255,_255,_255,_0.75)),_linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]"
              >
                <span className="!bg-clip-text text-transparent [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] [background:linear-gradient(90.05deg,_#8959ef_0.5%,_#595fef_47.98%,_#1ab4d7)]">
                  ${Number(max_funding).toLocaleString("en-US")}
                </span>
              </span>{" "}
              in funding!
            </p>

            <div className="text-txt-primary relative z-[3] mb-6 self-stretch text-base leading-[150%]">
              Apply for a loan instantly using only your Xero account. Skip the
              paperwork and reviews–just connect your account to see approved
              offers.
            </div>
            <Button
              variant={"gradient"}
              style={{
                opacity: step ? 0 : 1,
              }}
              className="transition-opcity flex h-12 w-full rounded-xl text-base duration-500 ease-linear md:hidden"
              onClick={() => setStep(1)}
            >
              <div className="relative font-medium leading-[24px]">
                View your offer
              </div>
              <ArrowRightIcon size={24} />
            </Button>
            <div className="hidden flex-1 flex-col md:flex">
              <div className="mt-auto flex flex-row justify-start self-start overflow-hidden rounded-md border-[1px] border-solid border-[hsl(var(--brand))] p-2 text-center text-xs uppercase text-[hsl(var(--brand))]">
                OFFER EXPIRES{" "}
                {new Date(offer_expiration)
                  .toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  .toUpperCase()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-surface-card box-border flex min-w-[calc(100vw-6rem)] max-w-[calc(100vw-6rem)] flex-1 flex-col items-start justify-center self-stretch overflow-hidden rounded-r-xl border-l-[1px] border-solid bg-white text-left text-sm md:min-w-[312px]">
        <div className="border-gainsboro flex w-full flex-col items-center justify-start self-stretch overflow-hidden rounded-b-none rounded-t-xl border-b-[1px] border-solid px-6 py-4">
          <div className="text-icon flex flex-row items-center justify-center self-stretch font-medium">
            {is_prequalified ? (
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <BadgeCheckIcon size={21} />
                <p>PREQUALIFIED LOAN OFFER</p>
              </div>
            ) : (
              <p>CUSTOMIZE YOUR PLAN</p>
            )}
          </div>
        </div>
        <div className="text-txt-secondary flex flex-1 flex-col items-start justify-between self-stretch overflow-hidden rounded-b-xl rounded-t-none p-6 text-base">
          <div className="flex flex-col items-start justify-start gap-[24px] self-stretch">
            <div className="flex flex-col items-start justify-start gap-[12px] self-stretch">
              <div className="flex flex-col items-start justify-center gap-[12px] self-stretch rounded-lg">
                <div className="relative leading-[150%]">
                  How much funding do you need?
                </div>
                <div className="bg-txt-inverted border-border-dark box-border flex h-[65px] flex-row items-center justify-between self-stretch rounded-md border-[1px] border-solid px-3 py-0 text-[24px] text-black shadow-[0px_2px_5px_rgba(0,_0,_0,_0.05)]">
                  <div className="flex h-10 flex-row items-center justify-start gap-[3px]">
                    <div className="relative opacity-[0.5]">$</div>
                    <div className="relative text-[36px]">
                      {funding.toLocaleString("en-US")}
                    </div>
                  </div>
                  <div className="bg-surface-inset flex h-10 w-10 flex-row items-center justify-center rounded-md opacity-[0.7]">
                    <PencilIcon size={16} className="text-btn" />
                  </div>
                </div>
              </div>
              <div className="text-txt-dimmed flex flex-col items-start justify-start gap-3 self-stretch text-center text-xs">
                <Slider
                  max={max_funding}
                  step={500}
                  min={min_funding}
                  value={[funding]}
                  onValueChange={([value]) => setFunding(value)}
                />
                <div className="flex flex-row items-start justify-between self-stretch text-sm">
                  <div
                    className="relative cursor-pointer leading-[150%]"
                    onClick={() => setFunding(min_funding)}
                  >
                    ${min_funding.toLocaleString("en-US")}
                  </div>
                  <div
                    className="relative cursor-pointer leading-[150%]"
                    onClick={() => setFunding(max_funding)}
                  >
                    ${max_funding.toLocaleString("en-US")}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-lightgray relative box-border h-px self-stretch border-t-[1px] border-solid" />
            <div className="flex flex-col items-start justify-center gap-[12px] self-stretch">
              <div className="relative self-stretch leading-[150%]">
                How long do you want to repay?
              </div>
              <OptionToggle
                onChange={setLength}
                value={length}
                options={terms}
              />
            </div>
          </div>
          <Button
            className={`${step ? "opacity-100" : "opacity-0 md:opacity-100"} h-12 w-full rounded-xl text-base transition-opacity duration-1000 ease-linear`}
            variant={"gradient"}
            onClick={handleNext}
          >
            {is_prequalified ? "Apply Now" : "Apply with Xero"}
            <ArrowRightIcon size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
}

function Page() {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const progress = useMemo(() => (((step + 1) / 3) * 100).toFixed(0), [step]);

  const getXeroUserPermission = () => {
    navigate({ to: "/xero/permission-request" });
  };

  return (
    <div className="flex flex-1 flex-col items-center">
      <div className="flex w-full max-w-[1440px] flex-1 flex-col">
        <Header className="border-0" PartnerLogo={XeroSvg}>
          <div className="bg-surface-dark mx-6 hidden h-2 max-w-[360px] flex-1 shrink-0 flex-col items-start justify-start overflow-hidden rounded-full md:flex">
            <div
              className={`bg-surface-banner h-2 transition-all duration-500 ease-in-out`}
              style={{ width: `${progress}%` }}
            />
          </div>
        </Header>
        <StepContainer currentIndex={step}>
          <div className="flex flex-1 items-center justify-center p-6 text-center sm:p-12">
            <Hero next={() => setStep(1)} />
          </div>
          {/** Terms */}

          <div className="flex w-full flex-1 items-center justify-center p-6 text-center sm:p-12">
            <div className="flex w-full max-w-[512px] flex-col items-center gap-6 rounded-xl bg-white bg-opacity-80 p-6 [backdrop-filter:blur(32px)]">
              <div className="text-start">
                <p className="text-txt-primary text-2xl font-semibold leading-[125%]">
                  Before we get started
                </p>
                <div className="text-txt-tertiary z-[3] self-stretch text-base leading-[150%]">
                  Please review and agree to our policies and disclosures that
                  dictate how we’ll be working with you.
                </div>
              </div>

              <div className="w-full rounded-xl p-1 shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)] sm:p-3">
                <Agreements />
              </div>
              <Button
                className="h-12 w-full rounded-xl text-base"
                variant={"gradient"}
                onClick={getXeroUserPermission}
              >
                <div className="relative font-medium leading-[24px]">
                  Continue to Xero
                </div>
                <ArrowRightIcon size={24} />
              </Button>
            </div>
          </div>
        </StepContainer>
        <div className="flex flex-1 flex-col items-center justify-center p-0 md:p-12">
          <div className="w-full p-0 md:pb-12">
            <PromoTerms />
          </div>
          <Guide />
        </div>
        <Footer />
      </div>
    </div>
  );
}
