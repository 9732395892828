import { createFileRoute } from "@tanstack/react-router";

import { useAPI } from "@/contexts/api";
import { Button } from "@prime/ui/src/button";

export const Route = createFileRoute("/logout/")({
  component: Page,
});

function Page() {
  const api = useAPI();
  const { user, logout, isAuthenticated } = api.auth;

  if (!isAuthenticated)
    return (
      <div>
        <p>you are not authenticated. no need to logout</p>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </div>
    );

  return (
    <div>
      <pre>{JSON.stringify(user, null, 2)}</pre>
      <Button onClick={() => logout({ returnTo: window.location.origin })}>
        Logout
      </Button>
    </div>
  );
}
