import * as Sentry from "@sentry/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Loader2, TriangleAlertIcon, Users2Icon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import {
  createBusinessPerson,
  getBusinessPersons,
  updateBusinessPerson,
  verifyApplication,
} from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";
import { BusinessPerson } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import { Checkbox } from "@prime/ui/src/checkbox";
import { Dialog, DialogContent, DialogTrigger } from "@prime/ui/src/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";

import {
  BeneficialOwnerCard,
  BeneficialOwnerContent,
  BeneficialOwnerItem,
  BeneficialOwnerLabel,
  BeneficialOwnerTitle,
  BeneficialOwnerValue,
} from "./-components/beneficial-owner-card";
import {
  AddBeneficialOwnerTrigger,
  UpdateBeneficialOwnerTrigger,
  type BeneficialOwner,
} from "./-components/form-trigger";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/ownership/"
)({
  loader: async ({ context }) => {
    const { apiClient, application } = context;

    const businessPersons = await getBusinessPersons({
      apiClient,
      params: { borrower_id: application.borrower_id! },
    });
    return { businessPersons };
  },
  pendingComponent: LoadingScreen,
  errorComponent: ErrorPage,
  component: _Page,
});

function _Page() {
  const { application, apiClient, toast } = Route.useRouteContext();
  const { businessPersons } = Route.useLoaderData();
  const navigate = useNavigate();

  // We make a distinction between business persons by first
  // identifying the applicant and we treat the rest as beneficial owners.
  const applicantOwner = businessPersons.find(
    (person) => person.is_applicant
  ) as BusinessPerson;

  const [applicant, setApplicant] = useState<BusinessPerson>(applicantOwner);
  const [beneficialOwners, setBeneficialOwners] = useState<BeneficialOwner[]>(
    []
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const formSchema = z
    .object({
      certify: z.boolean(),
    })
    .refine((data) => data.certify, {
      message:
        "Certify that the information provided is complete, true, and correct.",
      path: ["certify"],
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      certify: false,
    },
  });

  const onSubmit = async () => {
    try {
      setIsProcessing(true);

      // Update applicant's ownership percentage
      await updateBusinessPerson({
        apiClient,
        params: { id: applicant.id },
        payload: {
          ownership_percentage: applicant.ownership_percentage,
        },
      });

      // Create business persons for any beneficial owners that were added
      if (beneficialOwners.length) {
        for (const owner of beneficialOwners) {
          await createBusinessPerson({
            apiClient,
            payload: {
              ...owner,
              // explicitly setting `is_signer` to false for
              // beneficial owners despite capturing it in the UI
              is_signer: false,
              ownership_percentage: String(owner.ownership_percentage),
              business_id: application.borrower_id!,
            },
          });
        }
      }

      await verifyApplication({
        apiClient,
        params: { id: application.id },
      });

      // If above successful, navigate to the connect bank step
      navigate({
        to: "/applications/$applicationId/verification/review",
        params: { applicationId: application.id },
        replace: true,
      });
    } catch (error) {
      Sentry.captureException(error);
      setIsProcessing(false);

      toast({
        title: "There was an error adding ownership details.",
        description: "Please contact support for assistance.",
        variant: "destructive",
      });
    }
  };

  // Add/Update/Delete beneficial owners handlers
  const handleAddBeneficialOwner = (values: BeneficialOwner) => {
    setBeneficialOwners((prev) => [...prev, values]);
  };

  const handleUpdateBeneficialOwner =
    (index: number) => (values: BeneficialOwner) => {
      setBeneficialOwners((prev) =>
        prev.map((owner, idx) => {
          if (idx === index) {
            return values;
          }
          return owner;
        })
      );
    };

  const handleDeleteBeneficialOwner = (index: number) => {
    setBeneficialOwners((prev) =>
      prev.filter((owner, idx) => {
        return idx !== index;
      })
    );
  };

  const totalOwnershipPercentage = beneficialOwners.reduce(
    (acc, owner) => {
      return acc + Number(owner.ownership_percentage);
    },
    Number(applicant.ownership_percentage) as number
  );

  const isTotalOwnershipAbove100 = totalOwnershipPercentage > 100;

  return (
    <Page>
      <PageHeading>
        <PageTitle>Business Owners</PageTitle>
        <PageDescription>
          Please list all individuals and entities with at least a 25% or
          greater ownership stake in{" "}
          <strong>{application.applicant_business_name}</strong>.
        </PageDescription>
      </PageHeading>

      <BeneficialOwnerCard>
        <BeneficialOwnerTitle>
          <div className="flex flex-1 items-center gap-2">
            <Users2Icon className="text-action-primary h-6 w-6" />
            <p className="font-medium">
              {applicant.first_name} {applicant.last_name}
            </p>
          </div>
          <EditApplicantTrigger
            applicant={applicant}
            handleEditApplicant={setApplicant}
          />
        </BeneficialOwnerTitle>
        <BeneficialOwnerContent>
          <BeneficialOwnerItem>
            <BeneficialOwnerLabel>Ownership Percentage</BeneficialOwnerLabel>
            <BeneficialOwnerValue>
              {applicant.ownership_percentage}%
            </BeneficialOwnerValue>
          </BeneficialOwnerItem>
        </BeneficialOwnerContent>
      </BeneficialOwnerCard>

      {beneficialOwners.map((owner, index) => (
        <BeneficialOwnerCard key={index}>
          <BeneficialOwnerTitle>
            <div className="flex flex-1 items-center gap-2">
              <Users2Icon className="text-action-primary h-6 w-6" />
              <p className="font-medium">
                {owner.first_name} {owner.last_name}
              </p>
            </div>
            <UpdateBeneficialOwnerTrigger
              beneficialOwner={owner}
              handleUpdateBeneficialOwner={handleUpdateBeneficialOwner(index)}
              handleDeleteBeneficialOwner={() =>
                handleDeleteBeneficialOwner(index)
              }
            />
          </BeneficialOwnerTitle>
          <BeneficialOwnerContent>
            <BeneficialOwnerItem>
              <BeneficialOwnerLabel>Ownership Percentage</BeneficialOwnerLabel>
              <BeneficialOwnerValue>
                {owner.ownership_percentage}%
              </BeneficialOwnerValue>
            </BeneficialOwnerItem>
            <BeneficialOwnerItem>
              <BeneficialOwnerLabel>Home Address</BeneficialOwnerLabel>
              <BeneficialOwnerValue>
                {owner.address_search}
              </BeneficialOwnerValue>
            </BeneficialOwnerItem>
            <BeneficialOwnerItem>
              <BeneficialOwnerLabel>Date of Birth</BeneficialOwnerLabel>
              <BeneficialOwnerValue>{owner.date_of_birth}</BeneficialOwnerValue>
            </BeneficialOwnerItem>
            <BeneficialOwnerItem>
              <BeneficialOwnerLabel>
                Social Security Number
              </BeneficialOwnerLabel>
              <BeneficialOwnerValue>{owner.ssn}</BeneficialOwnerValue>
            </BeneficialOwnerItem>
          </BeneficialOwnerContent>
        </BeneficialOwnerCard>
      ))}

      {!isTotalOwnershipAbove100 && (
        <div className="bg-background-option flex items-center justify-between rounded-lg p-4">
          <div>
            <p>Add Business Owner</p>
            <p className="text-text-readonly-secondary text-xs">
              You only need to add individuals who own{" "}
              <strong>25% or more</strong> in the business.
            </p>
          </div>
          <AddBeneficialOwnerTrigger
            handleAddBeneficialOwner={handleAddBeneficialOwner}
          />
        </div>
      )}

      {isTotalOwnershipAbove100 && (
        <div className="bg-background-option-uncertain flex items-start gap-3 rounded-lg p-4">
          <TriangleAlertIcon className="text-text-icon-uncertain h-9 w-9" />
          <div>
            <p className="text-sm font-normal">Ownership greater than 100%</p>
            <p className="text-text-readonly-secondary text-xs">
              The individual ownership percentages cannot add up to more than
              100%. Please edit the owners to continue.
            </p>
          </div>
        </div>
      )}

      <Form {...form}>
        <form
          className="flex flex-col gap-6"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="bg-background-option flex rounded-xl p-4">
            <FormField
              control={form.control}
              name="certify"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-label text-sm font-light">
                      As the individual opening an account or providing
                      information on behalf of a business, I hereby certify to
                      the best of my knowledge that the information provided is
                      complete and correct. The authorized individual/entity
                      agrees to notify the financial institution of any change
                      in such information.
                    </FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button
            type="submit"
            disabled={isProcessing || isTotalOwnershipAbove100}
            className="mt-6"
          >
            Done
            {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
          </Button>
        </form>
      </Form>
    </Page>
  );
}

function EditApplicantTrigger({
  applicant,
  handleEditApplicant,
}: {
  applicant: BusinessPerson;
  handleEditApplicant: React.Dispatch<React.SetStateAction<BusinessPerson>>;
}) {
  const formSchema = z.object({
    ownership_percentage: z.coerce
      .number()
      .gte(25)
      .lte(100, "Ownership stake must be at least 25%"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ownership_percentage: Number(applicant.ownership_percentage),
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const updatedApplicant: BusinessPerson = {
      ...applicant,
      ownership_percentage: String(values.ownership_percentage),
    };
    handleEditApplicant(updatedApplicant);
    setOpen(false);
    // close dialog
  };

  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant={"outline"}>Edit</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <p className="text-text-readonly text-2xl">Your Ownership</p>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="ownership_percentage"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Ownership Percentage"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button>Save Changes</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

function ErrorPage() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}
