import { createFileRoute } from "@tanstack/react-router";

import { PrivacyPolicy } from "@prime/pop-components/src/docs";

export const Route = createFileRoute("/_docs/docs/privacy-policy")({
  component: Page,
});

function Page() {
  return (
    <div className="m-auto mb-20 mt-20 flex max-w-screen-2xl flex-col justify-center">
      <PrivacyPolicy />
    </div>
  );
}
