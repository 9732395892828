import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";

import APIProvider, { useAPI } from "@/contexts/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { Toaster } from "@prime/ui/src/toast/toaster";
import { useToast } from "@prime/ui/src/toast/use-toast";

import "./index.css";
import { routeTree } from "./routeTree.gen";

const router = createRouter({
  routeTree,
  defaultPendingComponent: LoaderScreen,
  trailingSlash: "preserve", // CAUTION: Don't ever remove this line
  // Since the context is not initialized yet, we need to provide a default value (undefined!)
  // However, we initialize the app with these properties present,
  // so we can be confident that they will always be defined.
  // Therefore, the associated types defined in __root.tsx will be correct.
  context: {
    apiClient: undefined!,
    auth: undefined!,
    applyBackendClient: undefined!,
    toast: undefined!,
    featureFlags: null,
  },
  defaultPreload: "intent",
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Initialize Sentry and specify environment
// https://vitejs.dev/guide/env-and-mode.html#modes

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.primeft\.*/],

    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_RATE,
  });
}

function App() {
  const api = useAPI();
  const { auth, lendingService, applyBackend } = api;
  const { toast } = useToast();

  return (
    <React.StrictMode>
      <RouterProvider
        router={router}
        defaultPreload="intent"
        // trailingSlash="preserve"
        context={{
          apiClient: lendingService,
          auth,
          toast,
          applyBackendClient: applyBackend,
          featureFlags: null,
        }}
      />
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <APIProvider>
      <App />
    </APIProvider>
    <Toaster />
  </>
);
