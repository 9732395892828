export default function XeroSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 60 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="XeroLogo">
        <path
          id="path22"
          d="M10.5253 9.89204L16.9006 3.501C17.1119 3.28557 17.2299 3.00037 17.2299 2.69789C17.2299 2.0675 16.7174 1.55557 16.087 1.55557C15.78 1.55557 15.4921 1.67593 15.275 1.89568C15.2744 1.89695 8.90547 8.26219 8.90547 8.26219L2.50744 1.88614C2.29125 1.67301 2.00479 1.55557 1.70103 1.55557C1.07141 1.55557 0.558838 2.06725 0.558838 2.697C0.558838 3.00419 0.681482 3.29244 0.901609 3.50964L7.276 9.88314L0.904914 16.2662C0.680974 16.4867 0.558838 16.7756 0.558838 17.0842C0.558838 17.7144 1.07141 18.2257 1.70103 18.2257C2.00529 18.2257 2.29202 18.1076 2.50744 17.8921L8.89556 11.5126L15.2594 17.8688C15.4851 18.1022 15.7763 18.2265 16.087 18.2265C16.7168 18.2265 17.2292 17.7144 17.2292 17.0842C17.2292 16.78 17.1114 16.4943 16.897 16.2789L10.5253 9.89204Z"
          fill="#1AB4D7"
        />
        <path
          id="path24"
          d="M48.4253 9.89012C48.4253 11.0347 49.356 11.9658 50.502 11.9658C51.6453 11.9658 52.5764 11.0347 52.5764 9.89012C52.5764 8.74526 51.6453 7.8143 50.502 7.8143C49.356 7.8143 48.4253 8.74526 48.4253 9.89012Z"
          fill="#1AB4D7"
        />
        <path
          id="path26"
          d="M44.4885 9.89114C44.4885 6.57654 47.1849 3.8796 50.4999 3.8796C53.8132 3.8796 56.5103 6.57654 56.5103 9.89114C56.5103 13.205 53.8132 15.9008 50.4999 15.9008C47.1849 15.9008 44.4885 13.205 44.4885 9.89114ZM42.1238 9.89114C42.1238 14.509 45.8813 18.266 50.4999 18.266C55.1184 18.266 58.8778 14.509 58.8778 9.89114C58.8778 5.27268 55.1184 1.51464 50.4999 1.51464C45.8813 1.51464 42.1238 5.27268 42.1238 9.89114Z"
          fill="#1AB4D7"
        />
        <path
          id="path28"
          d="M41.5292 1.65789L41.1778 1.65687C40.1228 1.65687 39.1052 1.9896 38.2554 2.64452C38.1434 2.13208 37.6854 1.74647 37.1397 1.74647C36.5118 1.74647 36.0086 2.24964 36.0071 2.87888C36.0071 2.88117 36.0109 16.9863 36.0109 16.9863C36.0125 17.6144 36.5249 18.1249 37.1532 18.1249C37.7814 18.1249 38.2936 17.6144 38.2953 16.9849C38.2953 16.9821 38.2958 8.31062 38.2958 8.31062C38.2958 5.41949 38.5601 4.25188 41.0367 3.9424C41.2656 3.91393 41.5156 3.91838 41.5156 3.91838C42.1932 3.89512 42.6748 3.42945 42.6748 2.80008C42.6748 2.17033 42.1607 1.65789 41.5292 1.65789Z"
          fill="#1AB4D7"
        />
        <path
          id="path30"
          d="M19.5961 8.51218C19.5961 8.48091 19.5986 8.44838 19.6001 8.41622C20.2633 5.79466 22.6375 3.8547 25.4651 3.8547C28.3269 3.8547 30.7228 5.84232 31.3521 8.51218H19.5961ZM33.6912 8.2965C33.199 5.96598 31.9231 4.05169 29.9802 2.82231C27.1402 1.01923 23.3899 1.119 20.6467 3.06977C18.4091 4.66162 17.1174 7.26564 17.1174 9.94808C17.1174 10.6207 17.1985 11.2996 17.3673 11.9679C18.2122 15.2916 21.0695 17.8077 24.4762 18.2247C25.4871 18.3471 26.471 18.2885 27.4899 18.0246C28.3655 17.8116 29.2127 17.4565 29.9933 16.9563C30.8035 16.4355 31.4804 15.7486 32.136 14.9265C32.1492 14.9114 32.1625 14.898 32.1758 14.8821C32.6308 14.3178 32.5464 13.5154 32.0464 13.1323C31.6247 12.8091 30.9168 12.6781 30.3597 13.3914C30.24 13.562 30.1064 13.7375 29.9594 13.913C29.5165 14.4025 28.9669 14.8767 28.3084 15.2445C27.4705 15.6921 26.5156 15.9479 25.5005 15.9536C22.1781 15.9167 20.4001 13.5975 19.7677 11.9421C19.6573 11.633 19.5721 11.3123 19.5128 10.9821C19.505 10.9204 19.4993 10.8607 19.497 10.8058C20.184 10.8058 31.4199 10.8039 31.4199 10.8039C33.0545 10.7696 33.9342 9.61586 33.6912 8.2965Z"
          fill="#1AB4D7"
        />
      </g>
    </svg>
  );
}
