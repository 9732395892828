import * as Sentry from "@sentry/react";
import {
  createFileRoute,
  useNavigate,
  useRouter,
  useRouterState,
} from "@tanstack/react-router";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  FeatherIcon,
  Loader2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import {
  executeLoan,
  getBusinessPersons,
  markAgreementsCompleted,
  reviewAgreement,
  signAgreement,
} from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";
import { AgreementReview, BusinessPerson } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@prime/ui/src/form";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/agreements/"
)({
  loader: async ({ context, params }) => {
    const { apiClient, application } = context;
    const borrower_id = application.borrower_id;

    const offerSummaryAgreement = await reviewAgreement({
      apiClient,
      params: { id: params.applicationId },
      payload: { agreement_type: "offer_summary" },
    });

    const itemizationAgreement = await reviewAgreement({
      apiClient,
      params: { id: params.applicationId },
      payload: { agreement_type: "itemization" },
    });

    const financingAgreement = await reviewAgreement({
      apiClient,
      params: { id: params.applicationId },
      payload: { agreement_type: "loan_agreement" },
    });

    if (!borrower_id) {
      throw new Error("No borrower_id found in application");
    }

    const businessPersons = await getBusinessPersons({
      apiClient,
      params: { borrower_id },
    });

    return {
      offerSummaryAgreement,
      itemizationAgreement,
      financingAgreement,
      businessPersons,
    };
  },

  pendingComponent: LoadingScreen,
  errorComponent: ErrorPage,
  component: Page,
});

const onLoadIframe: React.ReactEventHandler<HTMLIFrameElement> = (event) => {
  const target = event.target as HTMLIFrameElement;
  const body = target?.contentWindow?.document?.body as HTMLElement | undefined;
  if (body) {
    body.style.padding = "8px";
    body.style.maxWidth = "unset";
    // 8.5in is the width of a standard letter page
    // - 1in each side for the margin
    body.style.width = "6.5in";
    body.style.overflow = "auto";
    body.style.margin = "auto";
    target.style.height = `${body.scrollHeight}px`;
  }
};

const Document = ({ content }: { content: string }) => {
  function openNewTab() {
    const newWindow = window.open();
    if (!newWindow) return;
    newWindow.document.write(content);
    const body = newWindow.document?.body as HTMLElement | undefined;
    if (body) {
      // 8.5in is the width of a standard letter page
      // 1in each side for the margin
      body.style.width = "8.5in";
      body.style.padding = "1in";
      body.style.margin = "auto";
    }
    newWindow.document.close();
  }

  return (
    <div className="border-border-500 relative w-full rounded-xl border">
      <button
        onClick={openNewTab}
        className="absolute right-2 top-2 rounded-lg bg-gray-200 p-2 text-white focus:outline-none focus:ring-1 focus:ring-gray-300"
        aria-label="Open in new tab"
      >
        <ExternalLinkIcon size={20} />
      </button>

      <div className="w-full overflow-hidden">
        <iframe
          className="min-h-[300px] w-full rounded-xl"
          srcDoc={content}
          onLoad={onLoadIframe}
        />
      </div>
    </div>
  );
};

const ScrollToEndButton = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const targetElement = document.querySelector("#sign");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  const scrollToBottom = () => {
    const targetElement = document.querySelector("#sign");
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return isVisible ? (
    <div className="fixed bottom-4 left-4 md:hidden">
      <button
        onClick={scrollToBottom}
        className="flex h-12 w-12 items-center justify-center rounded-full bg-[hsl(var(--brand))] text-white shadow-lg hover:bg-[hsl(var(--brand)/.8)]"
      >
        <ChevronDownIcon size={24} />
      </button>
    </div>
  ) : null;
};

function Page() {
  const navigate = useNavigate();
  const { apiClient, application } = Route.useRouteContext();
  const {
    offerSummaryAgreement,
    itemizationAgreement,
    financingAgreement,
    businessPersons,
  } = Route.useLoaderData();

  const isXero = application?.partner_short_id === "xero";

  const [isProcessing, setIsProcessing] = useState(false);

  const [isViewingOfferSummaryAgreement, setIsViewingOfferSummaryAgreement] =
    useState(false);

  const [isViewingItemizationAgreement, setIsViewingItemizationAgreement] =
    useState(false);

  const [isViewingFinancingAgreement, setIsViewingFinancingAgreement] =
    useState(false);

  if (isViewingOfferSummaryAgreement) {
    return (
      <OfferSummaryAgreementView
        content={offerSummaryAgreement.agreement.agreement_content_html!}
        setViewing={setIsViewingOfferSummaryAgreement}
      />
    );
  }

  if (isViewingItemizationAgreement) {
    return (
      <ItemizationAgreementView
        content={itemizationAgreement.agreement.agreement_content_html!}
        setViewing={setIsViewingItemizationAgreement}
      />
    );
  }

  const signer =
    businessPersons.find((x) => x.is_signer === true) || businessPersons[0];

  if (isViewingFinancingAgreement) {
    return (
      <FinancingAgreementView
        content={financingAgreement.agreement.agreement_content_html!}
        businessPerson={signer}
        setViewing={setIsViewingFinancingAgreement}
      />
    );
  }

  const nextStep = async () => {
    await markAgreementsCompleted({
      apiClient,
      params: { id: application.id },
    });

    navigate({
      to: "/applications/$applicationId/xero-writeback",
      params: { applicationId: application.id },
      replace: true,
    });
  };

  const handleExecuteLoan = async () => {
    setIsProcessing(true);
    try {
      await markAgreementsCompleted({
        apiClient,
        params: { id: application.id },
      });

      await executeLoan({
        apiClient,
        params: { id: application.id },
      });

      // If above successful, navigate to the loan dashboard
      navigate({
        to: "/loans/$loanId",
        params: { loanId: application.loan_id! },
        replace: true,
      });
    } catch (error) {
      setIsProcessing(false);
      Sentry.captureException(error);
    }
  };

  // Before the user can execute the loan, they must sign all agreements.
  // This is determined by the presence of the `created_at` and `agreement_id` properties
  const canExecuteLoan =
    offerSummaryAgreement.agreement.created_at &&
    offerSummaryAgreement.agreement.agreement_id &&
    itemizationAgreement.agreement.created_at &&
    itemizationAgreement.agreement.agreement_id &&
    financingAgreement.agreement.created_at &&
    financingAgreement.agreement.agreement_id;

  return (
    <div className="flex max-w-[480px] flex-1 flex-col gap-6">
      <div>
        <h1 className="text-text-900 mb-2 text-xl leading-[100%] lg:text-2xl">
          Sign Agreements
        </h1>
        <p className="text-text-600 text-sm leading-[1.1rem] lg:text-base lg:leading-tight">
          We connect to you bank to help verify your business revenue, and to
          retrieve the account information necessary to send and collect funds.
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <AgreementsSection
          title="Offer Summary"
          description="A standardized summary of your loan terms."
          agreement={offerSummaryAgreement}
          handleClickSign={() => setIsViewingOfferSummaryAgreement(true)}
        />
        <AgreementsSection
          title="Itemization"
          description="Details of how your loan funds will be distributed"
          agreement={itemizationAgreement}
          handleClickSign={() => setIsViewingItemizationAgreement(true)}
        />
        <AgreementsSection
          title="Financing Agreement"
          description="The binding legal agreement of your loan."
          agreement={financingAgreement}
          handleClickSign={() => setIsViewingFinancingAgreement(true)}
        />
      </div>

      {isXero ? (
        <Button disabled={isProcessing || !canExecuteLoan} onClick={nextStep}>
          Last Step
          <ArrowRightIcon className="ml-2 h-4 w-4" />
        </Button>
      ) : (
        <Button
          disabled={isProcessing || !canExecuteLoan}
          onClick={handleExecuteLoan}
        >
          Finalize Loan
          {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
        </Button>
      )}
    </div>
  );
}

function AgreementsSection({
  title,
  description,
  agreement,
  handleClickSign,
}: {
  title: string;
  description: string;
  agreement: AgreementReview;
  handleClickSign: () => void;
}) {
  const state = useRouterState();

  // Only certain properties are present when the agreement has been signed
  const isSigned =
    Boolean(agreement.agreement.created_at) &&
    Boolean(agreement.agreement.agreement_id);

  const pending =
    state.status !== "idle" || state.isLoading || state.isTransitioning;

  const Action = () => {
    if (isSigned) return <CheckIcon className="text-icon-highlight h-6 w-6" />;
    return (
      <Button
        className="font-normal"
        size={"sm"}
        disabled={pending}
        onClick={handleClickSign}
      >
        Sign
      </Button>
    );
  };

  return (
    <div className="border-border-500 flex items-center gap-[1rem] rounded-xl border p-4">
      <div>
        <p className="text-readonly font-medium">{title}</p>
        <p className="text-readonly-secondary font-light">{description}</p>
      </div>
      <div className="ml-auto">
        <Action />
      </div>
    </div>
  );
}

function OfferSummaryAgreementView({
  content,
  setViewing,
}: {
  content: string;
  setViewing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const { apiClient, application } = Route.useRouteContext();
  const [isProcessing, setIsProcessing] = useState(false);

  const acceptTerms = async () => {
    setIsProcessing(true);

    try {
      await signAgreement({
        apiClient,
        params: { id: application.id },
        payload: { agreement_type: "offer_summary" },
      });
    } catch (err) {
      console.error(err);
      // handle error
    } finally {
      router.invalidate();
      setViewing(false);
    }
  };

  return (
    <div className="m-auto flex w-full max-w-2xl flex-col gap-6">
      <div className="flex items-center gap-2">
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Offer Summary Agreement
        </h1>
      </div>
      <Document content={content} />
      <Button disabled={isProcessing} onClick={acceptTerms}>
        Accept
        {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </div>
  );
}

function ItemizationAgreementView({
  content,
  setViewing,
}: {
  content: string;
  setViewing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const { apiClient, application } = Route.useRouteContext();
  const [isProcessing, setIsProcessing] = useState(false);

  const acceptTerms = async () => {
    setIsProcessing(true);

    try {
      await signAgreement({
        apiClient,
        params: { id: application.id },
        payload: { agreement_type: "itemization" },
      });
    } catch (err) {
      console.error(err);
      // handle error
    } finally {
      router.invalidate();
      setViewing(false);
    }
  };
  return (
    <div className="m-auto flex w-full max-w-2xl flex-col gap-6">
      <div className="flex items-center gap-2">
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Itemization Agreement
        </h1>
      </div>
      <Document content={content} />
      <div className="bg-background-option text-readonly-secondary rounded-lg p-3 text-xs font-light">
        Applicable law requires this information to be provided to you to help
        you make an informed decision. By clicking the “Accept” button below,
        you are confirming that you received this information.
      </div>
      <Button disabled={isProcessing} onClick={acceptTerms}>
        Accept
        {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </div>
  );
}

function FinancingAgreementView({
  content,
  businessPerson,
  setViewing,
}: {
  content: string;
  businessPerson: BusinessPerson;
  setViewing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const router = useRouter();
  const { apiClient, application } = Route.useRouteContext();
  const [isProcessing, setIsProcessing] = useState(false);

  const businessPersonName = `${businessPerson.first_name} ${businessPerson.last_name}`;

  const formSchema = z
    .object({
      signature: z.string(),
    })
    .refine(
      (data) =>
        data.signature.toLowerCase() === businessPersonName.toLowerCase(),
      {
        message: `Signature must match our recorded name: ${businessPersonName}`,
        path: ["signature"],
      }
    );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      signature: "",
    },
  });

  const onSubmit = async () => {
    setIsProcessing(true);

    try {
      await signAgreement({
        apiClient,
        params: { id: application.id },
        payload: { agreement_type: "loan_agreement" },
      });
    } catch (err) {
      console.error(err);
      // handle error here
    } finally {
      router.invalidate();
      setViewing(false);
    }
  };

  return (
    <div className="m-auto flex w-full max-w-2xl flex-col gap-6">
      <div className="mb-6">
        <h1 className="text-text-900 mb-2 text-2xl lg:text-3xl">
          Financing Agreement
        </h1>
      </div>
      <Document content={content} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="my-6 flex flex-col items-center rounded-xl bg-[#F8F9FF] p-12">
            <FeatherIcon className="text-action-primary mb-6 h-20 w-20" />
            <div className="mb-6 text-center">
              <h3
                id="sign"
                className="text-text-readonly mb-1.5 text-2xl font-medium"
              >
                Sign Agreement
              </h3>
              <p className="text-text-readonly-secondary text-lg">
                To finalize your loan please type in your full legal name below.
              </p>
            </div>

            <FormField
              control={form.control}
              name="signature"
              render={({ field }) => (
                <FormItem className="flex flex-col space-y-0">
                  <FormControl>
                    <input
                      type="text"
                      className="mb-4 h-[100px] w-full rounded-lg p-4 text-4xl"
                      placeholder="Enter Full Name"
                      {...field}
                    />
                  </FormControl>
                  <FormLabel className="text-text-readonly-secondary text-center text-base font-light">
                    Signature must match our recorded name:{" "}
                    <span className="font-medium">{businessPersonName}</span>
                  </FormLabel>
                </FormItem>
              )}
            />
          </div>
          <Button disabled={isProcessing} type="submit" className="w-full">
            Confirm Signature
            {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
          </Button>
        </form>
      </Form>
      <ScrollToEndButton />
    </div>
  );
}

function ErrorPage() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}
