import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useCallback, useState } from "react";

import { getApplications } from "@/api";
import LoaderScreen from "@/routes/-components/loader-screen";
import { getRouteByApplicationStage } from "@/utils";
import { Header } from "@prime/pop-components/src/layout";
import { Button } from "@prime/ui/src/button";
import { Input } from "@prime/ui/src/input";
import { AxiosError } from "axios";

export const Route = createFileRoute("/invitations/code/")({
  beforeLoad: async (props) => {
    const { context, cause } = props;
    const { auth, apiClient } = context;
    const { isAuthenticated } = auth;

    if (cause !== "enter") return;

    if (isAuthenticated) {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }
  },
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  const [code, setCode] = useState("");
  const handleContinue = useCallback(() => {
    window.location.href = `https://a.pop.loans/${code}`;
  }, [code]);

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value);
  }
  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-start justify-center sm:mt-[120px]">
        <div className="flex w-full max-w-[480px] flex-col gap-6 p-6">
          <div className="gap-1">
            <p className="text-txt-primary text-2xl font-normal">
              Claim your offer
            </p>
            <p className="text-txt-tertiary text-sm">
              Enter the Offer Code on the invitation your received.
            </p>
          </div>

          <Input
            value={code}
            type="text"
            placeholder={"https://a.pop.loans/"}
            onInput={handleInput}
            className="w-full"
            containerClass="w-full"
          />
          <Button
            className="h-12 w-full rounded-xl px-5 py-3 text-sm font-medium"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
