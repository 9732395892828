export default function Content() {
  return (
    <>
      <h1 className="text-2xl">Credit Pull Consent</h1>
      <p className="mb-3 mt-3 text-xs font-extralight">Version: 20221107</p>

      <div className="space-y-4">
        <p>
          By clicking “Submit,” I authorize and instruct Prime Financial
          Technologies, Inc., its financial institution partners, and its
          service providers to obtain business and consumer reports about me
          from one or more business or consumer reporting agencies or other
          third parties, and to use the information contained in any such report
          to verify my identity; determine my eligibility for a loan or any
          other product or service; service, review, maintain, or collect on any
          loan provided or serviced by Prime Financial Technologies, Inc., its
          successors, or its financial institution partners; provide marketing,
          pre-qualification, or other information about products or services to
          me; and for any other purpose permitted by applicable law.
        </p>
      </div>
    </>
  );
}
