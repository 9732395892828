import * as Sentry from "@sentry/react";
import { Link, createFileRoute } from "@tanstack/react-router";
import {
  BadgeCheckIcon,
  BanknoteIcon,
  CalendarIcon,
  LandmarkIcon,
  LoaderCircle,
} from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import * as z from "zod";
import { getBankAccount, getServicingLoan, postManualPayment } from "@/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { maskCurrency } from "@prime/ui/lib/masks";
import { Button } from "@prime/ui/src/button";
import { Dialog, DialogOverlay, DialogPortal } from "@prime/ui/src/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@prime/ui/src/form";
import { Input } from "@prime/ui/src/input";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const Route = createFileRoute("/loans/$loanId/_loansLayout/payment/")({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;
    const { loanId } = params;

    const loan = await getServicingLoan({
      apiClient,
      params: { loanId },
    });

    const borrowerId = loan.borrower_id;

    const bankAccount = await getBankAccount({
      apiClient,
      params: { borrowerId },
    });

    return {
      bankAccount,
      loan,
    };
  },
  component: Page,
});

function Page() {
  const [manualPaymentAmount, setManualPaymentAmount] = useState<string | null>(
    null
  );

  return (
    <div className="my-14 flex justify-center">
      {manualPaymentAmount ? (
        <Card>
          <PaymentConfirmation paymentAmount={manualPaymentAmount!} />
        </Card>
      ) : (
        <Card>
          <PaymentForm setManualPaymentAmount={setManualPaymentAmount} />
        </Card>
      )}
    </div>
  );
}

function PaymentForm({
  setManualPaymentAmount,
}: {
  setManualPaymentAmount: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const { loanId } = Route.useParams();
  const { apiClient } = Route.useRouteContext();
  const { bankAccount, loan } = Route.useLoaderData();
  const [processing, setProcessing] = useState(false);

  const {
    name: bankAccountName,
    ach_account_number: bankAccountNumber,
    mask,
  } = bankAccount?.[0] || {};

  const bankAccountNumberMask = mask ?? bankAccountNumber?.slice(-4) ?? "XXXX";

  function convertDollarStringToNumber(input: string) {
    return parseFloat(input.replace(/[^0-9.]/g, ""));
  }

  const formSchema = z.object({
    payment_amount: z
      .string()
      .min(1, { message: "Please specify an amount" })
      .refine(
        (value) => {
          const dollarAmount = convertDollarStringToNumber(value);

          if (
            dollarAmount < 10 ||
            dollarAmount > parseFloat(loan.remaining_amount)
          ) {
            return false;
          } else {
            return true;
          }
        },
        {
          message:
            "Amount must be greater than $10.00 and not exceed the current balance",
        }
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      payment_amount: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setProcessing(true);
      await postManualPayment({
        apiClient,
        params: { loanId },
        payload: {
          amount: convertDollarStringToNumber(values.payment_amount),
        },
      });
      setManualPaymentAmount(values.payment_amount);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setProcessing(false);
    }
  }

  return (
    <>
      <Dialog open={processing}>
        <DialogPortal>
          <DialogOverlay>
            <div className="flex h-screen w-screen items-center justify-center">
              <LoaderCircle className="text-btn h-12 w-12 animate-spin" />
            </div>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>

      <h1 className="text-txt-primary text-2xl font-semibold">
        Make a Payment
      </h1>
      <div>
        <div className="border-border-light flex items-center justify-between border-b px-1.5 py-6">
          <p className="text-txt-secondary text-sm font-medium">
            Current Loan Balance
          </p>
          <p className="text-txt-secondary text-right text-sm font-normal">
            {USDollar.format(parseFloat(loan.remaining_amount))}
          </p>
        </div>
        <div className="border-border-light flex items-center justify-between border-b px-1.5 py-6">
          <p className="text-txt-secondary text-sm font-medium">Paying From</p>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <p className="text-txt-secondary text-base font-normal">
                {bankAccountName}
              </p>
              <p className="text-txt-tertiary text-xs font-normal">
                Account ending in {bankAccountNumberMask}
              </p>
            </div>
            <div className="border-border-light flex h-12 w-12 items-center justify-center rounded-xl border">
              <LandmarkIcon className="h-8 w-8" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-1.5 py-6">
          <p className="text-txt-secondary text-sm font-medium">Payment Date</p>
          <div className="text-txt-secondary flex items-center gap-2">
            <CalendarIcon className="h-4 w-4" />
            <p className="text-txt-secondary text-right text-sm font-normal">
              Today
            </p>
          </div>
        </div>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-6"
        >
          <FormField
            control={form.control}
            name="payment_amount"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Payment Amount"
                    valueModifier={maskCurrency}
                    {...field}
                  />
                </FormControl>
                <FormDescription className="text-txt-dimmed pl-2 text-xs">
                  This one-time payment will reduce the balance due at the end
                  of your loan. Your next scheduled auto payment will not
                  change.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-3 pt-6">
            <Button type="submit">
              <BanknoteIcon className="mr-2 h-5 w-5" />
              <span>Make Payment</span>
            </Button>
            <Button variant="secondary" asChild>
              <Link to="/loans/$loanId" params={{ loanId }}>
                Cancel
              </Link>
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}

function PaymentConfirmation({ paymentAmount }: { paymentAmount: string }) {
  const { loanId } = Route.useParams();
  const { bankAccount } = Route.useLoaderData();

  const {
    name: bankAccountName,
    ach_account_number: bankAccountNumber,
    mask,
  } = bankAccount?.[0] || {};

  const bankAccountNumberMask = mask ?? bankAccountNumber?.slice(-4) ?? "XXXX";

  return (
    <div>
      <div className="mb-12 flex flex-col items-center justify-center">
        <BadgeCheckIcon className="text-accent-success mb-3 h-10 w-10" />
        <h1 className="text-txt-primary mb-1 text-2xl font-semibold">
          You&rsquo;ve Scheduled a Payment
        </h1>
      </div>
      <div className="border-border-light flex items-center justify-between border-b px-1.5 py-6">
        <p className="text-txt-secondary text-sm font-medium">Payment Amount</p>
        <p className="text-txt-secondary text-right text-base font-normal">
          {paymentAmount}
        </p>
      </div>
      <div className="border-border-light flex items-center justify-between border-b px-1.5 py-6">
        <p className="text-txt-secondary text-sm font-medium">Payment Date</p>
        <div className="text-txt-secondary flex items-center gap-2">
          <CalendarIcon className="h-4 w-4" />
          <p className="text-txt-secondary text-right text-base font-normal">
            Today
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between px-1.5 py-6">
        <p className="text-txt-secondary text-sm font-medium">Paying From</p>
        <div className="flex items-center gap-4">
          <div className="text-right">
            <p className="text-txt-secondary text-base font-normal">
              {bankAccountName}
            </p>
            <p className="text-txt-tertiary text-xs font-normal">
              Account ending in {bankAccountNumberMask}
            </p>
          </div>
          <div className="border-border-light flex h-12 w-12 items-center justify-center rounded-xl border">
            <LandmarkIcon className="h-8 w-8" />
          </div>
        </div>
      </div>
      <div className="mb-12 p-3">
        <p className="text-txt-dimmed text-center text-xs">
          Payments placed before 11:59 EST will be made on the same day
        </p>
      </div>
      <Button className="w-full" asChild>
        <Link to="/loans/$loanId" params={{ loanId }}>
          Return to Dashboard
        </Link>
      </Button>
    </div>
  );
}

function Card({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-surface-card w-[480px] rounded-2xl p-6 shadow-xl">
      {children}
    </div>
  );
}
