import { Eye, EyeOff, Loader2, User } from "lucide-react";
import React from "react";
import { useState } from "react";

import { Button } from "@prime/ui/src/button";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";

import { useIdentityContext } from "../contexts/identity";
import { formatGoogleAddress } from "../lib/address";

const VerificationSummary = () => {
  const { onSubmit, personalInformation, businessInformation, isSubmitting } =
    useIdentityContext();

  const handleSubmit = async () => {
    await onSubmit({
      personalInformation,
      businessInformation,
    });
  };

  return (
    <Page>
      <PageHeading>
        <PageTitle>Verify and Submit</PageTitle>
        <PageDescription>
          Please carefully review the information below and correct any errors
          before submitting.
        </PageDescription>
      </PageHeading>
      <div className="flex flex-col gap-4">
        <BusinessVerificationDetailsTable />
        <PersonalVerificationDetailsTable />
      </div>

      <p className="text-text-500 text-xs">
        By clicking &ldquo;Submit,&rdquo; I authorize and instruct Prime
        Financial Technologies, Inc., its financial institution partners, and
        its service providers to obtain business and consumer reports about me
        from one or more business or consumer reporting agencies or other third
        parties, and to use the information contained in any such report to
        [verify my identity; determine my eligibility for a loan or any other
        product or service; service, review, maintain, or collect on any loan
        provided or serviced by Prime Financial Technologies, Inc., its
        successors, or its financial institution partners; provide marketing,
        pre-qualification, or other information about products or services to
        me; and for any other purpose permitted by applicable law.
      </p>

      <Button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className="mt-2 h-11 w-full"
      >
        Submit
        {isSubmitting && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
      </Button>
    </Page>
  );
};

function PersonalVerificationDetailsTable() {
  const { personalInformation, setStep } = useIdentityContext();

  const [showSsn, setShowSsn] = useState<boolean>(false);
  return (
    <div className="rounded-lg border p-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <User className="text-primary-500 h-6 w-6" />
          <h2 className="text-primeft-700 text-sm font-bold">Personal Info</h2>
        </div>
        <Button variant="outline" size="sm" onClick={() => setStep("personal")}>
          Revise
        </Button>
      </div>
      <table className="w-full border-separate border-spacing-y-2">
        <tbody>
          <TableRow row="Name">
            {personalInformation.first_name} {personalInformation.last_name}
          </TableRow>
          <TableRow row="Address">
            {formatGoogleAddress(personalInformation)}
          </TableRow>
          <TableRow row="Email">{personalInformation.email}</TableRow>
          <TableRow row="Phone Number">{personalInformation.phone}</TableRow>
          <TableRow row="DOB">{personalInformation.date_of_birth}</TableRow>
          <TableRow row="SSN">
            <span className="flex items-center justify-end">
              <span className="mr-2">
                {showSsn
                  ? personalInformation.ssn
                  : `***-**-**${personalInformation.ssn.slice(-2)}`}
              </span>
              <button onClick={() => setShowSsn((prev) => !prev)}>
                {showSsn ? (
                  <Eye className="h-4 w-4" />
                ) : (
                  <EyeOff className="h-4 w-4" />
                )}
              </button>
            </span>
          </TableRow>
        </tbody>
      </table>
    </div>
  );
}

function BusinessVerificationDetailsTable() {
  const { businessInformation, setStep } = useIdentityContext();

  return (
    <div className="rounded-lg border p-4">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <User className="text-primary-500 h-6 w-6" />
          <h2 className="text-primeft-700 text-sm font-bold">Business Info</h2>
        </div>
        <Button variant="outline" size="sm" onClick={() => setStep("business")}>
          Revise
        </Button>
      </div>
      <table className="w-full border-separate border-spacing-y-2">
        <tbody>
          <TableRow row="Business Address">
            {formatGoogleAddress(businessInformation)}
          </TableRow>

          <TableRow row="Legal Business Name">
            {businessInformation.business_name}
          </TableRow>

          <TableRow row="Business Phone Number">
            {businessInformation.phone}
          </TableRow>

          <TableRow row="EIN">{businessInformation.ein}</TableRow>

          <TableRow row="Ownership Percentage">
            {String(businessInformation.ownership_percentage)}%
          </TableRow>
        </tbody>
      </table>
    </div>
  );
}

function TableRow({
  row,
  children,
}: {
  row: string;
  children: React.ReactNode;
}) {
  return (
    <tr className="">
      <th scope="row" className="text-primeft-900 text-left font-medium">
        {row}
      </th>
      <td className="text-primeft-900 text-right font-normal">{children}</td>
    </tr>
  );
}

export default VerificationSummary;
