import * as Sentry from "@sentry/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

import {
  createBorrowerBusiness,
  createBusinessPerson,
  updateApplication,
} from "@/api";
import IdentityStep from "@prime/screens/src/identity/page";

type IdentityStepOnSubmit = React.ComponentProps<
  typeof IdentityStep
>["onSubmit"];

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/identity/"
)({
  component: Page,
});

function Page() {
  const { application, apiClient, toast, auth } = Route.useRouteContext();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit: IdentityStepOnSubmit = async ({
    personalInformation,
    businessInformation,
  }) => {
    let borrowerBusinessId = null;

    try {
      setSubmitting(true);

      ({ id: borrowerBusinessId } = await createBorrowerBusiness({
        apiClient,
        payload: businessInformation,
      }));

      await createBusinessPerson({
        apiClient,
        payload: {
          ...personalInformation,
          business_id: borrowerBusinessId,
          is_applicant: true,
          is_signer: true,
          ownership_percentage: businessInformation.ownership_percentage,
        },
      });

      await updateApplication({
        apiClient,
        params: { id: application.id },
        payload: {
          business_id: borrowerBusinessId,
          stage: "identification_completed",
        },
      });

      // If above successful, navigate to the ownership step
      navigate({
        to: "/applications/$applicationId/ownership",
        params: { applicationId: application.id },
        replace: true,
      });
    } catch (error) {
      setSubmitting(false);
      Sentry.captureException(error);
      toast({
        title: "There was an error processing your application.",
        description: "Please contact support for assistance.",
        variant: "destructive",
      });
    }
  };

  return (
    <IdentityStep
      application={application}
      googlePlacesApiKey={import.meta.env.VITE_APPLY_GOOGLE_PLACES_API_KEY}
      onSubmit={onSubmit}
      isSubmitting={submitting}
      userEmail={auth?.user?.email}
    />
  );
}
