export default function Content() {
  return (
    <>
      <h1 className="text-2xl">Pop Website Terms of Service</h1>
      <p className="mb-3 mt-3 text-xs font-extralight">
        Effective as of August 5, 2024
      </p>

      <div className="space-y-4">
        <p>
          These Pop Terms of Service (the “Terms of Service”) are a legal
          agreement between Prime Financial Technologies, Inc. (“Prime”, “we” or
          “us”) and you, the individual or entity (“you”) who is accessing the
          Pop Website/User Portal (the “Pop Site”). By using the Pop Site, you
          agree to be bound by these terms and conditions. If you are accessing
          or using the Pop Site on behalf of your company, you represent that
          you are authorized to accept this Agreement on behalf of your company,
          and all references to “you” also reference your company.
        </p>

        <h2 className="font-medium">General</h2>
        <p>
          The Pop Site is an online interface maintained by Prime that enables
          you to view and manage information provided by us. In addition, these
          terms supplement any other agreements you may have in place with
          Prime. In the event of a conflict between these Terms of Service and
          your written agreement with Prime, the terms of the written agreement
          will control. Prime reserves the right to modify or discontinue,
          temporarily or permanently, the Pop Site (or any part thereof) without
          notice. You agree that Prime will not be liable to you or to any third
          party for any modification, suspension or discontinuance of the Pop
          Site.
        </p>

        <h2 className="font-medium">Account Registration</h2>
        <p>
          You may be required to register with an account with Prime in order to
          access and use certain features of the Pop Site. If you choose to
          register for the Pop Site, you agree to provide and maintain true,
          accurate, current, and complete information about yourself as prompted
          by the registration form. Registration data and certain other
          information about you are governed by our Privacy Policy.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          password and account details, if any, and are fully responsible for
          any and all activities that occur under your password or account. You
          agree to (a) immediately notify Prime of any unauthorized use of your
          password or account or any other breach of security, and (b) ensure
          that you exit from your account at the end of each session when
          accessing the Pop Site. Prime will not be liable for any loss or
          damage arising from your failure to comply with this paragraph.
        </p>

        <h2 className="font-medium">Restricted Activities</h2>
        <p>
          You must use the Pop Site in a lawful manner, and must obey all laws,
          rules, and regulations (“Laws”) that apply to your use of the Pop
          Site. You must not, and must not allow others to: (i) access or
          attempt to access non-public Prime systems, programs, data, or
          services; (ii) work around any of the technical limitations of the Pop
          Site or enable disabled or prohibited functionality; (iii) reverse
          engineer or attempt to reverse engineer the Pop Site except as
          expressly permitted by Laws; (iv) perform or attempt to perform any
          actions that would interfere with the normal operation of the Pop Site
          or affect our other users' use of the Pop Site; or (v) impose an
          unreasonable or disproportionately large load on the Pop Site.
        </p>

        <h2 className="font-medium">Third Party Services</h2>
        <p>
          The Pop Site may provide links or other access to services, sites,
          technology, and resources that are provided or otherwise made
          available by third parties (the “Third-Party Services”). Third-Party
          Services may also be subject to additional terms and conditions,
          privacy policies, or other agreements with such third party, and you
          may be required to authenticate to or create separate accounts to use
          Third-Party Services on the websites or via the technology platforms
          of their respective providers. Some Third-Party Services will provide
          us with access to certain information that you have provided to third
          parties, including through such Third-Party Services, and we will use,
          store and disclose such information in accordance with our Privacy
          Policy.
        </p>

        <h2 className="font-medium">Representations and Warranties</h2>
        <p>
          You represent and warrant to Prime that (i) you have full power and
          authority to enter into these Terms of Service and are 18 years of age
          or older; (ii) your activities in connection with the Pop Site do not
          and will not violate, infringe, or misappropriate any third party's
          copyright, trademark, right of privacy or publicity, or other personal
          or proprietary right, and (iii) you will not use the Pop Site in a
          manner that is defamatory, obscene, unlawful, threatening, abusive,
          tortious, offensive or harassing.
        </p>

        <h2 className="font-medium">Disclaimer</h2>
        <p>
          The Pop Site may be temporarily unavailable for scheduled maintenance
          or for unscheduled emergency maintenance, either by Prime or by
          third-party providers, or because of other causes beyond our
          reasonable control, but Prime may use reasonable efforts to provide
          advance notice in writing or by email of any scheduled service
          disruption. HOWEVER, THE POP SITE, AND ALL SERVER AND NETWORK
          COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
          ANY WARRANTIES OF ANY KIND, AND PRIME EXPRESSLY DISCLAIMS ANY AND ALL
          WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT PRIME
          DOES NOT WARRANT THAT THE POP SITE WILL BE UNINTERRUPTED, TIMELY,
          SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO
          THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE POP SITE, AND NO
          INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM PRIME THROUGH THE
          POP SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS
          OF SERVICE.
        </p>

        <h2 className="font-medium">Limitation of Liability</h2>
        <p>
          UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT,
          TORT, OR OTHERWISE) SHALL PRIME BE LIABLE TO YOU OR ANY THIRD PARTY
          FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR
          PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST
          DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN
          EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE SIX (6) MONTHS
          PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO FEES APPLY,
          ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION
          ALLOCATE THE RISKS UNDER THESE TERMS OF SERVICE BETWEEN THE PARTIES,
          AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING
          WHETHER TO ENTER INTO THESE TERMS OF SERVICE.
        </p>
        <p>
          Some states do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which
          means that some of the above limitations may not apply to you. IN
          THESE STATES, PRIME'S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT
          PERMITTED BY LAW.
        </p>

        <h2 className="font-medium">Indemnification</h2>
        <p>
          You shall defend, indemnify, and hold harmless Prime from and against
          any claims, actions or demands, including without limitation
          reasonable legal and accounting fees, arising or resulting from your
          breach of these Terms of Service, or your other access, contribution
          to, use or misuse of the Pop Site. Prime shall provide notice to you
          of any such claim, suit or demand. Prime reserves the right to assume
          the exclusive defense and control of any matter which is subject to
          indemnification under this section. In such case, you agree to
          cooperate with any reasonable requests assisting Prime's defense of
          such matter.
        </p>

        <h2 className="font-medium">Term and Termination</h2>
        <p>
          These Terms of Service apply to you starting on the date you first
          access or use the Pop Site and continue until Prime terminates these
          Terms or Service, or until you stop using the Pop Site. If after
          stopping, you start to use the Pop Site again, you are consenting to
          these Terms of Service. We may terminate these Terms of Service at any
          time for any reason by providing you notice. We may suspend your
          access to the Pop Site, or immediately terminate these Terms of
          Service, if (i) you use the Pop Site in a prohibited manner or
          otherwise do not comply with any of the provisions of these Terms of
          Service; or (ii) any Law requires us to do so, in which case you will
          no longer be able to access the Pop Site. Upon termination of your use
          of the Pop Site or termination of these Terms of Service for any
          reason, in addition to this section, the following sections will
          survive termination: Sections 5 through 13.
        </p>

        <h2 className="font-medium">Governing Law</h2>
        <p>
          These Terms of Service will be governed by the laws of New York,
          except for New York's choice of law rules, and by applicable federal
          United States laws. Each party agrees to submit to personal and
          exclusive jurisdiction of the courts located in New York, New York,
          and each party waives all objections to that jurisdiction and venue.
        </p>

        <h2 className="font-medium">Assignment</h2>
        <p>
          You may not assign these Terms of Service or any of your rights or
          obligations, by operation of law or otherwise, without our prior
          written approval and any attempted assignment will be void. We reserve
          the right to freely assign these Terms of Service and our rights and
          obligations to any third party without notice or consent. Subject to
          the foregoing, these Terms of Service will be binding upon and inure
          to the benefit of you and us, their successors and permitted assigns.
        </p>

        <h2 className="font-medium">Miscellaneous</h2>
        <p>
          Prime's failure to exercise or enforce any right or provision of the
          Terms of Service will not be considered a waiver of that right or
          provision. If any provision of these Terms of Service is found by any
          court of competent jurisdiction to be unenforceable or invalid, that
          provision will be limited or eliminated to the minimum extent
          necessary so that these Terms of Service otherwise remain in full
          force and effect and remain enforceable between the parties. Headings
          are for reference purposes only and in no way define, limit, construe
          or describe the scope or extent of the section. These Terms of Service
          constitutes the entire agreement between you and Prime with respect to
          your use of the Pop Site. These Terms of Service are not intended and
          will not be construed to create any rights or remedies in any parties
          other than you and Prime, and no other person will have the ability to
          assert any rights as a third party beneficiary under these Terms of
          Service. These Terms of Service do not limit any rights that Prime may
          have under trade secret, copyright, patent or other laws.
        </p>

        <h2 className="font-medium">Contact Us</h2>
        <p>
          Please contact us at support@pop.loans to pose any questions regarding
          these Terms of Service or the Pop Site.
        </p>
        <p className="text-xs font-extralight">
          &copy; 2024 Prime Financial Technologies, Inc.
        </p>
      </div>
    </>
  );
}
