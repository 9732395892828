import { LucideIcon } from "lucide-react";
import { createContext, useContext } from "react";

import { PortalAsideNavigationItemState } from "@prime/ui/src/portal";

type Step = {
  IconComponent: LucideIcon;
  title: string;
  state: PortalAsideNavigationItemState;
};

type ApplicationContextType = {
  steps: Step[];
  setSteps: (steps: Step[]) => void;
  nextStep: () => void;
  refresh: () => void;
  activeStep: Step;
};

const ApplicationContext = createContext<ApplicationContextType | undefined>(
  undefined
);

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  }
  return context;
};

export default ApplicationContext;
