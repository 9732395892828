import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

import LoaderScreen from "@/routes/-components/loader-screen";
import { GlobalNavigation } from "@/routes/loans/-components/global-navigation";

export const Route = createFileRoute("/loans/$loanId/_loansLayout")({
  beforeLoad: async ({ context }) => {
    const { isAuthenticated } = context.auth;

    if (!isAuthenticated) {
      throw redirect({
        to: "/",
        search: {
          error: "authentication_required",
        },
      });
    }
  },
  component: Layout,
  pendingComponent: LoaderScreen,
  errorComponent: ErrorLayout,
});

function Layout() {
  return (
    <div className="bg-surface-dark flex min-h-screen flex-col">
      <GlobalNavigation />
      <Outlet />
    </div>
  );
}

function ErrorLayout() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}
