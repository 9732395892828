import * as z from "zod";

export function dateToLocalDateString(
  date: Date,
  dateStyle: "full" | "long" | "medium" | "short" | undefined = "full"
) {
  return date.toLocaleDateString("en-US", { dateStyle: dateStyle });
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function daysUntil(date: Date, now: Date = new Date()): number {
  return Math.floor((date.getTime() - now.getTime()) / (1000 * 3600 * 24));
}

export function dateIsValid(value: string) {
  const date = new Date(value);
  const [, day] = value.split("/");

  if (Boolean(+date) && date.getDate() === parseInt(day)) {
    return date;
  } else {
    return undefined;
  }
}

export function dateOfBirthIsValid(value: string) {
  const validDate = dateIsValid(value);

  return (
    !!validDate && validDate < new Date() && validDate >= new Date(1900, 0, 1)
  );
}

const googleAddressSchema = z.object({
  _google_address_line_1: z.string(),
  _google_address_line_2: z.string(),
  _google_address_city: z.string(),
  _google_address_state: z.string(),
  _google_address_postal_code: z.string(),
  _google_address_country_code: z.string(),
});

export type GoogleAddress = z.infer<typeof googleAddressSchema>;

export const convertPlaceAddressComponentsToAddress = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): GoogleAddress => {
  const address: GoogleAddress = {
    _google_address_line_1: "",
    _google_address_line_2: "",
    _google_address_city: "",
    _google_address_state: "",
    _google_address_postal_code: "",
    _google_address_country_code: "",
  };

  for (const component of addressComponents) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address._google_address_line_1 = component.long_name;
        break;
      }

      case "route": {
        address._google_address_line_1 += ` ${component.short_name}`;
        break;
      }

      case "subpremise": {
        address._google_address_line_2 = component.long_name;
        break;
      }

      case "locality": {
        address._google_address_city = component.long_name;
        break;
      }

      case "sublocality_level_1": {
        address._google_address_city = component.long_name;
        break;
      }

      case "administrative_area_level_1": {
        address._google_address_state = component.short_name;
        break;
      }

      case "postal_code": {
        address._google_address_postal_code = component.long_name;
        break;
      }

      case "country": {
        address._google_address_country_code = component.short_name;
        break;
      }

      default: {
        break;
      }
    }
  }

  return address;
};

export function formatPhoneForAuth(phone: string) {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, "");

  // Check if the number has the correct length
  if (digits.length === 10) {
    // Format the number to +1 area code + rest of the number
    return `+1${digits}`;
  } else {
    throw new Error("Invalid phone number format");
  }
}
