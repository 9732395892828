import * as Sentry from "@sentry/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { useEffect, useState } from "react";

import {
  executeLoan,
  getWritebackAccounts,
  postWritebackOptIn,
  postWritebackOptOut,
} from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";
import { WritebackAccountsResponse } from "@/types";
import { Button } from "@prime/ui/src/button";
import { Input } from "@prime/ui/src/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@prime/ui/src/select";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/xero-writeback/"
)({
  pendingComponent: LoadingScreen,
  errorComponent: ErrorPage,
  component: Page,
});

function Page() {
  const navigate = useNavigate();
  const { apiClient, application, toast } = Route.useRouteContext();
  const [isOptingIn, setIsOptingIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [showExecuteLoan, setShowExecuteLoan] = useState(false);

  const [bankAccount, setBankAccount] = useState("");
  const [expenseAccount, setExpenseAccount] = useState("");
  const [newExpenseAccountName, setNewExpenseAccountName] = useState(
    "Pop Loans - Expense Account"
  );
  const [newExpenseNominalCode, setNewExpenseNominalCode] =
    useState("POPLOANS");
  const [nominalCodeError, setNominalCodeError] = useState("");

  const [xeroAccounts, setXeroAccounts] = useState<
    WritebackAccountsResponse["partner_platform_accounts"] | undefined
  >();

  useEffect(() => {
    if (isOptingIn) {
      setIsLoading(true);
      getWritebackAccounts({
        apiClient,
        params: {
          applicationId: application.id,
        },
      })
        .then((response) => {
          setXeroAccounts(response?.partner_platform_accounts);
          setBankAccount(
            response?.partner_platform_accounts?.bank_accounts?.[0]
              ?.partner_platform_id || ""
          );
          setExpenseAccount(
            response?.partner_platform_accounts?.expense_accounts?.[0]
              ?.partner_platform_id || "create"
          );
        })
        .catch((error) => {
          setIsOptingIn(false);
          Sentry.captureException(error);
          toast({
            title: "Error",
            description:
              "An error occurred while fetching Xero accounts. Please try again.",
            variant: "destructive",
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [isOptingIn, application, apiClient, toast]);

  const handleExecuteLoan = async () => {
    setIsProcessing(true);
    try {
      await executeLoan({
        apiClient,
        params: { id: application.id },
      });

      // If above successful, navigate to the loan dashboard
      navigate({
        to: "/loans/$loanId",
        params: { loanId: application.loan_id! },
        replace: true,
      });
    } catch (error) {
      setIsProcessing(false);
      Sentry.captureException(error);
    }
  };

  function optOut() {
    setIsLoading(true);
    postWritebackOptOut({
      apiClient,
      params: {
        applicationId: application.id,
      },
    })
      .then(() => setShowExecuteLoan(true))
      .catch((error) => {
        Sentry.captureException(error);
        toast({
          title: "Error",
          description:
            "An error occurred while opting out of Xero writeback. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => setIsLoading(false));
  }

  function optIn() {
    setIsLoading(true);
    const existingExpenseAccount = xeroAccounts?.expense_accounts.find(
      (exp) => exp.partner_platform_id === expenseAccount
    );
    const expenseAccountObj = existingExpenseAccount
      ? {
          id: expenseAccount,
          nominalCode: existingExpenseAccount.nominal_code,
          accountName: existingExpenseAccount.account_name,
          shouldCreateInPartnerSystem: false,
        }
      : {
          accountName: newExpenseAccountName,
          nominalCode: newExpenseNominalCode || "POPLOANS",
          shouldCreateInPartnerSystem: true,
        };
    postWritebackOptIn({
      apiClient,
      params: {
        applicationId: application.id,
        optIn: true,
        bankAccountId: bankAccount,
        expenseAccount: expenseAccountObj,
      },
    })
      .then(() => setShowExecuteLoan(true))
      .catch((error) => {
        Sentry.captureException(error);
        toast({
          title: "Error",
          description:
            "An error occurred while opting out of Xero writeback. Please try again.",
          variant: "destructive",
        });
      })
      .finally(() => setIsLoading(false));
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (showExecuteLoan) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="text-txt-primary relative box-border flex w-full max-w-[432px] flex-1 flex-col items-center justify-center gap-12 overflow-hidden p-6 text-left">
          <div className="box-border flex flex-col items-start justify-start">
            <div className="flex flex-col items-start justify-start gap-1 font-normal">
              <div className="relative self-stretch text-2xl leading-[125%]">
                You are all set!
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-3 text-base text-white">
            <Button
              className="w-full"
              onClick={handleExecuteLoan}
              disabled={isProcessing}
            >
              Finalize Loan
              {isProcessing ? (
                <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
              ) : null}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="text-txt-primary relative box-border flex w-full max-w-[432px] flex-1 flex-col items-center justify-center gap-12 overflow-hidden p-6 text-left">
        {!isOptingIn ? (
          <>
            <div className="box-border flex flex-col items-start justify-start">
              <div className="flex flex-col items-start justify-start gap-1 font-normal">
                <div className="relative self-stretch text-2xl leading-[125%]">
                  Should We Track Payments in Xero?
                </div>
                <div className="text-txt-tertiary relative self-stretch text-sm leading-[150%]">
                  Do you want us to capture your loan deposit and payments in
                  Xero?
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-3 text-base text-white">
              <Button
                className="w-full"
                onClick={() => setIsOptingIn(true)}
                disabled={isLoading}
              >
                Yes
              </Button>
              <Button
                className="w-full"
                variant={"outline"}
                onClick={optOut}
                disabled={isLoading}
              >
                No
                {isLoading ? (
                  <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
                ) : null}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="box-border flex flex-col items-start justify-start">
              <div className="flex flex-col items-start justify-start gap-1 font-normal">
                <div className="relative self-stretch text-2xl leading-[125%]">
                  Select your accounts from Xero
                </div>
                <div className="text-txt-tertiary relative self-stretch text-sm leading-[150%]">
                  We need to know which bank account and expense account to use.
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-3">
              <Select onValueChange={setBankAccount} defaultValue={bankAccount}>
                <SelectTrigger placeholder="Bank Account">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {xeroAccounts?.bank_accounts.map(
                    ({ partner_platform_id: id, account_name }) => {
                      return (
                        <SelectItem key={id} value={id}>
                          {account_name}
                        </SelectItem>
                      );
                    }
                  )}
                </SelectContent>
              </Select>
              <Select
                onValueChange={setExpenseAccount}
                defaultValue={expenseAccount}
              >
                <SelectTrigger placeholder="Expense Account">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {xeroAccounts?.expense_accounts.map(
                    ({ partner_platform_id: id, account_name }) => {
                      return (
                        <SelectItem key={id} value={id}>
                          {account_name}
                        </SelectItem>
                      );
                    }
                  )}
                  <SelectItem value="create">
                    Create new expense account...
                  </SelectItem>
                </SelectContent>
              </Select>
              {expenseAccount === "create" ? (
                <>
                  <div className="text-txt-tertiary relative self-stretch text-sm leading-[150%]">
                    Enter new expense account details
                  </div>
                  <Input
                    placeholder="Expense Account Name"
                    value={newExpenseAccountName}
                    onChange={({ target }) =>
                      setNewExpenseAccountName(target.value)
                    }
                  />
                  <div className="flex flex-col gap-1">
                    <Input
                      placeholder="Expense Account Nominal Code"
                      value={newExpenseNominalCode}
                      aria-invalid={!!nominalCodeError}
                      onChange={({ target }) => {
                        let value = (target.value || "").trim();
                        if (value.length > 10) {
                          setNominalCodeError(
                            "Nominal code must be 10 characters or less"
                          );
                          value = value.slice(0, 10);
                        } else {
                          setNominalCodeError("");
                        }
                        setNewExpenseNominalCode(value);
                      }}
                    />
                    {nominalCodeError ? (
                      <div className="text-sm text-red-500">
                        {nominalCodeError}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
            <div className="flex w-full flex-col gap-3 text-base text-white">
              <Button className="w-full" onClick={optIn} disabled={isLoading}>
                Done
                {isLoading ? (
                  <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
                ) : null}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function ErrorPage() {
  return (
    <div className="m-auto flex max-w-lg flex-col gap-6 p-6">
      <div className="rounded-xl border border-red-500 p-8">
        <h1 className="text-readonly text-2xl font-extralight">
          We&rsquo;re sorry, but there seems to be an error in your application.
          We are working to fix it.
        </h1>
      </div>
    </div>
  );
}
