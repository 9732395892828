/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router"

// Import Routes

import { Route as rootRoute } from "./routes/__root"
import { Route as DocsImport } from "./routes/_docs"
import { Route as DashboardImport } from "./routes/_dashboard"
import { Route as IndexImport } from "./routes/index"
import { Route as LogoutIndexImport } from "./routes/logout/index"
import { Route as AuthorizeIndexImport } from "./routes/authorize/index"
import { Route as XeroWelcomeIndexImport } from "./routes/xero/welcome/index"
import { Route as OffersIdIndexImport } from "./routes/offers/$id/index"
import { Route as InvitationsCodeIndexImport } from "./routes/invitations/code/index"
import { Route as InvitationsInvitationIdIndexImport } from "./routes/invitations/$invitationId/index"
import { Route as AuthorizeLoginIndexImport } from "./routes/authorize/login/index"
import { Route as AuthorizeClaimIndexImport } from "./routes/authorize/claim/index"
import { Route as PlatformKeyGetStartedIndexImport } from "./routes/$platformKey/get-started/index"
import { Route as PlatformKeyAuthIndexImport } from "./routes/$platformKey/auth/index"
import { Route as LoansLoanIdLoansLayoutImport } from "./routes/loans/$loanId/_loansLayout"
import { Route as ApplicationsApplicationIdApplicationLayoutImport } from "./routes/applications/$applicationId/_applicationLayout"
import { Route as DocsDocsTermsOfServiceRouteImport } from "./routes/_docs/docs/terms-of-service/route"
import { Route as DocsDocsPrivacyPolicyRouteImport } from "./routes/_docs/docs/privacy-policy/route"
import { Route as DocsDocsESignRouteImport } from "./routes/_docs/docs/e-sign/route"
import { Route as DocsDocsCreditPullRouteImport } from "./routes/_docs/docs/credit-pull/route"
import { Route as OffersIdClaimedIndexImport } from "./routes/offers/$id/claimed/index"
import { Route as LoansLoanIdLoansLayoutIndexImport } from "./routes/loans/$loanId/_loansLayout/index"
import { Route as InvitationsInvitationIdVerificationIndexImport } from "./routes/invitations/$invitationId/verification/index"
import { Route as InvitationsInvitationIdDisclosuresIndexImport } from "./routes/invitations/$invitationId/disclosures/index"
import { Route as InvitationsInvitationIdClaimIndexImport } from "./routes/invitations/$invitationId/claim/index"
import { Route as DashboardDashboardApplicationIdIndexImport } from "./routes/_dashboard/dashboard/$applicationId/index"
import { Route as LoansLoanIdLoansLayoutPaymentIndexImport } from "./routes/loans/$loanId/_loansLayout/payment/index"
import { Route as ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexImport } from "./routes/applications/$applicationId/_applicationLayout/xero-writeback/index"
import { Route as ApplicationsApplicationIdApplicationLayoutTermsIndexImport } from "./routes/applications/$applicationId/_applicationLayout/terms/index"
import { Route as ApplicationsApplicationIdApplicationLayoutOwnershipIndexImport } from "./routes/applications/$applicationId/_applicationLayout/ownership/index"
import { Route as ApplicationsApplicationIdApplicationLayoutIdentityIndexImport } from "./routes/applications/$applicationId/_applicationLayout/identity/index"
import { Route as ApplicationsApplicationIdApplicationLayoutConnectBankIndexImport } from "./routes/applications/$applicationId/_applicationLayout/connect-bank/index"
import { Route as ApplicationsApplicationIdApplicationLayoutAgreementsIndexImport } from "./routes/applications/$applicationId/_applicationLayout/agreements/index"
import { Route as ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexImport } from "./routes/applications/$applicationId/_applicationLayout/verification/review/index"
import { Route as ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexImport } from "./routes/applications/$applicationId/_applicationLayout/verification/denied/index"
import { Route as ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexImport } from "./routes/applications/$applicationId/_applicationLayout/underwriting/review/index"
import { Route as ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexImport } from "./routes/applications/$applicationId/_applicationLayout/underwriting/denied/index"

// Create Virtual Routes

const LoansLoanIdImport = createFileRoute("/loans/$loanId")()
const ApplicationsApplicationIdImport = createFileRoute(
  "/applications/$applicationId",
)()

// Create/Update Routes

const DocsRoute = DocsImport.update({
  id: "/_docs",
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: "/_dashboard",
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any)

const LoansLoanIdRoute = LoansLoanIdImport.update({
  id: "/loans/$loanId",
  path: "/loans/$loanId",
  getParentRoute: () => rootRoute,
} as any)

const ApplicationsApplicationIdRoute = ApplicationsApplicationIdImport.update({
  id: "/applications/$applicationId",
  path: "/applications/$applicationId",
  getParentRoute: () => rootRoute,
} as any)

const LogoutIndexRoute = LogoutIndexImport.update({
  id: "/logout/",
  path: "/logout/",
  getParentRoute: () => rootRoute,
} as any)

const AuthorizeIndexRoute = AuthorizeIndexImport.update({
  id: "/authorize/",
  path: "/authorize/",
  getParentRoute: () => rootRoute,
} as any)

const XeroWelcomeIndexRoute = XeroWelcomeIndexImport.update({
  id: "/xero/welcome/",
  path: "/xero/welcome/",
  getParentRoute: () => rootRoute,
} as any)

const OffersIdIndexRoute = OffersIdIndexImport.update({
  id: "/offers/$id/",
  path: "/offers/$id/",
  getParentRoute: () => rootRoute,
} as any)

const InvitationsCodeIndexRoute = InvitationsCodeIndexImport.update({
  id: "/invitations/code/",
  path: "/invitations/code/",
  getParentRoute: () => rootRoute,
} as any)

const InvitationsInvitationIdIndexRoute =
  InvitationsInvitationIdIndexImport.update({
    id: "/invitations/$invitationId/",
    path: "/invitations/$invitationId/",
    getParentRoute: () => rootRoute,
  } as any)

const AuthorizeLoginIndexRoute = AuthorizeLoginIndexImport.update({
  id: "/authorize/login/",
  path: "/authorize/login/",
  getParentRoute: () => rootRoute,
} as any)

const AuthorizeClaimIndexRoute = AuthorizeClaimIndexImport.update({
  id: "/authorize/claim/",
  path: "/authorize/claim/",
  getParentRoute: () => rootRoute,
} as any)

const PlatformKeyGetStartedIndexRoute = PlatformKeyGetStartedIndexImport.update(
  {
    id: "/$platformKey/get-started/",
    path: "/$platformKey/get-started/",
    getParentRoute: () => rootRoute,
  } as any,
)

const PlatformKeyAuthIndexRoute = PlatformKeyAuthIndexImport.update({
  id: "/$platformKey/auth/",
  path: "/$platformKey/auth/",
  getParentRoute: () => rootRoute,
} as any)

const LoansLoanIdLoansLayoutRoute = LoansLoanIdLoansLayoutImport.update({
  id: "/_loansLayout",
  getParentRoute: () => LoansLoanIdRoute,
} as any)

const ApplicationsApplicationIdApplicationLayoutRoute =
  ApplicationsApplicationIdApplicationLayoutImport.update({
    id: "/_applicationLayout",
    getParentRoute: () => ApplicationsApplicationIdRoute,
  } as any)

const DocsDocsTermsOfServiceRouteRoute =
  DocsDocsTermsOfServiceRouteImport.update({
    id: "/docs/terms-of-service",
    path: "/docs/terms-of-service",
    getParentRoute: () => DocsRoute,
  } as any)

const DocsDocsPrivacyPolicyRouteRoute = DocsDocsPrivacyPolicyRouteImport.update(
  {
    id: "/docs/privacy-policy",
    path: "/docs/privacy-policy",
    getParentRoute: () => DocsRoute,
  } as any,
)

const DocsDocsESignRouteRoute = DocsDocsESignRouteImport.update({
  id: "/docs/e-sign",
  path: "/docs/e-sign",
  getParentRoute: () => DocsRoute,
} as any)

const DocsDocsCreditPullRouteRoute = DocsDocsCreditPullRouteImport.update({
  id: "/docs/credit-pull",
  path: "/docs/credit-pull",
  getParentRoute: () => DocsRoute,
} as any)

const OffersIdClaimedIndexRoute = OffersIdClaimedIndexImport.update({
  id: "/offers/$id/claimed/",
  path: "/offers/$id/claimed/",
  getParentRoute: () => rootRoute,
} as any)

const LoansLoanIdLoansLayoutIndexRoute =
  LoansLoanIdLoansLayoutIndexImport.update({
    id: "/",
    path: "/",
    getParentRoute: () => LoansLoanIdLoansLayoutRoute,
  } as any)

const InvitationsInvitationIdVerificationIndexRoute =
  InvitationsInvitationIdVerificationIndexImport.update({
    id: "/invitations/$invitationId/verification/",
    path: "/invitations/$invitationId/verification/",
    getParentRoute: () => rootRoute,
  } as any)

const InvitationsInvitationIdDisclosuresIndexRoute =
  InvitationsInvitationIdDisclosuresIndexImport.update({
    id: "/invitations/$invitationId/disclosures/",
    path: "/invitations/$invitationId/disclosures/",
    getParentRoute: () => rootRoute,
  } as any)

const InvitationsInvitationIdClaimIndexRoute =
  InvitationsInvitationIdClaimIndexImport.update({
    id: "/invitations/$invitationId/claim/",
    path: "/invitations/$invitationId/claim/",
    getParentRoute: () => rootRoute,
  } as any)

const DashboardDashboardApplicationIdIndexRoute =
  DashboardDashboardApplicationIdIndexImport.update({
    id: "/dashboard/$applicationId/",
    path: "/dashboard/$applicationId/",
    getParentRoute: () => DashboardRoute,
  } as any)

const LoansLoanIdLoansLayoutPaymentIndexRoute =
  LoansLoanIdLoansLayoutPaymentIndexImport.update({
    id: "/payment/",
    path: "/payment/",
    getParentRoute: () => LoansLoanIdLoansLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute =
  ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexImport.update({
    id: "/xero-writeback/",
    path: "/xero-writeback/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutTermsIndexRoute =
  ApplicationsApplicationIdApplicationLayoutTermsIndexImport.update({
    id: "/terms/",
    path: "/terms/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute =
  ApplicationsApplicationIdApplicationLayoutOwnershipIndexImport.update({
    id: "/ownership/",
    path: "/ownership/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute =
  ApplicationsApplicationIdApplicationLayoutIdentityIndexImport.update({
    id: "/identity/",
    path: "/identity/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute =
  ApplicationsApplicationIdApplicationLayoutConnectBankIndexImport.update({
    id: "/connect-bank/",
    path: "/connect-bank/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute =
  ApplicationsApplicationIdApplicationLayoutAgreementsIndexImport.update({
    id: "/agreements/",
    path: "/agreements/",
    getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
  } as any)

const ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute =
  ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexImport.update(
    {
      id: "/verification/review/",
      path: "/verification/review/",
      getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
    } as any,
  )

const ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute =
  ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexImport.update(
    {
      id: "/verification/denied/",
      path: "/verification/denied/",
      getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
    } as any,
  )

const ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute =
  ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexImport.update(
    {
      id: "/underwriting/review/",
      path: "/underwriting/review/",
      getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
    } as any,
  )

const ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute =
  ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexImport.update(
    {
      id: "/underwriting/denied/",
      path: "/underwriting/denied/",
      getParentRoute: () => ApplicationsApplicationIdApplicationLayoutRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    "/_dashboard": {
      id: "/_dashboard"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    "/_docs": {
      id: "/_docs"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof DocsImport
      parentRoute: typeof rootRoute
    }
    "/authorize/": {
      id: "/authorize/"
      path: "/authorize"
      fullPath: "/authorize"
      preLoaderRoute: typeof AuthorizeIndexImport
      parentRoute: typeof rootRoute
    }
    "/logout/": {
      id: "/logout/"
      path: "/logout"
      fullPath: "/logout"
      preLoaderRoute: typeof LogoutIndexImport
      parentRoute: typeof rootRoute
    }
    "/_docs/docs/credit-pull": {
      id: "/_docs/docs/credit-pull"
      path: "/docs/credit-pull"
      fullPath: "/docs/credit-pull"
      preLoaderRoute: typeof DocsDocsCreditPullRouteImport
      parentRoute: typeof DocsImport
    }
    "/_docs/docs/e-sign": {
      id: "/_docs/docs/e-sign"
      path: "/docs/e-sign"
      fullPath: "/docs/e-sign"
      preLoaderRoute: typeof DocsDocsESignRouteImport
      parentRoute: typeof DocsImport
    }
    "/_docs/docs/privacy-policy": {
      id: "/_docs/docs/privacy-policy"
      path: "/docs/privacy-policy"
      fullPath: "/docs/privacy-policy"
      preLoaderRoute: typeof DocsDocsPrivacyPolicyRouteImport
      parentRoute: typeof DocsImport
    }
    "/_docs/docs/terms-of-service": {
      id: "/_docs/docs/terms-of-service"
      path: "/docs/terms-of-service"
      fullPath: "/docs/terms-of-service"
      preLoaderRoute: typeof DocsDocsTermsOfServiceRouteImport
      parentRoute: typeof DocsImport
    }
    "/applications/$applicationId": {
      id: "/applications/$applicationId"
      path: "/applications/$applicationId"
      fullPath: "/applications/$applicationId"
      preLoaderRoute: typeof ApplicationsApplicationIdImport
      parentRoute: typeof rootRoute
    }
    "/applications/$applicationId/_applicationLayout": {
      id: "/applications/$applicationId/_applicationLayout"
      path: "/applications/$applicationId"
      fullPath: "/applications/$applicationId"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
      parentRoute: typeof ApplicationsApplicationIdRoute
    }
    "/loans/$loanId": {
      id: "/loans/$loanId"
      path: "/loans/$loanId"
      fullPath: "/loans/$loanId"
      preLoaderRoute: typeof LoansLoanIdImport
      parentRoute: typeof rootRoute
    }
    "/loans/$loanId/_loansLayout": {
      id: "/loans/$loanId/_loansLayout"
      path: "/loans/$loanId"
      fullPath: "/loans/$loanId"
      preLoaderRoute: typeof LoansLoanIdLoansLayoutImport
      parentRoute: typeof LoansLoanIdRoute
    }
    "/$platformKey/auth/": {
      id: "/$platformKey/auth/"
      path: "/$platformKey/auth"
      fullPath: "/$platformKey/auth"
      preLoaderRoute: typeof PlatformKeyAuthIndexImport
      parentRoute: typeof rootRoute
    }
    "/$platformKey/get-started/": {
      id: "/$platformKey/get-started/"
      path: "/$platformKey/get-started"
      fullPath: "/$platformKey/get-started"
      preLoaderRoute: typeof PlatformKeyGetStartedIndexImport
      parentRoute: typeof rootRoute
    }
    "/authorize/claim/": {
      id: "/authorize/claim/"
      path: "/authorize/claim"
      fullPath: "/authorize/claim"
      preLoaderRoute: typeof AuthorizeClaimIndexImport
      parentRoute: typeof rootRoute
    }
    "/authorize/login/": {
      id: "/authorize/login/"
      path: "/authorize/login"
      fullPath: "/authorize/login"
      preLoaderRoute: typeof AuthorizeLoginIndexImport
      parentRoute: typeof rootRoute
    }
    "/invitations/$invitationId/": {
      id: "/invitations/$invitationId/"
      path: "/invitations/$invitationId"
      fullPath: "/invitations/$invitationId"
      preLoaderRoute: typeof InvitationsInvitationIdIndexImport
      parentRoute: typeof rootRoute
    }
    "/invitations/code/": {
      id: "/invitations/code/"
      path: "/invitations/code"
      fullPath: "/invitations/code"
      preLoaderRoute: typeof InvitationsCodeIndexImport
      parentRoute: typeof rootRoute
    }
    "/offers/$id/": {
      id: "/offers/$id/"
      path: "/offers/$id"
      fullPath: "/offers/$id"
      preLoaderRoute: typeof OffersIdIndexImport
      parentRoute: typeof rootRoute
    }
    "/xero/welcome/": {
      id: "/xero/welcome/"
      path: "/xero/welcome"
      fullPath: "/xero/welcome"
      preLoaderRoute: typeof XeroWelcomeIndexImport
      parentRoute: typeof rootRoute
    }
    "/_dashboard/dashboard/$applicationId/": {
      id: "/_dashboard/dashboard/$applicationId/"
      path: "/dashboard/$applicationId"
      fullPath: "/dashboard/$applicationId"
      preLoaderRoute: typeof DashboardDashboardApplicationIdIndexImport
      parentRoute: typeof DashboardImport
    }
    "/invitations/$invitationId/claim/": {
      id: "/invitations/$invitationId/claim/"
      path: "/invitations/$invitationId/claim"
      fullPath: "/invitations/$invitationId/claim"
      preLoaderRoute: typeof InvitationsInvitationIdClaimIndexImport
      parentRoute: typeof rootRoute
    }
    "/invitations/$invitationId/disclosures/": {
      id: "/invitations/$invitationId/disclosures/"
      path: "/invitations/$invitationId/disclosures"
      fullPath: "/invitations/$invitationId/disclosures"
      preLoaderRoute: typeof InvitationsInvitationIdDisclosuresIndexImport
      parentRoute: typeof rootRoute
    }
    "/invitations/$invitationId/verification/": {
      id: "/invitations/$invitationId/verification/"
      path: "/invitations/$invitationId/verification"
      fullPath: "/invitations/$invitationId/verification"
      preLoaderRoute: typeof InvitationsInvitationIdVerificationIndexImport
      parentRoute: typeof rootRoute
    }
    "/loans/$loanId/_loansLayout/": {
      id: "/loans/$loanId/_loansLayout/"
      path: "/"
      fullPath: "/loans/$loanId/"
      preLoaderRoute: typeof LoansLoanIdLoansLayoutIndexImport
      parentRoute: typeof LoansLoanIdLoansLayoutImport
    }
    "/offers/$id/claimed/": {
      id: "/offers/$id/claimed/"
      path: "/offers/$id/claimed"
      fullPath: "/offers/$id/claimed"
      preLoaderRoute: typeof OffersIdClaimedIndexImport
      parentRoute: typeof rootRoute
    }
    "/applications/$applicationId/_applicationLayout/agreements/": {
      id: "/applications/$applicationId/_applicationLayout/agreements/"
      path: "/agreements"
      fullPath: "/applications/$applicationId/agreements"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutAgreementsIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/connect-bank/": {
      id: "/applications/$applicationId/_applicationLayout/connect-bank/"
      path: "/connect-bank"
      fullPath: "/applications/$applicationId/connect-bank"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutConnectBankIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/identity/": {
      id: "/applications/$applicationId/_applicationLayout/identity/"
      path: "/identity"
      fullPath: "/applications/$applicationId/identity"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutIdentityIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/ownership/": {
      id: "/applications/$applicationId/_applicationLayout/ownership/"
      path: "/ownership"
      fullPath: "/applications/$applicationId/ownership"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutOwnershipIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/terms/": {
      id: "/applications/$applicationId/_applicationLayout/terms/"
      path: "/terms"
      fullPath: "/applications/$applicationId/terms"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutTermsIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/xero-writeback/": {
      id: "/applications/$applicationId/_applicationLayout/xero-writeback/"
      path: "/xero-writeback"
      fullPath: "/applications/$applicationId/xero-writeback"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/loans/$loanId/_loansLayout/payment/": {
      id: "/loans/$loanId/_loansLayout/payment/"
      path: "/payment"
      fullPath: "/loans/$loanId/payment"
      preLoaderRoute: typeof LoansLoanIdLoansLayoutPaymentIndexImport
      parentRoute: typeof LoansLoanIdLoansLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/underwriting/denied/": {
      id: "/applications/$applicationId/_applicationLayout/underwriting/denied/"
      path: "/underwriting/denied"
      fullPath: "/applications/$applicationId/underwriting/denied"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/underwriting/review/": {
      id: "/applications/$applicationId/_applicationLayout/underwriting/review/"
      path: "/underwriting/review"
      fullPath: "/applications/$applicationId/underwriting/review"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/verification/denied/": {
      id: "/applications/$applicationId/_applicationLayout/verification/denied/"
      path: "/verification/denied"
      fullPath: "/applications/$applicationId/verification/denied"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
    "/applications/$applicationId/_applicationLayout/verification/review/": {
      id: "/applications/$applicationId/_applicationLayout/verification/review/"
      path: "/verification/review"
      fullPath: "/applications/$applicationId/verification/review"
      preLoaderRoute: typeof ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexImport
      parentRoute: typeof ApplicationsApplicationIdApplicationLayoutImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardDashboardApplicationIdIndexRoute: typeof DashboardDashboardApplicationIdIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardDashboardApplicationIdIndexRoute:
    DashboardDashboardApplicationIdIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface DocsRouteChildren {
  DocsDocsCreditPullRouteRoute: typeof DocsDocsCreditPullRouteRoute
  DocsDocsESignRouteRoute: typeof DocsDocsESignRouteRoute
  DocsDocsPrivacyPolicyRouteRoute: typeof DocsDocsPrivacyPolicyRouteRoute
  DocsDocsTermsOfServiceRouteRoute: typeof DocsDocsTermsOfServiceRouteRoute
}

const DocsRouteChildren: DocsRouteChildren = {
  DocsDocsCreditPullRouteRoute: DocsDocsCreditPullRouteRoute,
  DocsDocsESignRouteRoute: DocsDocsESignRouteRoute,
  DocsDocsPrivacyPolicyRouteRoute: DocsDocsPrivacyPolicyRouteRoute,
  DocsDocsTermsOfServiceRouteRoute: DocsDocsTermsOfServiceRouteRoute,
}

const DocsRouteWithChildren = DocsRoute._addFileChildren(DocsRouteChildren)

interface ApplicationsApplicationIdApplicationLayoutRouteChildren {
  ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute
  ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute
  ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute
  ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute
  ApplicationsApplicationIdApplicationLayoutTermsIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutTermsIndexRoute
  ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute
  ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute
  ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute
  ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute
  ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute: typeof ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute
}

const ApplicationsApplicationIdApplicationLayoutRouteChildren: ApplicationsApplicationIdApplicationLayoutRouteChildren =
  {
    ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute:
      ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute,
    ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute:
      ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute,
    ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute:
      ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute,
    ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute:
      ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute,
    ApplicationsApplicationIdApplicationLayoutTermsIndexRoute:
      ApplicationsApplicationIdApplicationLayoutTermsIndexRoute,
    ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute:
      ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute,
    ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute:
      ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute,
    ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute:
      ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute,
    ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute:
      ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute,
    ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute:
      ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute,
  }

const ApplicationsApplicationIdApplicationLayoutRouteWithChildren =
  ApplicationsApplicationIdApplicationLayoutRoute._addFileChildren(
    ApplicationsApplicationIdApplicationLayoutRouteChildren,
  )

interface ApplicationsApplicationIdRouteChildren {
  ApplicationsApplicationIdApplicationLayoutRoute: typeof ApplicationsApplicationIdApplicationLayoutRouteWithChildren
}

const ApplicationsApplicationIdRouteChildren: ApplicationsApplicationIdRouteChildren =
  {
    ApplicationsApplicationIdApplicationLayoutRoute:
      ApplicationsApplicationIdApplicationLayoutRouteWithChildren,
  }

const ApplicationsApplicationIdRouteWithChildren =
  ApplicationsApplicationIdRoute._addFileChildren(
    ApplicationsApplicationIdRouteChildren,
  )

interface LoansLoanIdLoansLayoutRouteChildren {
  LoansLoanIdLoansLayoutIndexRoute: typeof LoansLoanIdLoansLayoutIndexRoute
  LoansLoanIdLoansLayoutPaymentIndexRoute: typeof LoansLoanIdLoansLayoutPaymentIndexRoute
}

const LoansLoanIdLoansLayoutRouteChildren: LoansLoanIdLoansLayoutRouteChildren =
  {
    LoansLoanIdLoansLayoutIndexRoute: LoansLoanIdLoansLayoutIndexRoute,
    LoansLoanIdLoansLayoutPaymentIndexRoute:
      LoansLoanIdLoansLayoutPaymentIndexRoute,
  }

const LoansLoanIdLoansLayoutRouteWithChildren =
  LoansLoanIdLoansLayoutRoute._addFileChildren(
    LoansLoanIdLoansLayoutRouteChildren,
  )

interface LoansLoanIdRouteChildren {
  LoansLoanIdLoansLayoutRoute: typeof LoansLoanIdLoansLayoutRouteWithChildren
}

const LoansLoanIdRouteChildren: LoansLoanIdRouteChildren = {
  LoansLoanIdLoansLayoutRoute: LoansLoanIdLoansLayoutRouteWithChildren,
}

const LoansLoanIdRouteWithChildren = LoansLoanIdRoute._addFileChildren(
  LoansLoanIdRouteChildren,
)

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute
  "": typeof DocsRouteWithChildren
  "/authorize": typeof AuthorizeIndexRoute
  "/logout": typeof LogoutIndexRoute
  "/docs/credit-pull": typeof DocsDocsCreditPullRouteRoute
  "/docs/e-sign": typeof DocsDocsESignRouteRoute
  "/docs/privacy-policy": typeof DocsDocsPrivacyPolicyRouteRoute
  "/docs/terms-of-service": typeof DocsDocsTermsOfServiceRouteRoute
  "/applications/$applicationId": typeof ApplicationsApplicationIdApplicationLayoutRouteWithChildren
  "/loans/$loanId": typeof LoansLoanIdLoansLayoutRouteWithChildren
  "/$platformKey/auth": typeof PlatformKeyAuthIndexRoute
  "/$platformKey/get-started": typeof PlatformKeyGetStartedIndexRoute
  "/authorize/claim": typeof AuthorizeClaimIndexRoute
  "/authorize/login": typeof AuthorizeLoginIndexRoute
  "/invitations/$invitationId": typeof InvitationsInvitationIdIndexRoute
  "/invitations/code": typeof InvitationsCodeIndexRoute
  "/offers/$id": typeof OffersIdIndexRoute
  "/xero/welcome": typeof XeroWelcomeIndexRoute
  "/dashboard/$applicationId": typeof DashboardDashboardApplicationIdIndexRoute
  "/invitations/$invitationId/claim": typeof InvitationsInvitationIdClaimIndexRoute
  "/invitations/$invitationId/disclosures": typeof InvitationsInvitationIdDisclosuresIndexRoute
  "/invitations/$invitationId/verification": typeof InvitationsInvitationIdVerificationIndexRoute
  "/loans/$loanId/": typeof LoansLoanIdLoansLayoutIndexRoute
  "/offers/$id/claimed": typeof OffersIdClaimedIndexRoute
  "/applications/$applicationId/agreements": typeof ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute
  "/applications/$applicationId/connect-bank": typeof ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute
  "/applications/$applicationId/identity": typeof ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute
  "/applications/$applicationId/ownership": typeof ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute
  "/applications/$applicationId/terms": typeof ApplicationsApplicationIdApplicationLayoutTermsIndexRoute
  "/applications/$applicationId/xero-writeback": typeof ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute
  "/loans/$loanId/payment": typeof LoansLoanIdLoansLayoutPaymentIndexRoute
  "/applications/$applicationId/underwriting/denied": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute
  "/applications/$applicationId/underwriting/review": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute
  "/applications/$applicationId/verification/denied": typeof ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute
  "/applications/$applicationId/verification/review": typeof ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute
  "": typeof DocsRouteWithChildren
  "/authorize": typeof AuthorizeIndexRoute
  "/logout": typeof LogoutIndexRoute
  "/docs/credit-pull": typeof DocsDocsCreditPullRouteRoute
  "/docs/e-sign": typeof DocsDocsESignRouteRoute
  "/docs/privacy-policy": typeof DocsDocsPrivacyPolicyRouteRoute
  "/docs/terms-of-service": typeof DocsDocsTermsOfServiceRouteRoute
  "/applications/$applicationId": typeof ApplicationsApplicationIdApplicationLayoutRouteWithChildren
  "/loans/$loanId": typeof LoansLoanIdLoansLayoutIndexRoute
  "/$platformKey/auth": typeof PlatformKeyAuthIndexRoute
  "/$platformKey/get-started": typeof PlatformKeyGetStartedIndexRoute
  "/authorize/claim": typeof AuthorizeClaimIndexRoute
  "/authorize/login": typeof AuthorizeLoginIndexRoute
  "/invitations/$invitationId": typeof InvitationsInvitationIdIndexRoute
  "/invitations/code": typeof InvitationsCodeIndexRoute
  "/offers/$id": typeof OffersIdIndexRoute
  "/xero/welcome": typeof XeroWelcomeIndexRoute
  "/dashboard/$applicationId": typeof DashboardDashboardApplicationIdIndexRoute
  "/invitations/$invitationId/claim": typeof InvitationsInvitationIdClaimIndexRoute
  "/invitations/$invitationId/disclosures": typeof InvitationsInvitationIdDisclosuresIndexRoute
  "/invitations/$invitationId/verification": typeof InvitationsInvitationIdVerificationIndexRoute
  "/offers/$id/claimed": typeof OffersIdClaimedIndexRoute
  "/applications/$applicationId/agreements": typeof ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute
  "/applications/$applicationId/connect-bank": typeof ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute
  "/applications/$applicationId/identity": typeof ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute
  "/applications/$applicationId/ownership": typeof ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute
  "/applications/$applicationId/terms": typeof ApplicationsApplicationIdApplicationLayoutTermsIndexRoute
  "/applications/$applicationId/xero-writeback": typeof ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute
  "/loans/$loanId/payment": typeof LoansLoanIdLoansLayoutPaymentIndexRoute
  "/applications/$applicationId/underwriting/denied": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute
  "/applications/$applicationId/underwriting/review": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute
  "/applications/$applicationId/verification/denied": typeof ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute
  "/applications/$applicationId/verification/review": typeof ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/": typeof IndexRoute
  "/_dashboard": typeof DashboardRouteWithChildren
  "/_docs": typeof DocsRouteWithChildren
  "/authorize/": typeof AuthorizeIndexRoute
  "/logout/": typeof LogoutIndexRoute
  "/_docs/docs/credit-pull": typeof DocsDocsCreditPullRouteRoute
  "/_docs/docs/e-sign": typeof DocsDocsESignRouteRoute
  "/_docs/docs/privacy-policy": typeof DocsDocsPrivacyPolicyRouteRoute
  "/_docs/docs/terms-of-service": typeof DocsDocsTermsOfServiceRouteRoute
  "/applications/$applicationId": typeof ApplicationsApplicationIdRouteWithChildren
  "/applications/$applicationId/_applicationLayout": typeof ApplicationsApplicationIdApplicationLayoutRouteWithChildren
  "/loans/$loanId": typeof LoansLoanIdRouteWithChildren
  "/loans/$loanId/_loansLayout": typeof LoansLoanIdLoansLayoutRouteWithChildren
  "/$platformKey/auth/": typeof PlatformKeyAuthIndexRoute
  "/$platformKey/get-started/": typeof PlatformKeyGetStartedIndexRoute
  "/authorize/claim/": typeof AuthorizeClaimIndexRoute
  "/authorize/login/": typeof AuthorizeLoginIndexRoute
  "/invitations/$invitationId/": typeof InvitationsInvitationIdIndexRoute
  "/invitations/code/": typeof InvitationsCodeIndexRoute
  "/offers/$id/": typeof OffersIdIndexRoute
  "/xero/welcome/": typeof XeroWelcomeIndexRoute
  "/_dashboard/dashboard/$applicationId/": typeof DashboardDashboardApplicationIdIndexRoute
  "/invitations/$invitationId/claim/": typeof InvitationsInvitationIdClaimIndexRoute
  "/invitations/$invitationId/disclosures/": typeof InvitationsInvitationIdDisclosuresIndexRoute
  "/invitations/$invitationId/verification/": typeof InvitationsInvitationIdVerificationIndexRoute
  "/loans/$loanId/_loansLayout/": typeof LoansLoanIdLoansLayoutIndexRoute
  "/offers/$id/claimed/": typeof OffersIdClaimedIndexRoute
  "/applications/$applicationId/_applicationLayout/agreements/": typeof ApplicationsApplicationIdApplicationLayoutAgreementsIndexRoute
  "/applications/$applicationId/_applicationLayout/connect-bank/": typeof ApplicationsApplicationIdApplicationLayoutConnectBankIndexRoute
  "/applications/$applicationId/_applicationLayout/identity/": typeof ApplicationsApplicationIdApplicationLayoutIdentityIndexRoute
  "/applications/$applicationId/_applicationLayout/ownership/": typeof ApplicationsApplicationIdApplicationLayoutOwnershipIndexRoute
  "/applications/$applicationId/_applicationLayout/terms/": typeof ApplicationsApplicationIdApplicationLayoutTermsIndexRoute
  "/applications/$applicationId/_applicationLayout/xero-writeback/": typeof ApplicationsApplicationIdApplicationLayoutXeroWritebackIndexRoute
  "/loans/$loanId/_loansLayout/payment/": typeof LoansLoanIdLoansLayoutPaymentIndexRoute
  "/applications/$applicationId/_applicationLayout/underwriting/denied/": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingDeniedIndexRoute
  "/applications/$applicationId/_applicationLayout/underwriting/review/": typeof ApplicationsApplicationIdApplicationLayoutUnderwritingReviewIndexRoute
  "/applications/$applicationId/_applicationLayout/verification/denied/": typeof ApplicationsApplicationIdApplicationLayoutVerificationDeniedIndexRoute
  "/applications/$applicationId/_applicationLayout/verification/review/": typeof ApplicationsApplicationIdApplicationLayoutVerificationReviewIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | "/"
    | ""
    | "/authorize"
    | "/logout"
    | "/docs/credit-pull"
    | "/docs/e-sign"
    | "/docs/privacy-policy"
    | "/docs/terms-of-service"
    | "/applications/$applicationId"
    | "/loans/$loanId"
    | "/$platformKey/auth"
    | "/$platformKey/get-started"
    | "/authorize/claim"
    | "/authorize/login"
    | "/invitations/$invitationId"
    | "/invitations/code"
    | "/offers/$id"
    | "/xero/welcome"
    | "/dashboard/$applicationId"
    | "/invitations/$invitationId/claim"
    | "/invitations/$invitationId/disclosures"
    | "/invitations/$invitationId/verification"
    | "/loans/$loanId/"
    | "/offers/$id/claimed"
    | "/applications/$applicationId/agreements"
    | "/applications/$applicationId/connect-bank"
    | "/applications/$applicationId/identity"
    | "/applications/$applicationId/ownership"
    | "/applications/$applicationId/terms"
    | "/applications/$applicationId/xero-writeback"
    | "/loans/$loanId/payment"
    | "/applications/$applicationId/underwriting/denied"
    | "/applications/$applicationId/underwriting/review"
    | "/applications/$applicationId/verification/denied"
    | "/applications/$applicationId/verification/review"
  fileRoutesByTo: FileRoutesByTo
  to:
    | "/"
    | ""
    | "/authorize"
    | "/logout"
    | "/docs/credit-pull"
    | "/docs/e-sign"
    | "/docs/privacy-policy"
    | "/docs/terms-of-service"
    | "/applications/$applicationId"
    | "/loans/$loanId"
    | "/$platformKey/auth"
    | "/$platformKey/get-started"
    | "/authorize/claim"
    | "/authorize/login"
    | "/invitations/$invitationId"
    | "/invitations/code"
    | "/offers/$id"
    | "/xero/welcome"
    | "/dashboard/$applicationId"
    | "/invitations/$invitationId/claim"
    | "/invitations/$invitationId/disclosures"
    | "/invitations/$invitationId/verification"
    | "/offers/$id/claimed"
    | "/applications/$applicationId/agreements"
    | "/applications/$applicationId/connect-bank"
    | "/applications/$applicationId/identity"
    | "/applications/$applicationId/ownership"
    | "/applications/$applicationId/terms"
    | "/applications/$applicationId/xero-writeback"
    | "/loans/$loanId/payment"
    | "/applications/$applicationId/underwriting/denied"
    | "/applications/$applicationId/underwriting/review"
    | "/applications/$applicationId/verification/denied"
    | "/applications/$applicationId/verification/review"
  id:
    | "__root__"
    | "/"
    | "/_dashboard"
    | "/_docs"
    | "/authorize/"
    | "/logout/"
    | "/_docs/docs/credit-pull"
    | "/_docs/docs/e-sign"
    | "/_docs/docs/privacy-policy"
    | "/_docs/docs/terms-of-service"
    | "/applications/$applicationId"
    | "/applications/$applicationId/_applicationLayout"
    | "/loans/$loanId"
    | "/loans/$loanId/_loansLayout"
    | "/$platformKey/auth/"
    | "/$platformKey/get-started/"
    | "/authorize/claim/"
    | "/authorize/login/"
    | "/invitations/$invitationId/"
    | "/invitations/code/"
    | "/offers/$id/"
    | "/xero/welcome/"
    | "/_dashboard/dashboard/$applicationId/"
    | "/invitations/$invitationId/claim/"
    | "/invitations/$invitationId/disclosures/"
    | "/invitations/$invitationId/verification/"
    | "/loans/$loanId/_loansLayout/"
    | "/offers/$id/claimed/"
    | "/applications/$applicationId/_applicationLayout/agreements/"
    | "/applications/$applicationId/_applicationLayout/connect-bank/"
    | "/applications/$applicationId/_applicationLayout/identity/"
    | "/applications/$applicationId/_applicationLayout/ownership/"
    | "/applications/$applicationId/_applicationLayout/terms/"
    | "/applications/$applicationId/_applicationLayout/xero-writeback/"
    | "/loans/$loanId/_loansLayout/payment/"
    | "/applications/$applicationId/_applicationLayout/underwriting/denied/"
    | "/applications/$applicationId/_applicationLayout/underwriting/review/"
    | "/applications/$applicationId/_applicationLayout/verification/denied/"
    | "/applications/$applicationId/_applicationLayout/verification/review/"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  DocsRoute: typeof DocsRouteWithChildren
  AuthorizeIndexRoute: typeof AuthorizeIndexRoute
  LogoutIndexRoute: typeof LogoutIndexRoute
  ApplicationsApplicationIdRoute: typeof ApplicationsApplicationIdRouteWithChildren
  LoansLoanIdRoute: typeof LoansLoanIdRouteWithChildren
  PlatformKeyAuthIndexRoute: typeof PlatformKeyAuthIndexRoute
  PlatformKeyGetStartedIndexRoute: typeof PlatformKeyGetStartedIndexRoute
  AuthorizeClaimIndexRoute: typeof AuthorizeClaimIndexRoute
  AuthorizeLoginIndexRoute: typeof AuthorizeLoginIndexRoute
  InvitationsInvitationIdIndexRoute: typeof InvitationsInvitationIdIndexRoute
  InvitationsCodeIndexRoute: typeof InvitationsCodeIndexRoute
  OffersIdIndexRoute: typeof OffersIdIndexRoute
  XeroWelcomeIndexRoute: typeof XeroWelcomeIndexRoute
  InvitationsInvitationIdClaimIndexRoute: typeof InvitationsInvitationIdClaimIndexRoute
  InvitationsInvitationIdDisclosuresIndexRoute: typeof InvitationsInvitationIdDisclosuresIndexRoute
  InvitationsInvitationIdVerificationIndexRoute: typeof InvitationsInvitationIdVerificationIndexRoute
  OffersIdClaimedIndexRoute: typeof OffersIdClaimedIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
  DocsRoute: DocsRouteWithChildren,
  AuthorizeIndexRoute: AuthorizeIndexRoute,
  LogoutIndexRoute: LogoutIndexRoute,
  ApplicationsApplicationIdRoute: ApplicationsApplicationIdRouteWithChildren,
  LoansLoanIdRoute: LoansLoanIdRouteWithChildren,
  PlatformKeyAuthIndexRoute: PlatformKeyAuthIndexRoute,
  PlatformKeyGetStartedIndexRoute: PlatformKeyGetStartedIndexRoute,
  AuthorizeClaimIndexRoute: AuthorizeClaimIndexRoute,
  AuthorizeLoginIndexRoute: AuthorizeLoginIndexRoute,
  InvitationsInvitationIdIndexRoute: InvitationsInvitationIdIndexRoute,
  InvitationsCodeIndexRoute: InvitationsCodeIndexRoute,
  OffersIdIndexRoute: OffersIdIndexRoute,
  XeroWelcomeIndexRoute: XeroWelcomeIndexRoute,
  InvitationsInvitationIdClaimIndexRoute:
    InvitationsInvitationIdClaimIndexRoute,
  InvitationsInvitationIdDisclosuresIndexRoute:
    InvitationsInvitationIdDisclosuresIndexRoute,
  InvitationsInvitationIdVerificationIndexRoute:
    InvitationsInvitationIdVerificationIndexRoute,
  OffersIdClaimedIndexRoute: OffersIdClaimedIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_dashboard",
        "/_docs",
        "/authorize/",
        "/logout/",
        "/applications/$applicationId",
        "/loans/$loanId",
        "/$platformKey/auth/",
        "/$platformKey/get-started/",
        "/authorize/claim/",
        "/authorize/login/",
        "/invitations/$invitationId/",
        "/invitations/code/",
        "/offers/$id/",
        "/xero/welcome/",
        "/invitations/$invitationId/claim/",
        "/invitations/$invitationId/disclosures/",
        "/invitations/$invitationId/verification/",
        "/offers/$id/claimed/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/dashboard/$applicationId/"
      ]
    },
    "/_docs": {
      "filePath": "_docs.tsx",
      "children": [
        "/_docs/docs/credit-pull",
        "/_docs/docs/e-sign",
        "/_docs/docs/privacy-policy",
        "/_docs/docs/terms-of-service"
      ]
    },
    "/authorize/": {
      "filePath": "authorize/index.tsx"
    },
    "/logout/": {
      "filePath": "logout/index.tsx"
    },
    "/_docs/docs/credit-pull": {
      "filePath": "_docs/docs/credit-pull/route.tsx",
      "parent": "/_docs"
    },
    "/_docs/docs/e-sign": {
      "filePath": "_docs/docs/e-sign/route.tsx",
      "parent": "/_docs"
    },
    "/_docs/docs/privacy-policy": {
      "filePath": "_docs/docs/privacy-policy/route.tsx",
      "parent": "/_docs"
    },
    "/_docs/docs/terms-of-service": {
      "filePath": "_docs/docs/terms-of-service/route.tsx",
      "parent": "/_docs"
    },
    "/applications/$applicationId": {
      "filePath": "applications/$applicationId",
      "children": [
        "/applications/$applicationId/_applicationLayout"
      ]
    },
    "/applications/$applicationId/_applicationLayout": {
      "filePath": "applications/$applicationId/_applicationLayout.tsx",
      "parent": "/applications/$applicationId",
      "children": [
        "/applications/$applicationId/_applicationLayout/agreements/",
        "/applications/$applicationId/_applicationLayout/connect-bank/",
        "/applications/$applicationId/_applicationLayout/identity/",
        "/applications/$applicationId/_applicationLayout/ownership/",
        "/applications/$applicationId/_applicationLayout/terms/",
        "/applications/$applicationId/_applicationLayout/xero-writeback/",
        "/applications/$applicationId/_applicationLayout/underwriting/denied/",
        "/applications/$applicationId/_applicationLayout/underwriting/review/",
        "/applications/$applicationId/_applicationLayout/verification/denied/",
        "/applications/$applicationId/_applicationLayout/verification/review/"
      ]
    },
    "/loans/$loanId": {
      "filePath": "loans/$loanId",
      "children": [
        "/loans/$loanId/_loansLayout"
      ]
    },
    "/loans/$loanId/_loansLayout": {
      "filePath": "loans/$loanId/_loansLayout.tsx",
      "parent": "/loans/$loanId",
      "children": [
        "/loans/$loanId/_loansLayout/",
        "/loans/$loanId/_loansLayout/payment/"
      ]
    },
    "/$platformKey/auth/": {
      "filePath": "$platformKey/auth/index.tsx"
    },
    "/$platformKey/get-started/": {
      "filePath": "$platformKey/get-started/index.tsx"
    },
    "/authorize/claim/": {
      "filePath": "authorize/claim/index.tsx"
    },
    "/authorize/login/": {
      "filePath": "authorize/login/index.tsx"
    },
    "/invitations/$invitationId/": {
      "filePath": "invitations/$invitationId/index.tsx"
    },
    "/invitations/code/": {
      "filePath": "invitations/code/index.tsx"
    },
    "/offers/$id/": {
      "filePath": "offers/$id/index.tsx"
    },
    "/xero/welcome/": {
      "filePath": "xero/welcome/index.tsx"
    },
    "/_dashboard/dashboard/$applicationId/": {
      "filePath": "_dashboard/dashboard/$applicationId/index.tsx",
      "parent": "/_dashboard"
    },
    "/invitations/$invitationId/claim/": {
      "filePath": "invitations/$invitationId/claim/index.tsx"
    },
    "/invitations/$invitationId/disclosures/": {
      "filePath": "invitations/$invitationId/disclosures/index.tsx"
    },
    "/invitations/$invitationId/verification/": {
      "filePath": "invitations/$invitationId/verification/index.tsx"
    },
    "/loans/$loanId/_loansLayout/": {
      "filePath": "loans/$loanId/_loansLayout/index.tsx",
      "parent": "/loans/$loanId/_loansLayout"
    },
    "/offers/$id/claimed/": {
      "filePath": "offers/$id/claimed/index.tsx"
    },
    "/applications/$applicationId/_applicationLayout/agreements/": {
      "filePath": "applications/$applicationId/_applicationLayout/agreements/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/connect-bank/": {
      "filePath": "applications/$applicationId/_applicationLayout/connect-bank/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/identity/": {
      "filePath": "applications/$applicationId/_applicationLayout/identity/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/ownership/": {
      "filePath": "applications/$applicationId/_applicationLayout/ownership/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/terms/": {
      "filePath": "applications/$applicationId/_applicationLayout/terms/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/xero-writeback/": {
      "filePath": "applications/$applicationId/_applicationLayout/xero-writeback/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/loans/$loanId/_loansLayout/payment/": {
      "filePath": "loans/$loanId/_loansLayout/payment/index.tsx",
      "parent": "/loans/$loanId/_loansLayout"
    },
    "/applications/$applicationId/_applicationLayout/underwriting/denied/": {
      "filePath": "applications/$applicationId/_applicationLayout/underwriting/denied/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/underwriting/review/": {
      "filePath": "applications/$applicationId/_applicationLayout/underwriting/review/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/verification/denied/": {
      "filePath": "applications/$applicationId/_applicationLayout/verification/denied/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    },
    "/applications/$applicationId/_applicationLayout/verification/review/": {
      "filePath": "applications/$applicationId/_applicationLayout/verification/review/index.tsx",
      "parent": "/applications/$applicationId/_applicationLayout"
    }
  }
}
ROUTE_MANIFEST_END */
