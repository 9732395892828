import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

import { useAPI } from "@/contexts/api";
import LoaderScreen from "@/routes/-components/loader-screen";

export const Route = createFileRoute("/$platformKey/auth/")({
  pendingComponent: LoaderScreen,
  component: Page,
});

function Page() {
  const { platformKey } = Route.useParams<{ platformKey: string }>();
  const { auth } = useAPI();
  useEffect(() => {
    auth.signInWith({
      connection: platformKey,
    });
  }, [auth, platformKey]);

  return <LoaderScreen />;
}
