import * as Sentry from "@sentry/react";
import { Link, createFileRoute, redirect } from "@tanstack/react-router";

import { getApplications } from "@/api";
import { CustomAxiosRequestConfig } from "@/contexts/api";
import PrimeLogoSvg from "@/routes/offers/-components/prime-logo.svg";
import VerifyEmail from "@/routes/offers/-components/verify-email-dialog";
import type { PrequalApplication } from "@/types";
import { getRouteByApplicationStage } from "@/utils";
import { Button } from "@prime/ui/src/button";
import { Dialog, DialogContent, DialogTrigger } from "@prime/ui/src/dialog";
import { AxiosError } from "axios";

export const Route = createFileRoute("/offers/$id/")({
  component: Page,
  beforeLoad: async ({ context, cause }) => {
    if (cause !== "enter") return;
    const { auth, apiClient } = context;

    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      try {
        const { applications = [] } = await getApplications({
          apiClient,
        });

        const { to: redirectTo, params: redirectParams } =
          getRouteByApplicationStage(applications?.[0]);

        throw redirect({
          to: redirectTo,
          params: redirectParams,
          replace: true,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          Sentry.captureException(error);
        } else {
          // This is a purposeful redirect "throw" and we don't want to catch it
          throw error;
        }
      }
    }
  },
  loader: async ({ context, params, location }) => {
    const { apiClient } = context;

    // ?error=offer_claim_failed
    if (
      location.search &&
      "error" in location.search &&
      location.search.error === "offer_claim_failed"
    ) {
      context.toast({
        title: "There was an issue claiming this offer",
        description: "Please contact support for assistance.",
        variant: "destructive",
      });
    }

    try {
      const { data: offer } = await apiClient.get<PrequalApplication>(
        `/applications/prequal/${params.id}`,
        {
          requiresAuth: false,
        } as CustomAxiosRequestConfig
      );
      return offer;
    } catch (error) {
      Sentry.captureException(error);
      throw redirect({ to: "/", search: { error: "missing_offer" } });
    }
  },
});

function Page() {
  const offer = Route.useLoaderData();

  const loanAmount = parseFloat(offer.requested_loan_amount);

  const usDollarFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formattedLoanAmount = usDollarFormat.format(loanAmount);

  return (
    <div className="bg-screen-primary flex min-h-screen flex-col items-center justify-center text-center">
      <div className="my-auto mb-20 mt-20 flex max-w-screen-2xl flex-col items-center justify-center">
        <div className="mb-6 flex justify-center">
          <PrimeLogoSvg />
        </div>
        <div className="text-onPrimary flex flex-col items-center gap-12 px-6">
          <div className="max-w-4xl">
            <h1 className="max-w-md text-4xl leading-tight">
              Finance your purchase in weekly installments.
            </h1>
          </div>

          <div className="flex w-full flex-col items-start rounded-lg bg-[#4C5866] p-6 text-white shadow-[0_20px_80px_0_rgba(0,0,0,0.15)] sm:w-[500px]">
            <p className="text-sm uppercase tracking-widest text-[#FCFBFC]/50">
              Installment Loan
            </p>
            <h3 className="text-2xl font-light">
              {offer.applicant_business_name}
            </h3>
            <div className="bg-screen-secondary text-primeft-700 my-6 flex w-full items-center justify-between rounded-md bg-black/10 px-3.5 py-4 text-sm font-medium">
              <p className="text-sm font-light text-[#B0B9C6]">Loan Amount</p>
              <p className="text-base font-light">{formattedLoanAmount}</p>
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <Button className="h-14 w-full bg-[#5C59EF] p-4 text-base font-light transition-all hover:bg-[#5C59EF]/90 md:w-full">
                  Apply Now
                </Button>
              </DialogTrigger>
              <DialogContent>
                <VerifyEmail />
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <footer>
          <div className="mx-auto max-w-7xl overflow-hidden px-4 py-4 sm:px-6 lg:px-8">
            <nav className="flex flex-wrap justify-center" aria-label="Footer">
              <ul className="flex gap-4 text-xs font-extralight">
                <li>
                  <a href="mailto:support@primeft.com">Contact Support</a>
                </li>
                <li>
                  <Link to="/docs/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/docs/terms-of-service">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/docs/e-sign">E-Sign</Link>
                </li>
              </ul>
            </nav>
            <p className="mt-1 text-center text-xs">
              &copy; 2023 Prime Financial Technologies, Inc.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}
