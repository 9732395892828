import { createFileRoute } from "@tanstack/react-router";

import Content from "@/routes/_docs/docs/-content/credit-pull";

export const Route = createFileRoute("/_docs/docs/credit-pull")({
  component: Page,
});

function Page() {
  return (
    <div className="m-auto mb-20 mt-20 flex max-w-screen-2xl flex-col justify-center">
      <Content />
    </div>
  );
}
