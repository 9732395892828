import { createFileRoute, redirect } from "@tanstack/react-router";

import { underwriteApplication } from "@/api";
import LoadingScreen from "@/routes/-components/loader-screen";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/underwriting/review/"
)({
  loader: async ({ context, params }) => {
    const apiClient = context.apiClient;

    // Update underwriting status
    const { stage, risk_assessement_status } = await underwriteApplication({
      apiClient,
      params: { application_id: params.applicationId as string },
    });

    if (
      stage === "risk_assessment_approved" ||
      (!risk_assessement_status && stage === "underwriting_approved")
    ) {
      throw redirect({
        to: "/applications/$applicationId/terms",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }

    if (
      stage === "risk_assessment_denied" ||
      (!risk_assessement_status && stage === "underwriting_denied")
    ) {
      throw redirect({
        to: "/applications/$applicationId/underwriting/denied",
        params: { applicationId: params.applicationId },
        replace: true,
      });
    }
  },
  pendingComponent: LoadingScreen,
  component: _Page,
});

export function _Page() {
  return (
    <Page>
      <PageHeading>
        <PageTitle>Application In Review</PageTitle>
        <PageDescription>
          You&rsquo;ll receive an email as soon as our team has completed a
          quick review of your loan application. You can expect to hear from us
          within 24 hours.
        </PageDescription>
        <PageDescription className="mt-4">
          Please get in touch if you have any questions.
        </PageDescription>
      </PageHeading>
    </Page>
  );
}
