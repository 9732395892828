import * as Sentry from "@sentry/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { CheckCircleIcon, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";

import * as z from "zod";
import {
  connectBank,
  getOpenBankingWidget,
  signAgreement,
  underwriteApplication,
} from "@/api";
import { OpenBankingWidgetResponse } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@prime/ui/src/button";
import { Checkbox } from "@prime/ui/src/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@prime/ui/src/form";
import {
  Page,
  PageDescription,
  PageHeading,
  PageTitle,
} from "@prime/ui/src/page";

export const Route = createFileRoute(
  "/applications/$applicationId/_applicationLayout/connect-bank/"
)({
  beforeLoad: async ({ context, params }) => {
    const apiClient = context.apiClient;

    return {
      fetchOpenBankingWidget: async () => {
        return await getOpenBankingWidget({
          apiClient,
          payload: { application_id: params.applicationId },
        });
      },
    };
  },
  component: _Page,
});

function _Page() {
  const navigate = useNavigate();

  const [openBankingWidgetData, setOpenBankingWidgetData] =
    useState<OpenBankingWidgetResponse | null>(null);
  const [publicToken, setPublicToken] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [connectedBankData, setConnectedBankData] =
    useState<PlaidLinkOnSuccessMetadata | null>(null);
  const { fetchOpenBankingWidget, application, apiClient, toast } =
    Route.useRouteContext();

  const formSchema = z
    .object({
      agree_to_terms: z.boolean(),
    })
    .refine((data) => data.agree_to_terms, {
      message: "You must agree to the terms of service",
      path: ["agree_to_terms"],
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      agree_to_terms: false,
    },
  });

  const onConnectBankFormSubmit = async () => {
    try {
      setIsProcessing(true);
      await signAgreement({
        apiClient,
        params: { id: application.id },
        payload: { agreement_type: "bank_account_info_consent" },
      });
      const openBankingWidgetData = await fetchOpenBankingWidget();
      setOpenBankingWidgetData(openBankingWidgetData);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Failed to connect bank",
        description:
          "There was an issue connecting your bank. Please contact support for help or try again.",
        variant: "destructive",
      });
    }
  };

  const onSuccess = async (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    setPublicToken(public_token);
    setConnectedBankData(metadata);
    setIsProcessing(false);
  };

  const onContinue = async () => {
    setIsProcessing(true);

    try {
      if (!openBankingWidgetData || !publicToken) {
        throw new Error(
          "openBankingWidget and/or publicToken data is not present"
        );
      }

      await connectBank({
        apiClient,
        params: { application_id: application.id },
        payload: {
          public_token: publicToken,
          vendor_name: openBankingWidgetData.vendor_name,
        },
      });

      await underwriteApplication({
        apiClient,
        params: { application_id: application.id },
      });

      navigate({
        to: "/applications/$applicationId/underwriting/review",
        params: { applicationId: application.id },
        replace: true,
      });
    } catch (e) {
      Sentry.captureException(e);
      toast({
        title: "Failed to connect bank",
        description:
          "There was an issue connecting your bank. Please contact support for help or try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const { open, ready } = usePlaidLink({
    token: openBankingWidgetData ? openBankingWidgetData.ob_widget_token : null,
    onSuccess,
    onExit: () => {
      setIsProcessing(false);
    },
  });

  useEffect(() => {
    if (openBankingWidgetData && ready) {
      open();
    }
  }, [open, ready, openBankingWidgetData]);

  const heading = connectedBankData
    ? "Bank Account Connected"
    : "Connect Your Bank";

  return (
    <Page>
      <PageHeading>
        <PageTitle>{heading}</PageTitle>
        {!connectedBankData && (
          <PageDescription>
            We connect to you bank to help verify your business revenue, and to
            retrieve the account information necessary to send and collect
            funds.
          </PageDescription>
        )}
      </PageHeading>

      {!connectedBankData && (
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onConnectBankFormSubmit)}
          >
            <div className="bg-background-option flex rounded-xl p-4">
              <FormField
                control={form.control}
                name="agree_to_terms"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <div className="flex flex-row items-start space-x-3 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-label text-sm font-light">
                        By checking this box, you consent to the storage and
                        processing of your banking information by Prime
                        Financial Technologies.
                      </FormLabel>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit" className="mt-6" disabled={isProcessing}>
              Connect Bank
              {isProcessing && (
                <Loader2 className="ml-2 h-4 w-4 animate-spin" />
              )}
            </Button>
          </form>
        </Form>
      )}

      {connectedBankData && (
        <>
          <BankAccountCard data={connectedBankData} />
          <Button
            type="submit"
            className="mt-6"
            disabled={isProcessing}
            onClick={onContinue}
          >
            Continue
            {isProcessing && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
          </Button>
        </>
      )}
    </Page>
  );
}

function BankAccountCard({ data }: { data: PlaidLinkOnSuccessMetadata }) {
  const bankName = data.institution?.name;
  const accountType = data.accounts[0].subtype;
  const accountNumber = data.accounts[0].mask;

  return (
    <div className="border-action-border-input flex items-center justify-between rounded-lg border px-3 py-4">
      <div>
        <p className="text-text-readonly text-lg font-medium">{bankName}</p>
        <p className="text-text-readonly-secondary text-sm font-light">
          <span className="capitalize">{accountType}</span> account ending in{" "}
          {accountNumber}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <p className="text-text-readonly-affirmative">Connected</p>
        <CheckCircleIcon className="text-text-readonly-affirmative h-4 w-4" />
      </div>
    </div>
  );
}
