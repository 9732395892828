export function dateToLocalDateString(
  date: Date,
  dateStyle: "full" | "long" | "medium" | "short" | undefined = "full"
) {
  return date.toLocaleDateString("en-US", { dateStyle: dateStyle });
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function daysUntil(date: Date, now: Date = new Date()): number {
  return Math.floor((date.getTime() - now.getTime()) / (1000 * 3600 * 24));
}

export function dateIsValid(value: string) {
  const date = new Date(value);
  const [, day] = value.split("/");

  if (Boolean(+date) && date.getDate() === parseInt(day)) {
    return date;
  } else {
    return undefined;
  }
}

export function dateOfBirthIsValid(value: string) {
  const validDate = dateIsValid(value);

  return (
    !!validDate && validDate < new Date() && validDate >= new Date(1900, 0, 1)
  );
}
