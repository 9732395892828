import * as React from "react";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";

import { AuthContextValue } from "@/contexts/api";
import { useToast } from "@prime/ui/src/toast/use-toast";
import { AxiosInstance } from "axios";

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : React.lazy(() =>
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    );

type RootContext = {
  apiClient: AxiosInstance;
  auth: AuthContextValue;
  toast: ReturnType<typeof useToast>["toast"];
  applyBackendClient: AxiosInstance;
  featureFlags: Record<string, boolean> | null;
};

export const Route = createRootRouteWithContext<RootContext>()({
  component: Page,
  notFoundComponent: () => (
    <div className="grid min-h-screen items-center justify-center">
      <div className="border-border-500 border p-8">
        <h1 className="text-xl font-medium">
          Sorry, we cannot find what you are looking for.
        </h1>
      </div>
    </div>
  ),
});

function Page() {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools position="bottom-right" />
    </>
  );
}
