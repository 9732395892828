import * as React from "react";
import { ChevronLeft } from "lucide-react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/css";
import { Button } from "../button";

const localNavigationVariants = cva(
  "text-txt-primary flex w-full flex-row items-center justify-start gap-3 text-left text-2xl",
  {
    variants: {
      variant: {
        default: "",
        alternative: "text-action-primary gap-1 text-base",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface LocalNavigationProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof localNavigationVariants> {
  title: string;
  routeName?: string;
}

const LocalNavigation = React.forwardRef<
  HTMLButtonElement,
  LocalNavigationProps
>(({ className, variant, title, routeName, onClick }, ref) => {
  return (
    <div className={cn(localNavigationVariants({ variant, className }))}>
      {variant === "alternative" ? (
        <Button
          ref={ref}
          onClick={onClick}
          className="items-center gap-1 border-0 p-0 text-base font-normal"
          variant={"outline"}
        >
          {routeName ? <ChevronLeft className="h-4 w-4 shrink-0" /> : null}
          {title}
        </Button>
      ) : (
        <>
          {routeName ? (
            <>
              <Button
                onClick={onClick}
                className="items-center gap-1 font-normal"
                variant={"outline"}
              >
                <ChevronLeft className="h-4 w-4 shrink-0" />
                {routeName}
              </Button>
              <div className="border-border-dark box-border h-9 w-px border-r border-solid"></div>
            </>
          ) : null}
          <div className="font-medium">{title}</div>
        </>
      )}
    </div>
  );
});
LocalNavigation.displayName = "LocalNavigation";

export { LocalNavigation, localNavigationVariants };
