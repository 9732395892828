import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_docs")({
  component: Layout,
});

function Layout() {
  return (
    <div className="m-auto max-w-2xl">
      <Outlet />
    </div>
  );
}
